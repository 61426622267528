<template>
  <div class="events-actions-component">
    <el-button-group>
      <el-button type="primary" @click="goTo('/events/paytax')" class="azul" size="small" :disabled="disabled">Pedágio</el-button>
      <el-button type="primary" @click="goTo('/events/payfuel')" class="azul" size="small" :disabled="disabled">Abastecimento</el-button>
      <el-button type="primary" @click="goTo('/events/general')" class="azul" size="small" :disabled="disabled">Outros</el-button>
    </el-button-group>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'events-actions',
  data () {
    return {}
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('Trucks', ['selectedTruck'])
  },
  methods: {
    ...mapMutations('Shared', ['setError', 'setInfoMessage']),
    goTo (destination) {
      if (!this.selectedTruck.truck_id) this.setError('Veículo deve ser selecionado')
      else this.$router.push(destination)
    }
  }
}
</script>

<style scoped>
  .events-actions-component{
    text-align: center;
    margin-bottom: 10px;
    padding-bottom: 12px;
  }
</style>
