<template>
  <div>
    <h1>Detalhes do cliente</h1>
    <!-- Distância e previsão de entrega, se não cancelado -->
    <delivery-prevision :customer="deliverCustomer" v-if="withoutKnowStatus && false" />

    <!-- Entregando... -->
    <delivery-delivering :customer="deliverCustomer" v-if="isDelivering" />

    <!-- Se cancelado, motivos do cancelamento -->
    <delivery-canceled-cause :customer="deliverCustomer" v-if="deliverCustomer.status === 'canceled'" />

    <!-- Se cancelado, motivos do cancelamento -->
    <delivery-reavailable-cause :customer="deliverCustomer" v-if="deliverCustomer.status === 'reavailable'" />

    <!-- Se entregue, detalhes da entrega -->
    <delivery-delivered :customer="deliverCustomer" v-if="deliverCustomer.status === 'delivered'" />

    <!-- Dados do cliente -->
    <client-details :customer="deliverCustomer" nameHighlight :showInvoices="true" />

    <!-- Ações da carga -->
    <div class="actions-buttons actions-buttons-fullWidth">
      <el-button type="success" v-if="withoutKnowStatus" @click="deliver(deliverCustomer)">Iniciar Entrega</el-button>
      <el-button type="danger" v-if="withoutKnowStatus" @click="cancel(deliverCustomer)">Cancelar Entrega</el-button>
      <el-button type="info" v-if="isReavalible" @click="reavailable(deliverCustomer)">Reiniciar Entrega</el-button>
      <el-button type="success" v-if="isDelivering" @click="delivery(deliverCustomer)">Fazer Checkin da Entrega</el-button>
      <el-button type="info" v-if="isDelivering" @click="waze">Verificar no Mapa</el-button>
      <el-button type="info" v-if="isDelivering" @click="openMaps">Navegar com Google Maps</el-button>
      <el-button type="info" v-if="isDelivering" @click="openWaze">Navegar com Waze</el-button>
      <el-button type="info" @click="$router.go(-1)">Voltar</el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ClientDetails from '@/components/Cargo/Complements/ClientDetails'
import DeliveryPrevision from '@/components/Cargo/Complements/DeliveryPrevision'
import DeliveryCanceledCause from '@/components/Cargo/Complements/DeliveryCanceledCause'
import DeliveryReavailableCause from '@/components/Cargo/Complements/DeliveryReavailableCause'
import DeliveryDelivered from '@/components/Cargo/Complements/DeliveryDelivered'
import DeliveryDelivering from '@/components/Cargo/Complements/DeliveryDelivering'
import * as googleMaps from '@google/maps'
import { logging } from '@/services/logging.js'

export default {
  name: 'Deliver',
  components: {
    ClientDetails,
    DeliveryPrevision,
    DeliveryCanceledCause,
    DeliveryReavailableCause,
    DeliveryDelivered,
    DeliveryDelivering
  },
  data () {
    return {
      lat: 0,
      lng: 0
    }
  },
  computed: {
    ...mapState('Cargos', ['deliverCustomer']),
    withoutKnowStatus () {
      if (!this.deliverCustomer.status) return true
      else return !['canceled', 'delivered', 'delivering', 'delivering_checkin'].includes(this.deliverCustomer.status)
    },
    isDelivering () {
      return (['delivering', 'delivering_checkin'].includes(this.deliverCustomer.status))
    },
    isReavalible () {
      return (['canceled'].includes(this.deliverCustomer.status))
    }
  },
  methods: {
    ...mapActions('Cargos', [
      'setDeliveringCustomer',
      'setDeliveringCheckinCustomer',
      'setAvaliableAfterCanceledCustomer'
    ]),
    delivery (customer) {
      this.$confirm('Finalizar a entrega deste cliente?', 'Finalizar entrega', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning'
      })
        .then(() => this.setDeliveringCheckinCustomer(customer))
        .then(() => this.$router.push('/delivery'))
    },
    deliver (customer) {
      this.$confirm('Iniciar rota de entrega para este cliente?', 'Iniciar entrega', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning'
      })
        .then(() => {
          this.setDeliveringCustomer(customer)
        })
    },
    reavailable (customer) {
      this.$confirm('Alterar cliente para nova tentativa de entrega?', 'Reiniciar entrega', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'info'
      })
        .then(async () => {
          await this.setAvaliableAfterCanceledCustomer(customer)
          this.$router.push('/cargo')
        })
    },
    waze (customer) {
      this.$router.push('/deliver/waze')
    },
    cancel (customer) {
      this.$router.push('/customer-cancellation')
    },
    openMaps () {
      logging(`Open Google Maps to customer ${this.deliverCustomer.customer} lat: ${this.lat} lng: ${this.lng}`, 'GPS', 'Google Maps')
      window.open(encodeURI(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${this.lat},${this.lng}`))
    },
    openWaze () {
      logging(`Open Waze to customer ${this.deliverCustomer.customer} lat: ${this.lat} lng: ${this.lng}`, 'GPS', 'Waze')
      window.open(encodeURI(`https://waze.com/ul?ll=${this.lat},${this.lng}&z=10`))
    }
  },
  created () {
    // If has one customer being delivered... go there
    if (this.deliverCustomer.status === 'delivering_checkin') {
      this.$router.push('/delivery')

    // Extrair lat e lng caso não exista na carga
    } else {
      if (this.deliverCustomer.latlng.lat && this.deliverCustomer.latlng.lng) {
        this.lat = this.deliverCustomer.latlng.lat
        this.lng = this.deliverCustomer.latlng.lng
        logging(`Open customer ${this.deliverCustomer.customer} lat: ${this.lat} lng: ${this.lng}`, 'GPS', 'Open Customer')
      } else {
        let address = `${this.deliverCustomer.address1}, ${this.deliverCustomer.neighborhood}, ${this.deliverCustomer.city}, ${this.deliverCustomer.state}`
        let that = this
        let googleMapsClient = googleMaps.createClient({ key: 'AIzaSyD9zB9ZPBrenlnJ_HoF5rh0rnZ4WIKQsEA' }) // Não sei a origem, não tem acesso ao gmaps maps
        googleMapsClient.geocode({
          address
        }, function (err, response) {
          if (!err) {
            let coord = response.json.results[0].geometry.location
            that.lat = coord.lat
            that.lng = coord.lng
            logging(`Open customer with GEO ${this.deliverCustomer.customer} lat: ${that.lat} lng: ${that.lng}`, 'GPS', 'Open Customer')
          }
        })
      }
    }
  }
}
</script>
