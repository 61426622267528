<template>
  <div>
    <h1>Registrar Evento Geral para {{ selectedTruck.truck_id }}</h1>

    <el-form ref="payGeneralForm" status-icon :model="form" :rules="rules" label-position="top">

      <el-form-item label="Qual tipo de evento?" prop="eventtype" >
        <el-select v-model="form.eventtype" placeholder="Selecione o evento">
          <el-option
            v-for="(item, index) in companieEventsSettings.evtGeneralTypes"
            :key="index"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Descreva o evento" prop="notice" >
        <el-input type="textarea" v-model="form.notice" placeholder="Adicione informações relevantes para este evento" />
      </el-form-item>

      <el-form-item label="Alguma despesa?" prop="value">
        <input-mask-number-field
          :value.sync ="form.value"
          :showButtons=false
          prefix="R$" />
      </el-form-item>

      <el-form-item label="Informe o KM atual do veículo" prop="kmatual">
        <input-mask-number-field
          @updated="$refs['payGeneralForm'].validateField('kmatual')"
          :value.sync="form.kmatual"
          prefix="Km"
          :decimals=1 />
      </el-form-item>

      <el-form-item v-if="companieEventsSettings.evtGeneralNeedOdomPhoto" label="Foto do odômetro" prop="fotoOdom">
        <foto-upload-button
          @hasImage="hasOdomImage = $event"
          @onImage="form.fotoOdom = $event"
          outputFormat="base64"
          :showImagePreview="true"
          beforeText="Foto do odômetro"
          afterText="Foto do odômetro pronta"
          inputId="event-general-fuel-odm-img" >
        </foto-upload-button>
      </el-form-item>

      <el-form-item v-if="companieEventsSettings.evtGeneralNeedCompPhoto" label="Foto do comprovante de pagamento" prop="fotoComp">
        <foto-upload-button
          @hasImage="hasCompImage = $event"
          @onImage="form.fotoComp = $event"
          outputFormat="base64"
          :showImagePreview="true"
          beforeText="Foto do comprovante"
          afterText="Foto do comprovante pronta"
          inputId="event-general-fuel-comp-img" >
        </foto-upload-button>
      </el-form-item>

      <!-- Primeira foto Extra -->
      <el-form-item
        label="Foto do evento"
        prop="image0.image"
        v-if="companieEventsSettings.evtGeneralNeedExtrasPhoto"
        :rules="{ required: companieEventsSettings.evtGeneralNeedExtrasPhotoForce, message: 'Foto é obrigatório', trigger: 'blur' }" >

        <foto-upload-button
          @hasImage="form.image0.hasImage = $event"
          :showImagePreview="true"
          @onImage="form.image0.image = $event"
          outputFormat="base64"
          inputId="event-general-img" >
        </foto-upload-button>
      </el-form-item>

      <!-- Fotos adicionais -->
      <div v-if="!companieEventsSettings.evtGeneralDontNeedExtrasPhoto">
        <el-form-item
          v-for="(image, index) in form.images"
          :label="'Foto ' + (index + 2) + ' do evento'"
          :key="image.key"
          :prop="'images.' + index + '.image'"
          :rules="{ required: companieEventsSettings.evtGeneralNeedExtrasPhotoForce, message: 'Foto é obrigatório', trigger: 'blur' }" >

          <foto-upload-button
            @hasImage="image.hasImage = $event"
            @onImage="image.image = $event"
            @removeButton="removeImage(image)"
            outputFormat="base64"
            :showImagePreview="true"
            :inputId="`event-general-img${index}`" >
          </foto-upload-button>

        </el-form-item>
      </div>

      <center v-if="!companieEventsSettings.evtGeneralDontNeedExtrasPhoto">
        <el-button @click="addImage">Adicionar mais fotos</el-button>
      </center>

      <div class="actions-buttons actions-buttons-fullWidth">
        <el-button type="success" @click="registerEvent">Confirmar</el-button>
        <el-button type="info" @click="$router.go(-1)">Voltar</el-button>
      </div>

    </el-form>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import FotoUploadButton from '@/components/Shared/FotoUploadButton'
import InputMaskNumberField from '@/components/Shared/InputMaskNumberField'
import validateKm from '@/helpers/validateKm'

export default {
  name: 'event-general',
  components: {
    FotoUploadButton,
    InputMaskNumberField
  },
  data () {
    return {
      form: {
        eventtype: '',
        value: 0,
        kmatual: 0,
        notice: null,
        image0: { key: 1, image: null, hasImage: false },
        images: [],
        fotoOdom: null,
        fotoComp: null

      },
      hasCompImage: false,
      hasOdomImage: false,
      rules: {
        eventtype: [
          { required: true, message: 'Selecionar o tipo de evento é obrigatório' }
        ],
        kmatual: [
          { required: true, message: 'Informar o km atual do veículo é obrigatório' },
          { validator: validateKm }
        ],
        notice: [
          { required: true, message: 'Descrever o evento é obrigatório' }
        ],
        fotoOdom: [
          { required: true, message: 'Foto do odômetro é obrigatório' }
        ],
        fotoComp: [
          { required: true, message: 'Foto do comprovante é obrigatório' }
        ]
      }
    }
  },
  computed: {
    ...mapState('Trucks', ['selectedTruck']),
    ...mapGetters('User', ['companieEventsSettings'])
  },
  methods: {
    registerEvent () {
      this.$refs['payGeneralForm'].validate((valid) => {
        if (valid) {
          let images = this.form.images.map((image, index) => {
            return { data: image.image, describe: `evt_general_${(index + 2)}` }
          })

          images.unshift({ data: this.form.fotoOdom, describe: 'evt_general_odom' })
          images.unshift({ data: this.form.fotoComp, describe: 'evt_general_comp' })
          images.unshift({ data: this.form.image0.image, describe: `evt_general_1` })

          this.saveNewEvent({
            type: 'general',
            data: {
              eventtype: this.form.eventtype,
              value: parseFloat(this.form.value),
              kmatual: parseFloat(this.form.kmatual),
              notice: this.form.notice
            },
            images: images
          })
          this.setInfoMessage('Evento registrado')

          this.$router.go(-1)
        } else {
          this.setError(`Erros no formulário`)
        }
      })
    },
    removeImage (item) {
      var index = this.form.images.indexOf(item)
      if (index !== -1) {
        this.form.images.splice(index, 1)
      }
    },
    addImage () {
      let maxPhotos = this.companieEventsSettings.evtGeneralExtrasPhotoMax || 3

      if ((this.form.images.length + 2) <= maxPhotos) {
        this.form.images.push({
          key: Date.now(),
          image: null,
          hasImage: false
        })
      } else {
        this.setError(`Máximo de fotos alcançado!`)
      }
    },
    ...mapActions('Events', ['saveNewEvent']),
    ...mapMutations('Shared', ['setError', 'setInfoMessage'])
  },
  mounted () {
    if (!this.selectedTruck.truck_id) {
      this.setError('Caminhão deve ser selecionado')
      this.$router.push('/')
    } else {
      this.form.kmatual = this.selectedTruck.km
    }

    this.$$logging(`${this.$vnode.tag} Opened`, 'Events', 'Load Component')
  },
  beforeMount () {
    this.rules.fotoComp[0].required = this.companieEventsSettings.evtGeneralNeedCompPhotoForce
    this.rules.fotoOdom[0].required = this.companieEventsSettings.evtGeneralNeedOdomPhotoForce
  }
}
</script>
