import { db, timestamp } from '@/config/firebase'
import createIds from '@/helpers/createIds'
import getLocation from '@/services/getLocation'
import { store } from '@/store'
import env from '@/config/environment'
import t from 'typy'
import openStore from '@/services/localForageService'
import { uaParser } from '@/helpers/uaParser.js'
const indexDbStore = openStore('files')

const isPlainObject = obj => obj && obj.constructor === Object && Object.getPrototypeOf(obj) === Object.prototype

const logginFunc = async (msg = '', zone = '', operation = '', extraFieldObj = {}, copystate = null) => {
  let cloneState = copystate || store.state

  const docId = createIds.firebaseDoc()
  const logRef = db.collection('logs').doc(docId)

  let dataToSave = {
    created_at: timestamp,
    from: 'app',
    zone: zone,
    operation: operation,
    company_id: t(cloneState, 'User.profile.company_id').safeObject || null, // Firestore não aceita undefined
    login: t(cloneState, 'User.profile.login').safeObject || null,
    user_id: t(cloneState, 'User.profile.id').safeObject || null,
    branch_id: t(cloneState, 'User.profile.branch_id').safeObject || null,
    msg: msg,
    extraData: {},
    build_date: env.build.buildDate,
    version: env.build.version
  }

  // Se existir pelo menos 1 item na fila
  if (store.state.Shared.isSyncingPending > 0) {
    let keyValue = await indexDbStore.getItem(await indexDbStore.key(0))
    dataToSave.extraData['queue_size'] = t(cloneState, 'Shared.isSyncingPending').safeObject || null
    dataToSave.extraData['queue0_status'] = t(keyValue, 'status').safeObject || null
  }

  // Se existir pelo menos 2 itens na fila, get second status
  if (store.state.Shared.isSyncingPending > 1) {
    let keyValue = await indexDbStore.getItem(await indexDbStore.key(1))
    dataToSave.extraData['queue1_status'] = t(keyValue, 'status').safeObject || null
  }

  // Outas informações extras
  if (t(cloneState, 'Trucks.selectedTruck.truck_id').isDefined) dataToSave.extraData['truck_id'] = store.state.Trucks.selectedTruck.truck_id
  if (t(cloneState, 'Cargos.cargo.branch_id').isDefined) dataToSave.extraData['branch_id'] = store.state.Cargos.cargo.branch_id
  if (t(cloneState, 'Cargos.cargo.cargo_id').isDefined) dataToSave.extraData['cargo_id'] = store.state.Cargos.cargo.cargo_id
  if (t(cloneState, 'Shared.connectionType').isDefined) dataToSave.extraData['connection_type'] = store.state.Shared.connectionType
  if (t(cloneState, 'Shared.onlineState').isDefined) dataToSave.extraData['online_state'] = store.state.Shared.onlineState

  // Se receber parametro para fazer registro das inforamções completa do device... normalmente no login
  if (t(extraFieldObj, 'fullDeviceReport').isDefined && t(cloneState, 'Shared.deviceInfo').safeObject !== undefined) {
    dataToSave['device'] = uaParser()

    // Grava em uma tabela separada o device que o usuário fez login
    if (dataToSave.company_id !== null && dataToSave.user_id !== null) {
      db.collection('companies').doc(dataToSave.company_id).collection('devices').doc(dataToSave.user_id).set(dataToSave)
    }

    delete extraFieldObj.fullDeviceReport

  // Se não faz login das informações básicas
  } else if (t(cloneState, 'Shared.deviceInfo').safeObject !== null) {
    const device = store.state.Shared.deviceInfo
    dataToSave['device'] = {}
    if (device.browser) dataToSave.device['browser'] = `${device.browser.name} ${device.browser.version}`
    if (device.os) dataToSave.device['os'] = `${device.os.name} ${device.os.version}`
    if (device.userAgent) dataToSave.device['ua'] = device.userAgent
  }

  // Injeta objetos extras na raiz
  // Se for objeto e se objeto for maior que zero
  if (isPlainObject(extraFieldObj) && Object.keys(extraFieldObj).length > 0) {
    Object.keys(extraFieldObj).map(key => {
      if (dataToSave.hasOwnProperty(key)) dataToSave[key] = extraFieldObj[key]
      else dataToSave.extraData[key] = extraFieldObj[key]
    })
  }

  // If empty ExtraData, remove it
  if (Object.keys(dataToSave.extraData).length === 0) delete dataToSave.extraData

  getLocation()
    .then(location => {
      dataToSave = { ...dataToSave }
      dataToSave.location = location
    })
    .then(() => logRef.set(dataToSave))
}

const loggingInstall = {
  install (Vue, options) {
    Vue.prototype.$$logging = logginFunc
  }
}
export default loggingInstall
export { logginFunc as logging }
