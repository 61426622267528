<!--
  EXEMPLO DE USO

  import InputWithAudio from '@/components/Shared/InputWithAudio'

  export default {
    components: {
      InputWithAudio
    }
  }

  <template>
    <input-with-audio
      @updated="$refs['formRef'].validateField('fieldProp')"  // Obrigatório quando se deseja validar o campo com o validade do element

      :textValue.sync ="form.value"             // Propriedade no qual o valor do texto deve ser retornado, enviado no evento :update
      @hasAudio="hasAudio = $event"             // Evento gerado para atribuir o valor booleano quando existir audio
      @onAudio="form.audio = $event"            // Evento que retorno o conteúdo do audio, blob ou base64
      @duration="form.duration = $event"        // Evento que retorna a duranção do audio
      :outputFormat="blob"                      // Opcional, Caso queira definir o output format para base64 ou blob, default base64
      :placeholder="texto qualquer"             // Opcional, Texto para o placeholder
      showRecordButton                          // Se deve exibir os botões de gravação
      showTextArea                              // Se deve exibir o campo textarea
      enableDownloadLink                        // Se habilita link para download após gravação, default é falso
    </input-with-audio>
  </template>
-->

<template>
    <div>
      <div class="input-with-audio-field-div" :class="{ 'input-with-audio-field-div-focus': isFocus }">
        <textarea
          v-if="showTextArea"
          class="input-with-audio-field"
          :placeholder="placeholder"
          inputmode="text"
          autocomplete="off"
          :value="textValue"
          @focus="onFocus"
          @blur="onBlur"
          @change="onChange($event.target.value)"
          @input="onChange($event.target.value)"
          novalidate
          style="min-height: 100px;"
          />

        <button
          v-if="showRecordButton && (isLoading || isError)"
          class="input-with-audio-field-buttom"
          @click.prevent="" >
          <i v-show="isLoading" class="el-icon-loading"></i>
          <i v-show="isError" class="el-icon-error" style="color:red;"></i>
        </button>

        <button
          v-if="showRecordButton && !isRecording && !playerHasAudio && !isLoading && !isError"
          class="input-with-audio-field-buttom"
          @click.prevent="record" >
          <icon name="microphone"></icon>
        </button>

        <button
          v-if="showRecordButton && isRecording && !isLoading && !isError"
          class="input-with-audio-field-buttom buttom-recording"
          @click.prevent="stop" >
          <icon name="stop"></icon>
          <span class="duration">{{ prettyDuration(recordTimeDuration) }}</span>
        </button>

        <button
          v-if="showRecordButton && !isRecording && playerHasAudio && !playerPlaying && !isLoading && !isError"
          class="input-with-audio-field-buttom"
          @click.prevent="playPlayer"
          @mouseup="onMouseUp"
          @mousedown="onMouseDown"
          @touchstart="onMouseDown"
          @touchend="onMouseUp" >
          <icon name="play"></icon>
          <span class="duration">{{ prettyDuration(recordTimeDuration) }}</span>
        </button>

        <button
          v-if="showRecordButton && !isRecording && playerHasAudio && playerPlaying && !isLoading && !isError"
          class="input-with-audio-field-buttom"
          @click.prevent="pausePlayer" >
          <icon name="pause"></icon>
          <span class="duration">{{ playingTime }}</span>
        </button>
      </div>

      <div class="input-with-audio-download-div" v-if="enableDownloadLink" v-show="hasDownload">
        <a id="download">Download</a>
      </div>

      <audio :id="playerid" controls hidden></audio>

    </div>
</template>

<script>
export default {
  name: 'input-with-audio',
  data () {
    return {
      isFocus: false,
      isRecording: false,
      isLoading: false,
      isError: false,
      mediaRecorder: null,
      mediaRecorderParts: [],
      mediaRecorderOptions: {
        // audioBitsPerSecond: 128000,
        // audioBitsPerSecond: 16000,
        audioBitsPerSecond: 8000,
        // videoBitsPerSecond: 2500000,
        // mimeType: 'audio/webm'
        mimeType: 'audio/webm;codecs=opus'
        // mimeType: 'audio/ogg;codecs=opus' // firefox only
        // mimeType: 'video/webm;codecs=vp9'
        // mimeType: 'video/webm;codecs=h264,opus'
      },
      hasDownload: false,
      playerid: null,
      playerEl: null,
      playerPlaying: false,
      playerPlayingTime: 0,
      playerHasAudio: false,
      recordTimeDuration: 0,
      audioURL: null,
      audioBlob: null,
      audioBase64: null,
      longPressTimer: null,
      recordTimer: null
    }
  },
  props: {
    textValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Adicione informações relevantes para registro'
    },
    showRecordButton: {
      type: Boolean,
      default: true
    },
    showTextArea: {
      type: Boolean,
      default: true
    },
    enableDownloadLink: {
      type: Boolean,
      default: false
    },
    outputFormat: {
      type: String,
      default: 'base64',
      validator: value => ['base64', 'blob'].includes(value)
    }
  },
  computed: {
    getUniqueId () {
      const starTime = new Date()
      return `${starTime.toISOString().substr(0, 19).replace(/-|:|T/g, '')}_${Math.random().toString(36).substring(2)}`
    },
    prettyDuration () {
      // https://stackoverflow.com/questions/3733227/javascript-seconds-to-minutes-and-seconds/37770048
      return seconds => (seconds - (seconds %= 60)) / 60 + (seconds > 9 ? ':' : ':0') + seconds
    },
    playingTime () {
      let seconds = this.playerPlayingTime
      return (seconds - (seconds %= 60)) / 60 + (seconds > 9 ? ':' : ':0') + seconds
    }
  },
  methods: {
    record () {
      // console.log('Start Recording')
      // Verificando Browser Support
      if (window.MediaRecorder === undefined) {
        // console.log('Browser dont support recording')
        this.isError = true
        return
      } else {
        this.isLoading = true
      }

      // Verificar permissão de acesso ao microfone
      // result.onchange = (result) => console.log('Result Permission State Changed', result)
      navigator.permissions.query({ name: 'microphone' }).then(function (result) {
        // console.log('Permission State: ', result.state) // 'granted' 'prompt' 'denied'
        if (result.state === 'denied') this.isError = true
      })

      // List input devices, if want ask it before start recording
      // navigator.mediaDevices.enumerateDevices().then((devices) => {
      //   devices = devices.filter((d) => d.kind === 'audioinput')
      //   console.log('Input Devices: ', devices)
      //   // navigator.mediaDevices.getUserMedia({ audio: { deviceId: devices[0].deviceId }, video: false }).then(this.handleRecord)
      // })

      // Abre o microfone criando um stream de audio ativo
      // Chamando a função que irá manipular este audio e consequentemente gravar
      // getUserMedia apenas abre o microfone, MediaRecorder que faz a gravação
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(this.handleRecord)
        .catch(err => console.error(err))
    },
    stop () {
      // console.log('Stop Recording...')
      if (this.isRecording === true) {
        this.mediaRecorder.stop()
        // console.log('Media Record State: ', this.mediaRecorder.state)
      }
    },
    pause () {
      this.mediaRecorder.pause()
    },
    resume () {
      this.mediaRecorder.resume()
    },
    playPlayer () {
      this.playerPlaying = true
      this.playerEl.play()
      this.recordTimer = setInterval(() => { ++this.playerPlayingTime }, 1000)
    },
    pausePlayer () {
      this.playerPlaying = false
      this.playerEl.pause()
      clearTimeout(this.recordTimer)
    },
    toBase64 (blob) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        let base64 = ''
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          base64 = reader.result
          // Second parameter in new Blob add necessary info to avoid below lines
          // base64 = base64.split(',')[1]
          // base64 = `data:audio/webm;base64,${base64}`
          resolve(base64)
        }
      })
    },
    handleRecord (stream) {
      // Verify if mime Type is supported
      // console.log('Mime type supported?', MediaRecorder.isTypeSupported(this.mediaRecorderOptions.mimeType))

      // Initialize MediaRecorder
      this.mediaRecorder = new MediaRecorder(stream, this.mediaRecorderOptions)

      // Add event fired periodically triggered each time timeslice milliseconds
      // of media have been recorded (or when the entire media has been recorded, if timeslice wasn't specified)
      this.mediaRecorder.addEventListener('dataavailable', (e) => {
        // console.log('Recorded Size', e.data.size)
        if (e.data.size > 0) this.mediaRecorderParts.push(e.data)
      })

      // Add event fired when records stop
      this.mediaRecorder.addEventListener('stop', async () => {
        // console.log('Record stopped... gerando URL')
        this.isLoading = true
        this.isRecording = false
        clearInterval(this.recordTimer)

        // Create blob from stream chunks
        this.audioBlob = new Blob(this.mediaRecorderParts, { 'type': this.mediaRecorderOptions.mimeType })
        // console.log(`Blob created with ${this.mediaRecorder.audioBitsPerSecond} bps`)

        // If base64, convert blob and fire event to parent
        // Else, fire event with blob
        if (this.outputFormat === 'base64') {
          this.audioBase64 = await this.toBase64(this.audioBlob)
          // console.log(this.audioBase64)
          this.$emit('onAudio', this.audioBase64)
        } else {
          this.$emit('onAudio', this.audioBlob)
        }

        // Fire event has audio and duration
        this.$emit('hasAudio', true)
        this.$emit('duration', this.recordTimeDuration)

        // Crete URLs to player with Blob... accept base64 to // this.audioURL = this.audioBase64
        this.audioURL = window.URL.createObjectURL(this.audioBlob)

        // Se exibe link do download
        if (this.enableDownloadLink) {
          const downloadLink = document.getElementById('download')
          this.hasDownload = true
          downloadLink.href = this.audioURL
          downloadLink.download = this.getUniqueId + '.webm'
        }

        // Link audio to hidden player
        // plyerEl.src pode receber o stream também e criar um canal de reprodução direto
        this.playerEl.src = this.audioURL
        this.playerHasAudio = true
        // console.log('Player linked to url', this.playerEl.src)

        // Chrome Recond Icon dont get out after Media Record Stop
        // This is because this recording icon is the one of getUserMedia streaming, not the one of MediaRecorder.
        // When you stop the MediaRecorder, the stream is still active.
        stream.getTracks() // get all tracks from the MediaStream
          .forEach(track => track.stop()) // stop each of them

        // Remove loading state
        this.isLoading = false
      })

      this.mediaRecorder.start() // this.mediaRecorder.start(timeslice)
      this.isLoading = false
      this.isRecording = true
      this.recordTimer = setInterval(() => { ++this.recordTimeDuration }, 1000)
      // console.log('Media Record State: ', this.mediaRecorder.state)
    },
    onChange (value) {
      this.$emit('update:textValue', value)
    },
    onFocus () {
      this.isFocus = !this.isFocus
    },
    onBlur () {
      this.isFocus = !this.isFocus
      this.$emit('validate')
    },
    onMouseUp () {
      clearTimeout(this.longPressTimer)
    },
    onMouseDown () {
      this.longPressTimer = setTimeout(() => { this.clearRecorder() }, 2000)
    },
    clearRecorder () {
      this.$confirm('Apagar áudio', 'Formulário', { confirmButtonText: 'Sim', cancelButtonText: 'Não', type: 'info' })
        .then(() => {
          this.playerHasAudio = false
          this.audioBlob = null
          this.audioBase64 = null
          this.recordTimeDuration = 0
          this.playerPlayingTime = 0
          window.URL.revokeObjectURL(this.audioURL)
          this.$emit('onAudio', null)
          this.$emit('hasAudio', false)
          this.$emit('duration', 0)
        })
    }
  },
  created () {
    // window.AudioContext = window.AudioContext || window.webkitAudioContext
    // navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia
    // window.URL = window.URL || window.webkitURL
    this.playerid = this.getUniqueId
  },
  mounted () {
    this.playerEl = document.getElementById(this.playerid)
    this.playerEl.volume = 1.0
    this.playerEl.onended = () => {
      this.playerPlaying = false
      clearTimeout(this.recordTimer)
      this.playerPlayingTime = 0
    }
  }
}
</script>

<style scoped>
  .input-with-audio-download-div {
    display: flex;
  }
  .input-with-audio-field-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 0 4px 4px 0;
    transition: border 0.20s;
  }
  .input-with-audio-field-div-focus {
    border: 1px solid #409EFF !important;
  }
  .is-error .input-with-audio-field-div {
    border: 1px solid #f56c6c;
  }
  .is-success .input-with-audio-field-div {
    border: 1px solid #67c23a;
  }
  .input-with-audio-field {
    font-size: inherit;
    width: 100%;
    color: #606266;
    font-weight: 200;
    outline: 0;
    border: 0;
    resize: vertical;
    padding: 5px 15px;
    line-height: 1.5;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    cursor: text;
    -webkit-appearance: textarea;
    background-color: white;
    -webkit-rtl-ordering: logical;
    flex-direction: column;
  }
  .input-with-audio-field::placeholder {
    font-size: inherit;
    font-weight: 400;
    color: #ccc;
  }
  .input-with-audio-field-buttom {
    background-color: #409EFF;
    color: white;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    border: 0;
    border-radius: 50%;
    background-position: center;
    transition: background 0.6s;
    height: 60px;
    width: 60px;
  }
  .input-with-audio-field-buttom:focus {
    outline: 0;
  }
  .buttom-recording {
    background-color: #fc3535;
  }
  .duration {
    font-size: 10px;
  }
</style>
