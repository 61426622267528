const getDefaultCompanyState = () => {
  return {
    id: null,
    name: null,
    dnsname: null,
    branchs: []
  }
}

const getDefaultUserState = () => {
  return {
    profile: null,
    lastloggedin: null,
    token: null,
    companie: Object.assign({}, getDefaultCompanyState())
  }
}

export default getDefaultUserState()
export { getDefaultUserState }
export { getDefaultCompanyState }
