<!--
  EXEMPLO DE USO
  <template>
    <invoice-details :invoice="invoice" showTitle>
      :invoice="invoice"                  // obrigatório, dados do invoice
      showInvoices                        // opcional, boolean, se exibe titulo
      showDataGrid                        // opcional, boolean, se tabela dos itens
    </foto-upload-button>
  </template>
-->

<template>
    <div class="items-invoice-component">
      <h1 v-if="showTitle"> Detalhes nota {{ invoice.invoice }} </h1>

      <div class="dados-nota">
        <p>Valor Total: {{ invoice.total }}</p>
        <p>Volume: {{ invoice.volumes }}</p>
        <p>Peso: {{ invoice.weight }}</p>
      </div>

      <el-table v-if="showDataGrid" class="txt-nfe" :data="gridDataDetails" style="width: 100%">
        <el-table-column label="Itens da NFe" prop="product" />
        <el-table-column type="expand">
          <template slot-scope="props">
            <p>Produto: {{ props.row.product }}</p>
            <p>Quantidade: {{ props.row.quantity }}</p>
            <p>Medida: {{ props.row.um }}</p>
          </template>
        </el-table-column>
      </el-table>
    </div>
</template>

<script>
export default {
  name: 'invoice-details',
  data () {
    return {
      gridDataDetails: []
    }
  },
  props: {
    invoice: {
      type: Object,
      required: true
    },
    showTitle: Boolean,
    showDataGrid: Boolean
  },
  mounted () {
    if (this.showDataGrid) {
      this.gridDataDetails = (this.invoice.items)
        ? Object.values(this.invoice.items).map(item => {
          return {
            product: item.product.substring(7),
            quantity: item.quantity,
            um: item.um,
            condensed: `${item.product.substring(7)} (${item.quantity})`
          }
        })
        : []
    }
  }
}
</script>

<style scoped>
.txt-nfe {
  font-size:12px;
  color: black;
  font-weight: 500;
}
.dados-nota {
  font-size: 12px;
  text-align: center;
  font-weight: bold;
}
</style>
