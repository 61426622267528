<template>
  <div>
    <h1>Encerrar Carga</h1>

    <el-form ref="closeCargoForm" status-icon :model="form" :rules="rules" label-position="top">
      <el-form-item label="Informe o KM atual do veículo" prop="km_truck">
        <input-mask-number-field
          @updated="$refs['closeCargoForm'].validateField('km_truck')"
          :value.sync="form.km_truck"
          prefix="Km"
          :decimals=1 />
      </el-form-item>

      <el-form-item label="Foto do odômetro" prop="km_photo" v-if="odomPhoto.enabled">
        <foto-upload-button
          @onImage="form.km_photo = $event"
          outputFormat="base64"
          :showImagePreview="true"
          beforeText="Foto do odômetro"
          afterText="Foto do odômetro pronta" >
        </foto-upload-button>
      </el-form-item>

      <div class="actions-buttons actions-buttons-fullWidth">
        <el-button type="success" @click="closeCargo">Confirmar e encerrar carga</el-button>
        <el-button type="info" @click="$router.go(-1)">Voltar</el-button>
      </div>

    </el-form>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import FotoUploadButton from '@/components/Shared/FotoUploadButton'
import InputMaskNumberField from '@/components/Shared/InputMaskNumberField'
import validateKm from '@/helpers/validateKm'

export default {
  name: 'closeCargo',
  components: {
    FotoUploadButton,
    InputMaskNumberField
  },
  data () {
    return {
      form: {
        km_truck: 0,
        km_photo: null
      },
      rules: {
        km_truck: [
          { required: true, message: 'Campo obrigatório' },
          { validator: validateKm }
        ],
        km_photo: [
          { required: true, message: 'Foto é obrigatório' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('Trucks', ['selectedTruckSavedKm']),
    ...mapGetters('User', ['companieGeneralSettings']),
    odomPhoto () {
      let setting = {
        enabled: this.$$to(this, 'companieGeneralSettings.odomPhotoOnCloseCargo')
      }

      if (setting.enabled === undefined) setting.enabled = true

      return setting
    }
  },
  methods: {
    ...mapActions('Cargos', ['closeCargoValidade', 'closeCargoConfirm']),
    ...mapMutations('Shared', ['setError', 'setInfoMessage']),
    closeCargo () {
      this.$refs['closeCargoForm'].validate((valid) => {
        if (valid) {
          this.closeCargoValidade()
            .then(valid => {
              if (!valid) throw new Error('Existem cargas abertas! Finalize todas as cargas antes de continuar')
            })
            .then(() => {
              return this.$confirm('Esta ação não poderá ser desfeita, continuar?', 'Finalizar Carga', {
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                type: 'warning'
              })
            })
            .then(() => {
              return this.closeCargoConfirm(this.form)
            })
            .then(() => {
              this.setInfoMessage('Carga finalizada')
              this.$router.push('/?bypass=true')
            })
            .catch(err => {
              if (err.message) {
                this.$alert(err.message, 'Atenção', {
                  confirmButtonText: 'OK',
                  type: 'error'
                })
              }
            })
        } else {
          this.setError(`Erros no formulário`)
        }
      })
    }
  },
  created () {
    this.form.km_truck = this.selectedTruckSavedKm
  }
}
</script>
