<template>
  <div>
    <h1>Cancelar Entrega</h1>

    <!-- Dados do cliente -->
    <client-details :customer="deliverCustomer" nameHighlight :showInvoices="false" />

    <el-form ref="totalCancellationForm" status-icon :model="form" :rules="rules">

      <el-form-item label="Qual o motivo do cancelamento?" prop="cancellationReason">
        <el-select v-model="form.cancellationReason" placeholder="Selecione..." @change="needPhoto">
          <el-option
            v-for="item in cancellationReasons"
            :key="item.id"
            :label="item.description"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="form.photoNeeded" label="Adicione uma foto" prop="photo">
        <foto-upload-button
          @onImage="form.photo = $event"
          outputFormat="base64" >
        </foto-upload-button>
      </el-form-item>

      <el-form-item label="Observações adicionais?" prop="cancellationNotice" >
        <el-input type="textarea" v-model="form.cancellationNotice" placeholder="Adicione informações relevantes para o cancelamento" />
      </el-form-item>

      <div class="destaque">Atenção, esta ação não pode ser desfeita!</div>
      <div class="actions-buttons">
        <el-button type="success" @click="cancelConfirm(deliverCustomer)">Confirmar</el-button>
        <el-button type="danger" @click="$router.go(-1)">Voltar</el-button>
      </div>

    </el-form>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import ClientDetails from '@/components/Cargo/Complements/ClientDetails'
import FotoUploadButton from '@/components/Shared/FotoUploadButton'

export default {
  name: 'customer-cancellation',
  components: {
    ClientDetails,
    FotoUploadButton
  },
  data () {
    return {
      form: {
        cancellationReason: null,
        cancellationNotice: null,
        photo: null,
        photoNeeded: false
      },
      rules: {
        cancellationReason: [
          { required: true, message: 'Motivo do cancelamento é obrigatório' }
        ],
        cancellationNotice: [
          { required: true, message: 'Descreva os motivos do cancelamento' }
        ],
        photo: [
          { required: true, message: 'Foto é obrigatório' }
        ]
      }
    }
  },
  computed: {
    ...mapState('Cargos', ['deliverCustomer']),
    ...mapGetters('User', ['cancellationReasons'])
  },
  methods: {
    ...mapActions('Cargos', ['cancellationConfirm']),
    ...mapMutations('Shared', ['setError', 'setInfoMessage']),
    needPhoto (selectedReasonId) {
      const selectedReason = this.cancellationReasons.find(reason => reason.id === selectedReasonId)
      this.form.photoNeeded = selectedReason.photo || false
      this.form.photo = null
    },
    cancelConfirm (deliverCustomer) {
      this.$refs['totalCancellationForm'].validate((valid) => {
        if (valid) {
          this.$confirm('Esta ação não poderá ser desfeita, continuar?', 'Ação sem retorno', {
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar',
            type: 'warning'
          })
            .then(() => {
              this.cancellationConfirm({
                reason: this.form.cancellationReason,
                notice: this.form.cancellationNotice,
                photo: this.form.photo,
                customer: deliverCustomer
              })
                .then(() => {
                  this.setInfoMessage('Entrega cancelada')
                  this.$router.push('/cargo')
                })
            })
            .catch(err => this.setError(err.message))
        } else {
          this.setError(`Erros no formulário`)
        }
      })
    }
  }
}
</script>

<style scoped>
  .destaque {
    color: #fff;
    background-color: black;
    font-weight: bold;
    font-size: 15px;
    padding-bottom: 15px;
    padding-top: 15px;
    text-transform: uppercase;
    font-size: 10px;
    margin-top: 15px;
    border-radius: 5px;
    text-align: center;
  }
</style>
