<template>
  <div class="deliver-waze">
    <iframe v-if="waze"
      :src="waze"
      width="100%" height="420px" frameborder="0" allowfullscreen>
    </iframe>

    <!-- Ações da carga -->
    <div class="actions-buttons actions-buttons-fullWidth">
      <a v-if="deliverCustomer.status === 'delivering'"> <el-button @click="openMaps" type="info">Navegar no Maps</el-button> </a>
      <a v-if="deliverCustomer.status === 'delivering'"> <el-button @click="openWaze" type="info">Navegar no Waze</el-button> </a>
      <!-- <a v-if="deliverCustomer.status === 'delivering'" :href="wazeOpen" target="_blank"> <el-button type="info">Navegar no Waze</el-button> </a> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as googleMaps from '@google/maps'
import { logging } from '@/services/logging.js'
export default {
  name: 'items-invoice',
  computed: {
    ...mapState('Cargos', ['deliverCustomer'])
  },
  data () {
    return {
      waze: null,
      wazeOpen: null,
      lat: 0,
      lng: 0
    }
  },
  methods: {
    setWaze (lat, lng) {
      this.lat = lat
      this.lng = lng
      this.waze = `https://embed.waze.com/pt-BR/iframe?zoom=13&lat=${lat}&lon=${lng}&pin=1&desc=1`
      this.wazeOpen = `https://waze.com/ul?ll=${lat},${lng}&z=10`
    },
    openWaze() {
      logging(`Open Waze from verify to customer ${this.deliverCustomer.customer} lat: ${this.lat} lng: ${this.lng}`, 'GPS', 'Waze')
      window.open(encodeURI(this.wazeOpen))
    },
    openMaps () {
      logging(`Open Google Maps to customer ${this.deliverCustomer.customer} lat: ${this.lat} lng: ${this.lng}`, 'GPS', 'Google Maps')
      window.open(encodeURI(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${this.lat},${this.lng}`))
    }
  },
  created () {
    if (this.$$to(this, 'deliverCustomer.latlng.lat') && this.$$to(this, 'deliverCustomer.latlng.lng')) {
      logging(`Verify map Waze to customer ${this.deliverCustomer.customer} lat: ${this.deliverCustomer.latlng.lat} lng: ${this.deliverCustomer.latlng.lng}`, 'GPS', 'Waze')
      this.setWaze(this.deliverCustomer.latlng.lat, this.deliverCustomer.latlng.lng)
    } else {
      let address = `${this.deliverCustomer.address1}, ${this.deliverCustomer.neighborhood}, ${this.deliverCustomer.city}, ${this.deliverCustomer.state}`
      let that = this
      let googleMapsClient = googleMaps.createClient({ key: 'AIzaSyD9zB9ZPBrenlnJ_HoF5rh0rnZ4WIKQsEA' }) // Não sei a origem, não tem acesso ao gmaps maps
      googleMapsClient.geocode({
        address
      }, function (err, response) {
        if (!err) {
          let coord = response.json.results[0].geometry.location
          logging(`Verify map Waze with GEO to customer ${that.deliverCustomer.customer} lat: ${coord.lat} lng: ${coord.lng}`, 'GPS', 'Waze')
          that.setWaze(coord.lat, coord.lng)
        }
      })
    }
  }
}
</script>
