<template>
  <div>
    <h1>Registrar Evento de abastecimento para {{ selectedTruck.truck_id }}</h1>

    <el-form ref="payFuelForm" status-icon :model="form" :rules="rules" label-position="top">

      <el-form-item label="Informe o valor do abastecimento" prop="value">
        <input-mask-number-field
          @updated="$refs['payFuelForm'].validateField('value')"
          :value.sync="form.value"
          prefix="R$" />
      </el-form-item>

      <el-form-item label="Informe o KM atual do veículo" prop="kmatual">
        <input-mask-number-field
          @updated="$refs['payFuelForm'].validateField('kmatual')"
          :value.sync="form.kmatual"
          prefix="Km"
          :decimals=1 />
      </el-form-item>

      <el-form-item v-if="companieEventsSettings.evtPayFuelNeedOdomPhoto" label="Foto do odômetro" prop="fotoOdom">
        <foto-upload-button
          @hasImage="hasOdomImage = $event"
          @onImage="form.fotoOdom = $event"
          outputFormat="base64"
          :showImagePreview="true"
          beforeText="Foto do odômetro"
          afterText="Foto do odômetro pronta"
          inputId="event-pay-fuel-odm-img" >
        </foto-upload-button>
      </el-form-item>

      <el-form-item v-if="companieEventsSettings.evtPayFuelNeedCompPhoto" label="Foto do comprovante de pagamento" prop="fotoComp">
        <foto-upload-button
          @hasImage="hasCompImage = $event"
          @onImage="form.fotoComp = $event"
          outputFormat="base64"
          :showImagePreview="true"
          beforeText="Foto do comprovante"
          afterText="Foto do comprovante pronta"
          inputId="event-pay-fuel-comp-img" >
        </foto-upload-button>
      </el-form-item>

      <el-form-item label="Observações adicionais (teto/audio)" prop="notice" >
        <input-with-audio
          @onAudio="form.notice_audio = $event"
          @duration="form.audio_duration = $event"
          :textValue.sync="form.notice"
          outputFormat="base64" />
      </el-form-item>

      <div class="actions-buttons actions-buttons-fullWidth">
        <el-button type="success" @click="registerEvent">Confirmar</el-button>
        <el-button type="info" @click="$router.go(-1)">Voltar</el-button>
      </div>

    </el-form>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import FotoUploadButton from '@/components/Shared/FotoUploadButton'
import InputMaskNumberField from '@/components/Shared/InputMaskNumberField'
import InputWithAudio from '@/components/Shared/InputWithAudio'
import validateKm from '@/helpers/validateKm'
import validateValue from '@/helpers/validateValue'

export default {
  name: 'event-pay-fuel',
  components: {
    FotoUploadButton,
    InputMaskNumberField,
    InputWithAudio
  },
  data () {
    return {
      form: {
        value: 0,
        kmatual: 0,
        fotoOdom: null,
        fotoComp: null,
        notice: null,
        notice_audio: null,
        audio_duration: 0
      },
      hasCompImage: false,
      hasOdomImage: false,
      hasAudio: false,
      rules: {
        value: [
          { required: true, message: 'Valor do abastecimento é obrigatório' },
          { validator: validateValue }
        ],
        kmatual: [
          { required: true, message: 'Informar o km atual do veículo é obrigatório' },
          { validator: validateKm }
        ],
        fotoOdom: [
          { required: false, message: 'Foto do odômetro é obrigatório' }
        ],
        fotoComp: [
          { required: true, message: 'Foto do comprovante é obrigatório' }
        ]
      }
    }
  },
  computed: {
    ...mapState('Trucks', ['selectedTruck']),
    ...mapGetters('User', ['companieEventsSettings'])
  },
  methods: {
    updated (eventdata) {},
    registerEvent () {
      this.$refs['payFuelForm'].validate((valid) => {
        if (valid) {
          this.saveNewEvent({
            type: 'payfuel',
            data: {
              value: parseFloat(this.form.value),
              kmatual: parseFloat(this.form.kmatual),
              notice: this.form.notice,
              audio: this.form.notice_audio,
              audio_duration: this.form.audio_duration,
              audio_tag: 'evt_payfuel'
            },
            images: [
              { data: this.form.fotoOdom, describe: 'evt_payfuel_odom' },
              { data: this.form.fotoComp, describe: 'evt_payfuel_comp' }
            ]
          })
          this.setInfoMessage('Evento registrado')

          this.$router.go(-1)
        } else {
          this.setError(`Erros no formulário`)
        }
      })
    },
    ...mapActions('Events', ['saveNewEvent']),
    ...mapMutations('Shared', ['setError', 'setInfoMessage'])
  },
  mounted () {
    if (!this.selectedTruck.truck_id) {
      this.setError('Caminhão deve ser selecionado')
      this.$router.push('/')
    } else {
      this.form.kmatual = this.selectedTruck.km
    }

    this.$$logging(`${this.$vnode.tag} Opened`, 'Events', 'Load Component')
  },
  beforeMount () {
    this.rules.fotoComp[0].required = this.companieEventsSettings.evtPayFuelNeedCompPhotoForce
    this.rules.fotoOdom[0].required = this.companieEventsSettings.evtPayFuelNeedOdomPhotoForce
  }
}
</script>
