<template>
  <div>
    <h1>Iniciar Entregas</h1>

    <events-actions :disabled="(selectedTruck.truck_id) ? false : true"/>

    <el-form ref="openCargoForm" status-icon :model="form" :rules="rules" label-position="top">

      <el-form-item label="Selecione o veículo utilizado" prop="truck_id" >
        <el-row type="flex" justify="center" :gutter="1">
          <el-col :span="19">
          <el-select v-model="form.truck_id" placeholder="Veículos" @change="changeTruck">
            <el-option
              v-for="truck in allTrucks"
              :key="truck.truck_id"
              :label="truck.truck_id"
              :value="truck.truck_id">
              <span style="float: left">{{ truck.truck_id }} - {{ truck.truck_model }}</span>
              <!--<span style="float: right; color: #8492a6; font-size: 13px">{{ truck.truck_model }}</span>  -->
            </el-option>
          </el-select>
          </el-col>
          <el-col :span="5">
            <el-button plain icon="el-icon-refresh" @click="changeTruck" style="margin:0;"/>
          </el-col>
        </el-row>
        <div class="el-form-item__error el-form-error-message-to-success" v-if="hasValidCargo">
          {{ validCargoFountMessage }}
        </div>

      </el-form-item>

      <el-form-item label="Informe o KM atual do veículo" prop="initialKm">
        <input-mask-number-field
          @updated="$refs['openCargoForm'].validateField('initialKm')"
          :value.sync="form.initialKm"
          prefix="Km"
          :decimals=1 />
      </el-form-item>

      <el-form-item label="Foto do odômetro" prop="foto" v-if="odomPhoto.enabled">
        <foto-upload-button
          @hasImage="hasImage = $event"
          @onImage="form.foto = $event"
          outputFormat="base64"
          :showImagePreview="true"
          beforeText="Foto do odômetro"
          afterText="Foto do odômetro pronta" >
        </foto-upload-button>
      </el-form-item>

      <div class="actions-buttons actions-buttons-fullWidth">
        <el-button type="success" @click="openCargo">Iniciar entregas</el-button>
        <el-button v-if="isApple && isSyncingPending <= 0" type="info">
          {{isSyncingPending}} foto(s) na fila
        </el-button>
        <el-button v-if="isApple && connectionTypeBypass && isSyncingPending > 0" type="info" @click="hackWifi">
          <span v-if="isSyncing">Enviando...</span><span v-if="!isSyncing">Preparando para enviar...</span>
          <br>Toque aqui para desativar o envio de fotos
          <br>{{isSyncingPending}} foto(s) na fila
        </el-button>
        <el-button v-if="isApple && !connectionTypeBypass && isSyncingPending > 0" type="warning" @click="hackWifi">
          Existem fotos pendentes para enviar
          <br>Toque aqui para ativar o envio manual de fotos
          <br>{{isSyncingPending}} foto(s) na fila
        </el-button>
        <el-button type="danger" @click="userLogout">Sair</el-button>
      </div>

    </el-form>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import FotoUploadButton from '@/components/Shared/FotoUploadButton'
import EventsActions from '@/components/Shared/EventsActions'
import InputMaskNumberField from '@/components/Shared/InputMaskNumberField'
import validateKm from '@/helpers/validateKm'

export default {
  name: 'Home',
  components: {
    FotoUploadButton,
    InputMaskNumberField,
    EventsActions
  },
  data () {
    return {
      form: {
        initialKm: 0,
        foto: null,
        truck_id: ''
      },
      validCargoFountMessage: '',
      hasImage: false,
      hasValidCargo: false,
      isApple: this.$$browser.isApple,
      rules: {
        // operation: [
        //   { required: true, message: 'Selecionar uma operação é obrigatório' }
        //   // { type: 'email', message: 'Please enter a valid email address', trigger: 'blur'  }
        //   // {required: true, pattern:/\D*([2-9]\d{2})(\D*)([2-9]\d{2})(\D*)(\d{4})\D*/, message: 'Please input phone', trigger: 'blur' }
        // ],
        truck_id: [
          { required: true, message: 'Selecionar um veículo é obrigatório' },
          { validator: this.validateTruckCargo, trigger: 'none' }
          // { min: 6, message: 'Your password is too short!', trigger: 'blur' }
        ],
        initialKm: [
          { required: true, message: 'Informar o km atual do veículo é obrigatório' },
          { validator: validateKm }
        ],
        foto: [
          { required: true, message: 'Foto do odômetro é obrigatória' }
        ]

        // Outra forma de escrever a regra do validadeKm é colocando a rule diretamente no componente
        // <el-form-item label="Informe o KM atual do veículo" prop="initialKm"
        //   :rules="[
        //       { required: true, message: 'Km é obrigatório' },
        //       { type: 'number', min: this.lastTrukKm, message: 'Km não pode ser menor que já cadastrado' }
        //     ]"
        //   >
        // https://github.com/yiminghe/async-validator
      }
    }
  },
  computed: {
    ...mapState('Trucks', ['allTrucks', 'selectedTruck']),
    ...mapState('Cargos', ['cargo']),
    ...mapState('Shared', ['onlineState', 'connectionTypeBypass', 'isSyncingPending', 'isSyncing']),
    ...mapGetters('User', ['companieGeneralSettings']),
    odomPhoto () {
      let setting = {
        enabled: this.$$to(this, 'companieGeneralSettings.odomPhotoOnOpenCargo')
      }

      if (setting.enabled === undefined) setting.enabled = true

      return setting
    }
  },
  methods: {
    ...mapActions('Trucks', ['setSelectedTruck', 'getTrucks']),
    ...mapActions('User', ['userLogout', 'getUserProfile']),
    ...mapActions('Cargos', ['getAndOpenCargo', 'getOpenedCargos', 'setDeliverCustomer', 'loadCargoByTruckId', 'loadCargo']),
    ...mapMutations('Shared', ['setError', 'setLoadingMessage', 'setConnectionTypeBypass']),
    ...mapMutations('Cargos', ['clearCargos']),
    async openCargo () {
      // Valido o form e também rodo this.changeTruck(false) para
      // verifico novamente se veiculo possui carga aberta no ato da abertura da carga
      // Evitando que por demora no submit do form pelo usuário e erro de preenchimento do usuário
      // Que uma a carga do veículo já aberta por outro usuário seja carregada novamente.
      await this.changeTruck(false)

      this.$refs['openCargoForm'].validate(valid => {
        if (valid) {
          this.getOpenedCargos()
            .then(openedCargo => {
              if (openedCargo) {
                this.clearCargos()
                throw new Error(`Detectamos que existe outra carga aberta com o seu login, finalize esta carga antes de iniciar uma nova. <br><br> Se esta mensagem é inesperada, talvez seu login esteja em uso por outro dispositivo, troque asua senha acesso e contate seu supervisor para corrigir este problema. <br><br> Carga ${openedCargo.branch_id}/${openedCargo.cargo_id} aberta em ${this.$moment.unix(openedCargo.started_at.seconds).format('DD/MM/YYYY HH:mm')} com o veídulo ${openedCargo.truck_id}`)
              } else {
                return true
              }
            })
            .then(tested => {
              return this.getAndOpenCargo(this.form)
            })
            .then(cargas => {
              if (!cargas.error) this.$router.push('/cargo')
            })
            .catch(err => {
              if (err.message) {
                this.$alert(err.message, 'Conflito de carga encontrda!', {
                  confirmButtonText: 'OK',
                  type: 'error',
                  dangerouslyUseHTMLString: true
                })
              }
            })
        } else {
          this.setError(`Erros no formulário`)
        }
      })
    },
    changeTruck (resetKmForm = true) {
      let truckSelected = this.allTrucks.find(truck => truck.truck_id === this.form.truck_id)
      this.setSelectedTruck(truckSelected)
      return this.loadCargoByTruckId(this.form.truck_id)
        .then(cargo => {
          if (cargo) {
            this.validCargoFountMessage = `Carga ${cargo.cargo_id} disponível`
            this.hasValidCargo = true
            this.$refs['openCargoForm'].validateField('truck_id', (result) => {})
            if (resetKmForm) this.form.initialKm = truckSelected.km || 0
            return true
          } else {
            this.validCargoFountMessage = ``
            this.hasValidCargo = false
            this.$refs['openCargoForm'].validateField('truck_id', (result) => {})
            if (resetKmForm) this.form.initialKm = truckSelected.km || 0
            return false
          }
        })
    },
    validateTruckCargo (rule, value, callback) {
      if (!this.hasValidCargo) {
        callback(new Error('Veículo sem carga'))
      } else {
        callback()
      }
    },
    hackWifi () {
      this.setConnectionTypeBypass(!this.connectionTypeBypass)
    }
  },
  beforeRouteEnter (to, from, next) {
    // From events screen to Home screen bypass database querys
    // HAck libera update quando retorna da tela de outros eventos
    if (/\/events\/pay/.test(from.fullPath) && to.name === 'Home') to.meta.byPassReload = true
    else delete to.meta.byPassReload

    // From Login and events screen to Home screen bypass database querys
    // HAck libera update quando retorna da tela de outros eventos
    if ((/\/login/.test(from.fullPath) || /\/events\/pay/.test(from.fullPath)) && to.name === 'Home') to.meta.byPassReloadUser = true
    else delete to.meta.byPassReloadUser
    next()
  },
  beforeMount () {
    // Bypass refresh de informações do banco caso retorne de uma das abas de eventos
    // Isso reduz a quantide de consultas no banco

    if (!this.$route.meta.byPassReloadUser) {
      // Update user and company data and settings
      this.getUserProfile()
      delete this.$route.meta.byPassReloadUser
    }

    if (!this.$route.meta.byPassReload) {
      // Update Truck List and check if has a opened cargo
      this.setLoadingMessage('Buscando veículos...')
      this.getTrucks()
        .then(trucks => {
          this.setLoadingMessage('Buscando cargas abertas...')
          return this.getOpenedCargos()
        })
        .then(openedCargo => {
          if (openedCargo) {
            this.loadCargo(openedCargo) // Commit cargo
            this.setSelectedTruck(this.allTrucks.find(truck => truck.truck_id === openedCargo.truck_id)) // Commit selected truck from cargo
            this.setLoadingMessage('')
            this.$router.push('/cargo') // Redirect to cargos list
            return false
          } else {
            this.setLoadingMessage('')
            return true
          }
        })
        .then(needCheckAvaliableCargo => {
          // Verifico se caminhão seleciondo contem uma carga disponível
          if (needCheckAvaliableCargo && this.selectedTruck.truck_id) this.changeTruck()
        })

      delete this.$route.meta.byPassReload
    }
  },
  created () {
    // Reset cargo data
    this.clearCargos()
    this.setDeliverCustomer()

    // If truck selected, fill form
    if (this.selectedTruck.truck_id) {
      this.form.truck_id = this.selectedTruck.truck_id
      this.form.initialKm = this.selectedTruck.km
    }
  }
}
</script>
