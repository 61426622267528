<template>
  <div>
    <h1>Cancelamento da nota: <span class="txt-azul"> {{invoiceId}} </span></h1>

    <!-- Dados do invoice -->
    <invoice-details :invoice="invoice"/>

    <el-form ref="invoiceCancellationForm" status-icon :model="form" :rules="rules">

      <el-form-item label="Qual o motivo do cancelamento?" prop="cancellationReason">
        <el-select v-model="form.cancellationReason" placeholder="Selecione..." @change="needPhoto">
          <el-option
            v-for="item in cancellationReasons"
            :key="item.id"
            :label="item.description"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="form.photoNeeded" label="Adicione uma foto" prop="photo">
        <foto-upload-button
          @onImage="form.photo = $event"
          outputFormat="base64" >
        </foto-upload-button>
      </el-form-item>

      <el-form-item label="Observações adicionais?" prop="cancellationNotice" >
        <el-input type="textarea" v-model="form.cancellationNotice" placeholder="Adicione informações relevantes para o cancelamento" />
      </el-form-item>

      <div class="actions-buttons actions-buttons-fullWidth">
        <el-button type="success" @click="cancelConfirm">Confirmar cancelamento</el-button>
        <el-button type="info" @click="$router.go(-1)">Voltar</el-button>
      </div>

    </el-form>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import FotoUploadButton from '@/components/Shared/FotoUploadButton'
import InvoiceDetails from '@/components/Cargo/Complements/InvoiceDetails'

export default {
  name: 'invocie-partial-cancellation',
  components: {
    FotoUploadButton,
    InvoiceDetails
  },
  data () {
    return {
      form: {
        cancellationReason: null,
        cancellationNotice: null,
        photo: null,
        photoNeeded: false
      },
      rules: {
        cancellationReason: [
          { required: true, message: 'Motivo do cancelamento é obrigatório' }
        ],
        cancellationNotice: [
          { required: false, message: 'Descreva os motivos do cancelamento' }
        ],
        photo: [
          { required: true, message: 'Foto é obrigatório' }
        ]
      },
      gridDataDetails: [],
      invoice: {},
      invoiceIndex: -1,
      selectedReason: {}
    }
  },
  computed: {
    ...mapState('Cargos', ['deliverCustomer']),
    ...mapGetters('User', ['cancellationReasons']),
    invoiceId () {
      return this.$route.params.invoice.replace('xbarx', '/')
    }
  },
  methods: {
    ...mapMutations('Cargos', ['mixDeliverCustomer', 'mixDeliverCustomerInvoice']),
    needPhoto (selectedReasonId) {
      this.selectedReason = this.cancellationReasons.find(reason => reason.id === selectedReasonId)
      this.form.photoNeeded = this.selectedReason.photo || false
      this.form.photo = null
    },
    cancelConfirm () {
      this.$refs['invoiceCancellationForm'].validate((valid) => {
        if (valid) {
          this.mixDeliverCustomer({
            has_invoice_canceled: true
          })

          this.mixDeliverCustomerInvoice({
            invoiceNumber: this.invoice.invoice,
            deletePartial: true,
            data: {
              status: 'canceled',
              cancel_at: { seconds: Math.round((new Date()).getTime() / 1000) },
              cancel_photo: this.form.photo,
              cancel_notice: this.form.cancellationNotice,
              cancel_reason: this.form.cancellationReason,
              cancel_desc: this.selectedReason.description,
              receipt_photo: null
            }
          })
          this.$router.go(-1)
        }
      })
    }
  },
  created () {
    this.invoiceIndex = Object.values(this.deliverCustomer.invoices).findIndex(invoice => invoice.invoice === this.invoiceId)
    this.invoice = this.deliverCustomer.invoices[this.invoiceIndex]
  }
}
</script>

<style scoped>
.txt-azul {
  color:#00AFEF;
  font-size: 15px;
}
</style>
