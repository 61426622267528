import { store } from '@/store'

const defaultRouteTo = '/cargo'

function operate (guards, from, to, lastNext, i) {
  let guard = guards[i]
  // Se última função
  if (guards.length === i + 1) {
    guard(from, to, lastNext)

  // Se não, verifica o valor do next, se undefined chama próxima função
  } else {
    guard(from, to, function (nextArg) {
      switch (typeof (nextArg)) {
        case 'undefined':
          operate(guards, from, to, lastNext, i + 1)
          break
        case 'object':
          lastNext(nextArg)
          break
        case 'boolean':
          lastNext(nextArg)
          break
        case 'string':
          lastNext(nextArg)
          break
      }
    })
  }
}

export default {
  // Hack para suportar na rota multiplos guards, ainda não disponivel no vue
  multiple (ListOfGuards) {
    return function (from, to, next) {
      operate(ListOfGuards, from, to, next, 0)
    }
  },

  deliverCustomerIsSet (to, from, next) {
    if (!store.state.Cargos.deliverCustomer.customer) {
      console.warn('Invalid deliverCustomer data... cant continue')
      next(to.meta.onErrorValidateRouteTo || defaultRouteTo)
    } else {
      next()
    }
  },

  cargoIsSet (to, from, next) {
    if (store.state.Cargos.cargo.length === 0) {
      console.warn('Invalid cargo data... cant continue')
      next(to.meta.onErrorValidateRouteTo || defaultRouteTo)
    } else {
      next()
    }
  },

  truckIsSelected (to, from, next) {
    if (!store.state.Trucks.selectedTruck.truck_id) {
      console.warn('Invalid truck data... cant continue')
      next(to.meta.onErrorValidateRouteTo || defaultRouteTo)
    } else {
      next()
    }
  },

  statusIsSet (to, from, next) {
    if (!store.state.Cargos.deliverCustomer.status) {
      console.warn('Invalid deliver Customer status... cant continue')
      next(to.meta.onErrorValidateRouteTo || defaultRouteTo)
    } else {
      next()
    }
  },

  statusIsNotFinished (to, from, next) {
    if (store.state.Cargos.deliverCustomer.status === 'delivered' || store.state.Cargos.deliverCustomer.status === 'canceled') {
      console.warn('deliverCustomer with status already seted... cant continue')
      next(to.meta.onErrorValidateRouteTo || defaultRouteTo)
    } else {
      next()
    }
  }
}
