import { db, timestamp } from '@/config/firebase'
import getLocation from '@/services/getLocation'
import t from 'typy'

export default {
  getTrucks ({ commit, rootState }) {
    let trucks = []
    let userBranchId = t(rootState, 'User.profile.branch_id').safeObject

    let colRef = db.collection('companies')
      .doc(rootState.User.profile.company_id)
      .collection('trucks').orderBy('truck_id')
      .where('is_disabled', '==', false)

    if (userBranchId !== undefined) colRef = colRef.where('branch_id', '==', userBranchId)

    return colRef
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = {
            'id': doc.id,
            'branch_id': doc.data().branch_id,
            'truck_id': doc.data().truck_id,
            'truck_model': doc.data().truck_model,
            'km': doc.data().km
          }
          trucks.push(data)
        })
        commit('changeTrucks', trucks)
        return trucks
      })
      .catch(err => { throw new Error(err) })
  },
  saveAndSetTruckNewKm ({ commit, rootState, state }, payload) {
    // Recebe objecto {km: newKm, truck_id: truckId}
    // ou apenas o Km e atualiza o truck que estiver definido em selectedTruck

    const newKm = parseFloat(payload.km) || payload
    const truckId = payload.truck_id || state.selectedTruck.truck_id || undefined
    const companyId = t(rootState, 'User.profile.company_id').safeObject
    const userId = t(rootState, 'User.profile.id').safeObject || null

    if (truckId !== undefined && companyId !== undefined && !isNaN(newKm)) {
      const truckRef = db.collection('companies').doc(companyId).collection('trucks').doc(truckId)

      // Commit chages to state
      commit('changeSelectedTruckKm', newKm)

      // Save to database new Km and location
      return getLocation()
        .then(location => {
          return {
            location,
            km: newKm,
            updated_at: timestamp,
            last_user_id: userId
          }
        })
        .then(dataToUpdate => truckRef.update(dataToUpdate))
        .then(() => {
          // console.info(`Doc trucks/${truckId}/km successfully updated with ${newKm}`)
          return `Doc trucks/${truckId}/km successfully updated with ${newKm}`
        })
    } else {
      throw new Error('saveAndSetTruckNewKm: truckID or companyID cant be empty or invalid number')
    }
  },
  saveTruckLocation ({ commit, rootState, state, rootGetters }) {
    // Save to firebase the last truck location
    // Only in blocks of 30 seconds
    const now = Math.round((new Date()).getTime() / 1000)
    const lastUpdateInSeconds = now - rootState.Shared.lastLocationUpdateDB
    const maxInterval = rootGetters['User/companieGeneralSettings'].updateTruckLocationInterval || 300

    if (lastUpdateInSeconds >= maxInterval) {
      const truckId = t(state, 'selectedTruck.truck_id').safeObject
      const companyId = t(rootState, 'User.profile.company_id').safeObject
      const userId = t(rootState, 'User.profile.id').safeObject || null

      if (truckId !== undefined && companyId !== undefined) {
        commit('Shared/setLastUpdateLocationDB', null, { root: true })
        const truckRef = db.collection('companies').doc(companyId).collection('trucks').doc(truckId)

        return getLocation()
          .then(location => {
            return {
              location,
              updated_at_location: timestamp,
              last_user_id: userId
            }
          })
          .then(dataToUpdate => truckRef.update(dataToUpdate))
          .then(() => {
            // console.info(`Doc trucks/${truckId}/location successfully updated`)
            return `Doc trucks/${truckId}/location successfully updated`
          })
      }
    }
  },
  setSelectedTruck ({ commit }, payload) {
    if (payload) {
      commit('changeSelectedTruck', payload)
    } else {
      commit('changeSelectedTruck', false)
    }
  }
}
