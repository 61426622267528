<template>
  <div class="cargo-title-slot-component"
    @click="titleClick(customer)"
    :class="{
      'background-success': customer.status === 'delivered',
      'background-canceled': customer.status === 'canceled',
      'background-partial': customer.has_invoice_partial,
      'background-canceled2': customer.has_invoice_canceled
      }" >

    <div class="icon-status icon-success"
      :class="{
        'icon-success-warning': customer.has_invoice_partial,
        'icon-success-error': customer.has_invoice_canceled
      }"
      v-if="customer.status === 'delivered'">
        <i class="el-icon-success" />
    </div>

    <div class="icon-status icon-canceled"
      v-if="customer.status === 'canceled'">
        <i class="el-icon-error"></i>
    </div>

    <div class="icon-status icon-info"
      v-if="customer.status === 'reavailable'">
        <i class="el-icon-info"></i>
    </div>

    <div class="icon-status icon-canceled"
      v-if="customer.status === 'delivering'">
        <icon class="icon" name="truck-moving"></icon>
    </div>

    <div class="icon-status icon-blank" v-if="!customer.status"></div>

    <div class=titulo-texts>
      <div v-if="!!customer.fantasy_name" class="fantasia-item"> {{customer.fantasy_name}} </div>
      <div class="titulo-item"> {{customer.customer}} </div>
      <div class="titulo-endereco"> {{(customer.address1).toLowerCase()}} / {{(customer.neighborhood).toLowerCase()}}</div>
    </div>

    <div class="icon"><i class="el-icon-arrow-right el-icon-right" /></div>

  </div>
</template>

<script>
export default {
  name: 'cargo-title-slot',
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  methods: {
    titleClick (customer) {
      this.$emit('click', customer)
    }
  }
}
</script>

<style scoped>

  .cargo-title-slot-component {
    margin: 5px 0px;
    display: flex;
    position: relative;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    box-sizing: border-box;
    -webkit-appearance: none;
    -webkit-box-sizing: border-box;
    -webkit-transition: .1s;
    transition: .1s;
    color: #606266;
    line-height: 40px;
    font-weight: 500;
    font-size: 14px;
    justify-content: flex-start;
    align-items: center;
  }

  .icon {
    margin-left: auto;
    margin-right: 4px;
  }

  .icon-status {
    line-height: 40px;
    margin-left: 2px;
    margin-right: 2px;
  }

  .icon-blank {
    width: 14px;
  }

  .titulo-texts {
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 2px;
    margin-left: 2px;
  }

  .titulo-item {
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    color: black !important;
    line-height: 15px;
  }
  .fantasia-item {
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    color: black !important;
    line-height: 15px;
    margin-bottom: 10px;
    text-decoration: underline;
  }

  .titulo-endereco {
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    color: black !important;
    line-height: 15px;
    font-style: italic;
    font-size: 12px;
    text-transform: capitalize;
  }

  .icon-success i {
    color:#008000;
  }

  .icon-success-warning i {
    color:#e77b00;
  }

  .icon-success-error i {
    color:#ff0000;
  }

  .icon-canceled i {
    color: #ff0000;
  }

  .background-success {
    background-color: #e1fce1
  }

  .background-canceled {
    background-color: #fce1e1
  }

  .background-partial {
    background-color: #fcf3e1
  }

  .background-canceled2 {
    background-color: #fcf3e1
  }

</style>
