<template>
  <el-dialog
    :title="title"
    :visible.sync="showThisDialog"
    :before-close="onClose"
    center
    custom-class="edit-payment-dialog"
    width="100%"
    top="69px"
    :show-close="false"
  >

  <span class="h2-descricao" v-if="invoice.total !== undefined">Valor total da nota fiscal: {{ `R$ ${invoice.total.toFixed(2)}` }}</span><br>
  <span class="h2-descricao" v-if="totalNewPays !== undefined">Valor total em pagamentos: {{ `R$ ${totalNewPays.toFixed(2)}` }}</span><br>
  <span class="h2-descricao-alert" v-if="!correctPayment && formSubmitted"><center>Valor total da nota não é igual com o total de pagamento informado</center></span>

  <br />
  <span class="h2-descricao">Forma de pagamento original</span><br>
  <span class="h2-descricao"
    v-for="(payment, index) in paymentData"
    :key="`payit_dialog_${index}`"
    >
    {{ `R$ ${parseFloat(payment.ammount).toFixed(2)} com ${(payment.nameFromCheckin || payment.name)}` }} <br>
  </span>

  <br />
  <div>
    <span class="h2-descricao">Nova forma de pagamento</span><br>
    <el-form :model="form" :rules="rules" ref="editPaymentDialofRef" label-width="60px" label-position="right" inline>
    <div v-for="(payment, index) in form.newPayments" :key="`payment.${payment.key}`" >
      <div class=new-payment-item>
        <el-form-item
          :prop="`newPayments.${index}.name`"
          :rules="[
              { required: true, message: 'Obrigatorio', trigger: 'blur' }
            ]">
        <el-select v-model="form.newPayments[index].name"
          placeholder="Selecione a nova forma de pagamento"
          @change="paymentChange(form.newPayments[index].name, index)"
          >

          <el-option
            v-for="(paymentDB) in enabledPayments"
            :key="paymentDB.code"
            :label="paymentDB.name"
            :value="paymentDB.name">
          </el-option>
        </el-select>
        </el-form-item>

        <el-form-item
          :prop="`newPayments.${index}.ammount`"
          :rules="[
            {  min: 0.01, required: true, message: 'Obrigatorio', trigger: 'blur' }
          ]">

          <input-mask-number-field
            @updated="$refs['editPaymentDialofRef'].validateField(`newPayments.${index}.ammount`)"
            :value.sync="form.newPayments[index].ammount"
            prefix=""
            width="110px"
            :decimals=2 />

<!--
            <el-input-number v-model.number="form.newPayments[index].ammount"
              :precision="2" :step="0.01"
              :controls="false"
              placeholder="Valor"
              type="number"
              class="new-payment-ammount"
            /> -->
        </el-form-item>
      </div>
        <el-form-item label="NSU"
          :prop="`newPayments.${index}.nsu`"
          :ref="`npfi.${index}.nsu`"
          class="new-payment-form-item"
          v-if="newPaymentHasNsu(form.newPayments[index].code)"
          :rules="[
            { required: newPaymentHasNsuForced(form.newPayments[index].code), message: 'Número NSU obrigatório', trigger: 'blur' },
            { min: 5, message: 'NSU inválida, mínimo de 5 digitos', trigger: 'blur' }
          ]"
          >
          <el-input v-model="form.newPayments[index].nsu"
            placeholder="Número do NSU"
            type="number"
            class="new-payment-nsu"
          />
        </el-form-item>
    </div>
    </el-form>

    <br>
    <div class=new-payment-buttons>
      <el-button @click="removePayment()" plain
        :disabled="(form.newPayments.length) === 1"
        type="info" icon="el-icon-remove">
        Remover
      </el-button>

      <el-button @click="addPayment()" plain
        type="primary" icon="el-icon-circle-plus">
        Adicionar
      </el-button>

    </div>

    <div class=new-payment-buttons>
      <el-button @click="onClose()"
        type="info" icon="el-icon-error">
        Cancelar
      </el-button>

      <el-button @click="savePayment()"
        type="primary" icon="el-icon-success">
        Confirmar
      </el-button>
    </div>
  </div>

  </el-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import InputMaskNumberField from '@/components/Shared/InputMaskNumberField'

export default {
  name: 'edit-payment-dialog',
  props: {
    title: {
      default: 'Alterar pagamento',
      type: String
    }
  },
  components: {
    InputMaskNumberField
  },
  data () {
    return {
      showThisDialog: false,
      form: {
        newPayments: []
      },
      invoice: {},
      paymentData: {},
      formSubmitted: false,
      defaultNewPayments: {
        key: 1,
        name: '',
        ammount: 0,
        code: '',
        type: '',
        nsu: null
      },
      rules: {}
    }
  },
  computed: {
    ...mapGetters('User', ['companiePaymentsSettings']),
    paysWithNSUenabled() {
      return Object.values(this.companiePaymentsSettings).filter(payset => payset.nsu && payset.nsu === true)
    },
    paysWithNSUForcedenabled() {
      return Object.values(this.companiePaymentsSettings).filter(payset => payset.forcensu && payset.forcensu === true)
    },
    enabledPayments () {
      return Object.values(this.companiePaymentsSettings)
        .filter(payment => payment.enabled === true)
    },
    totalNewPays () {
      if (this.form.newPayments) {
        return this.form.newPayments.reduce((prev, e) => (e.ammount && e.ammount !== '') ? prev + parseFloat(e.ammount) : prev, 0)
      } else {
        return 0
      }
    },
    correctPayment () {
      return this.totalNewPays === this.invoice.total
    }
  },
  mounted () {},
  methods: {
    ...mapMutations('Shared', ['setError', 'setInfoMessage']),
    showDialog (invoice) {
      // this.paymentData = { ...(invoice.paymentEdited || invoice.payment) }
      this.paymentData = { ...invoice.payment }
      this.invoice = invoice
      this.showThisDialog = true

      if (invoice.paymentEdited) {
        this.form.newPayments = JSON.parse(JSON.stringify(invoice.paymentEdited))
      } else {
        this.form.newPayments.push({ ...this.defaultNewPayments })
      }
    },
    onClose () {
      this.showThisDialog = false
      this.invoice = {}
      this.paymentData = {}
      this.form.newPayments = []
      this.formSubmitted = false
    },
    addPayment () {
      this.form.newPayments.push({
        ...this.defaultNewPayments,
        key: (this.form.newPayments.length + 1)
      })
    },
    removePayment () {
      this.form.newPayments.pop()
    },
    savePayment () {
      this.formSubmitted = true
      this.$refs['editPaymentDialofRef'].validate((valid) => {
        if (valid && this.correctPayment) {
          this.$emit('savePayment', {
            newPayments: this.form.newPayments,
            invoice: this.invoice
          })
          this.onClose()
        } else {
          this.setError(`Erros no formulário`)
        }
      })
    },
    paymentChange (selected, idx) {
      let selectedPay = this.enabledPayments.find(pay => pay.name === selected)
      this.form.newPayments[idx].code = selectedPay.code
      this.form.newPayments[idx].type = selectedPay.type

      // Remove o nsu caso tenha trocado de opção
      let that = this
      setTimeout(() => {
        if (!(this.$refs[`npfi.${idx}.nsu`] && this.$refs[`npfi.${idx}.nsu`].length > 0)) {
          that.form.newPayments[idx].nsu = null
        }
      }, 400)
    },
    newPaymentHasNsu (code) {
      let nsuEnabledCodes = []
      Object.values(this.paysWithNSUenabled).map(pay => {
        nsuEnabledCodes.push(pay.code)
        nsuEnabledCodes.push(pay.type)
      })
      let enabled = nsuEnabledCodes.some(pay => nsuEnabledCodes.indexOf(code) >= 0)
      // console.log('newPaymentHasNsu on payDialog', code, enabled, nsuEnabledCodes)
      return enabled
    },
    newPaymentHasNsuForced (code) {
      let nsuEnabledCodes = []
      Object.values(this.paysWithNSUForcedenabled).map(pay => {
        nsuEnabledCodes.push(pay.code)
        nsuEnabledCodes.push(pay.type)
      })
      let enabled = nsuEnabledCodes.some(pay => nsuEnabledCodes.indexOf(code) >= 0)
      // console.log('newPaymentHasNsuForced on payDialog', code, enabled, nsuEnabledCodes)
      return enabled
    }
  }
}
</script>

<style lang="scss">
.edit-payment-dialog {
  .el-dialog__body {
    margin: auto;
    width: 100%;
  }

  .new-payment-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .new-payment-form-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-bottom: 10px !important;
  }

  .new-payment-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-bottom: 10px;

    button {
      width: 90%;
    }
  }

  .new-payment-ammount {
    width: 130px;
  }

  .new-payment-nsu {
    width: 200px;
    margin-left: 10px;
  }

  .el-form-item__error {
    display: none;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .h2-descricao {
    font-size: 14px;
    margin-bottom: 10px;
    color: #808080;
    line-height: 1.5;
    margin-top: 5px;
  }

  .h2-descricao-alert {
    font-size: 14px;
    margin-bottom: 10px;
    color: #808080;
    line-height: 1.5;
    margin-top: 5px;
    color: red;
    font-weight: bold;
  }
}
</style>
