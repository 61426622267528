import { getDefaultUserState, getDefaultCompanyState } from './state'

export default {
  'resetState' (state) {
    Object.assign(state, getDefaultUserState())
  },
  'changeUser' (state, payload) {
    state.profile = payload.profile || null
    state.token = payload.token || null
    state.lastloggedin = payload.lastloggedin || null
    if (payload.companie.id) {
      this.commit('User/changeCompanie', payload.companie)
    }
  },
  'clearUser' (state) {
    state.profile = null
    state.token = null
    state.lastloggedin = null
    this.commit('User/clearCompanie')
  },
  'clearCompanie' (state) {
    state.companie = Object.assign(state.companie, getDefaultCompanyState())
  },
  'changeCompanie' (state, payload) {
    if (payload === '' || !payload) {
      this.commit('User/clearCompanie')
    } else if (payload.id) {
      state.companie = payload
    }
  }
}
