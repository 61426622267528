<template>
  <div>
    <h2>Status geral</h2>
    <el-table :data="statusTable" size="mini" :show-header=false :class="'about-tables'">
      <el-table-column prop="field" label="Conf" width="200" align="right"></el-table-column>
      <el-table-column prop="value" label="Valor"> </el-table-column>
    </el-table>

    <h2>Fila de fotos</h2>
    <center><el-button v-if="filesTable.length > 0" type="info" size="mini" @click="resetQueue">Reiniciar fila</el-button></center>
    <el-table :data="filesTable" size="mini" :show-header=false :class="'about-tables'">
      <el-table-column prop="key" label="ID" width="100" align="right"></el-table-column>
      <el-table-column prop="status" label="Status"> </el-table-column>

      <el-table-column label="Imagens" width="120">
        <template slot-scope="scope"
          ><img
            :src="scope.row.fileData"
            width="100%"
            height="100%"
            style="cursor: pointer;"
        /></template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import openStore from '@/services/localForageService'
import env from '@/config/environment'
import fileUpload from '@/services/fileUploadService'

export default {
  name: 'About',
  data () {
    return {
      statusTable: [],
      filesTable: [],
      indexDbStore: openStore('files')
    }
  },
  computed: {
    ...mapState('Shared', [
      'onlineState',
      'isSyncing',
      'isSyncingPending',
      'isGPSError',
      'uploadingStatus',
      'uploadingFiles'
    ]),
    ...mapGetters('Shared', ['isConnectionFast']),
    ...mapGetters('User', ['companieImagesSettings'])
  },
  async mounted () {
    this.statusTable = [
      { field: 'Online', value: (this.onlineState ? 'Sim' : 'Não') },
      { field: 'Wifi', value: (this.isConnectionFast ? 'Conectado' : 'Desconectado') },
      { field: 'GPS', value: (this.isGPSError ? 'Com falha' : 'OK') },
      { field: 'Fotos na fila', value: this.isSyncingPending },
      { field: 'Intervalo de atualização', value: `${env.config.wifiInterval / 1000} s` },
      { field: 'Enviar somente no Wifi', value: (this.companieImagesSettings.uploadOnlyWifi ? 'Sim' : 'Não') },
      { field: 'Enviando fotos', value: (this.isSyncing ? 'Sim' : 'Não') }
    ]

    // Atualiza array com status
    this.updateTable()

    // Agenda update do array de status da fila no mesmo tempo do atualização
    setInterval(() => {
      this.updateTable()
    }, env.config.wifiInterval)
  },
  methods: {
    ...mapMutations('Shared', ['setError', 'setUploadingFirstLoad']),
    resetQueue () {
      return this.$confirm('Reiniciar envio de imagens?', 'Fila de imagens', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning'
      })
        .then(() => {
          this.setUploadingFirstLoad(true)
          this.updateTable()
          fileUpload.queueImageUpload()
        })
        .catch(() => {})
    },
    async updateTable () {
      this.statusTable = [
        { field: 'Online', value: (this.onlineState ? 'Sim' : 'Não') },
        { field: 'Wifi', value: (this.isConnectionFast ? 'Conectado' : 'Desconectado') },
        { field: 'GPS', value: (this.isGPSError ? 'Com falha' : 'OK') },
        { field: 'Fotos na fila', value: this.isSyncingPending },
        { field: 'Intervalo de atualização', value: `${env.config.wifiInterval / 1000} s` },
        { field: 'Enviar somente no Wifi', value: (this.companieImagesSettings.uploadOnlyWifi ? 'Sim' : 'Não') },
        { field: 'Enviando fotos', value: (this.isSyncing ? 'Sim' : 'Não') }
      ]

      this.filesTable = []
      await this.indexDbStore.iterate((value, key, iterationNumber) => {
        this.filesTable.push({
          key,
          ...value
        })
      })
    }
  }
}
</script>

<style>
  .about-tables td {
    padding: 0 !important;
  }

  .about-tables td:first-child {
    font-size: 10px !important;
  }

  .about-tables {
    margin-bottom:30px;
    width: 95%;
  }
</style>
