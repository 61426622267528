<template>
  <div class="delivery-prevision-component">
    <b>Você esta a 000 km de distância <br>
      Previsão de entrega: 00:00hrs</b>
  </div>
</template>

<script>
export default {
  name: 'delivery-prevision',
  data () {
    return {}
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
  .delivery-prevision-component {
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    background-color: #00AFEF;
    padding: 2px;
    margin-bottom: 5px;
    border-radius: 5px;
    line-height: 2;
    min-height: 52px;
  }
</style>
