<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Logout',
  methods: {
    ...mapActions('User', ['userLogout'])
  },
  created () {
    this.userLogout()
  }
}
</script>
