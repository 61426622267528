import { getDefaultCargosState } from './state'

export default {
  'resetState' (state) {
    Object.assign(state, getDefaultCargosState())
  },
  'changeCargos' (state, payload) {
    state.cargo = payload
  },
  'clearCargos' (state) {
    state.cargo = []
    state.deliverCustomer = {}
  },
  'changeDeliverCustomer' (state, payload) {
    state.deliverCustomer = payload
  },
  'mixCargo' (state, payload) {
    Object.assign(state.cargo, payload)
  },
  'mixDeliverCustomer' (state, payload) {
    Object.assign(state.deliverCustomer, payload)
  },
  'mixDeliverCustomerInvoice' (state, payload) {
    // payload recebe: {invoiceNumber, deletePartial: true, data: {safeguard_photo: file}}
    if (state.deliverCustomer.invoices && payload.invoiceNumber) {
      let index = Object.values(state.deliverCustomer.invoices).findIndex(invoice => invoice.order_number === payload.invoiceNumber || invoice.invoice === payload.invoiceNumber)
      if (index > -1) {
        // Remove partial informations if is defined
        if (payload.deletePartial) this.commit('Cargos/removePartialInfo', index)
        if (payload.deleteCancel) this.commit('Cargos/removeCancelInfo', index)

        // Mix content
        let newObject = Object.assign({}, state.deliverCustomer)
        Object.assign(newObject.invoices[index], payload.data)
        state.deliverCustomer = Object.assign({}, newObject)
      }
    }
  },
  'removePartialInfo' (state, index) {
    // Remove partial informations at invoice/items level
    Object.values(state.deliverCustomer.invoices[index].items).forEach(item => {
      delete item.partial
      delete item.partial_quantity
      delete item.partial_reason
      delete item.partial_desc
      delete item.partial_photo
    })

    // Remove partial informations at invoice level
    delete state.deliverCustomer.invoices[index].status
    delete state.deliverCustomer.invoices[index].partial_at
    delete state.deliverCustomer.invoices[index].safeguard_photo

    // Remove cancel informations at customer level, only if no other partial invoice found
    let hasOtherInvoicesPartial = false
    Object.values(state.deliverCustomer.invoices).forEach(invoice => {
      if (invoice.status === 'with_partial') {
        hasOtherInvoicesPartial = true
      }
    })

    if (!hasOtherInvoicesPartial) delete state.deliverCustomer.has_invoice_partial
  },
  'removeCancelInfo' (state, index) {
    delete state.deliverCustomer.invoices[index].status
    delete state.deliverCustomer.invoices[index].receipt_photo
    delete state.deliverCustomer.invoices[index].cancel_at
    delete state.deliverCustomer.invoices[index].cancel_photo
    delete state.deliverCustomer.invoices[index].cancel_notice
    delete state.deliverCustomer.invoices[index].cancel_reason
    delete state.deliverCustomer.invoices[index].cancel_desc

    // Remove cancel informations at customer level, only if no other canceled invoice found
    let hasOtherInvoicesCanceled = false
    Object.values(state.deliverCustomer.invoices).forEach(invoice => {
      if (invoice.status === 'canceled') {
        hasOtherInvoicesCanceled = true
      }
    })

    if (!hasOtherInvoicesCanceled) delete state.deliverCustomer.has_invoice_canceled
  },
  'updateTempDeliveryForm' (state, payload) {
    state.tempDeliveryFormData[payload.field] = payload.data
  },
  'clearUpdateTempDeliveryForm' (state) {
    state.tempDeliveryFormData = {}
  }
}
