<template>
  <div>
    <h1>Entrega Parcial NFe: <span class="txt-azul"> {{invoicePartialCancellation}} </span></h1>

    <!-- <div class="h2-descricao">
    Se algum item da <span class="h2-descricao-destaque"> Nota Fiscal </span> apresentar divergências ou defeitos, clique no botão <span class="h2-descricao-destaque"> Adicionar item na lista </span> para informar os itens que apresentaram problema.
    </div> -->

    <div class="txt-label"><label> Itens na lista parcial </label></div>
    <el-table
      :data="itemsCancellation"
      style="width: 100%"
      :show-header="false">
      <!-- <el-table-column type="expand" width="30">
        <template slot-scope="props">
          <p>Quantidade: {{ props.row.partial_quantity }}</p>
          <p>Motivo: {{ props.row.partial_desc }}</p>
          <p v-if="props.row.partial_photo">Foto Cadastrada: {{ (props.row.partial_photo) ? 'Sim' : 'Não' }}</p>
        </template>
      </el-table-column> -->
      <!-- <el-table-column prop="product"></el-table-column> -->
      <el-table-column>
        <template slot-scope="props">
          <p>
            {{ props.row.product }}
            <span class="spandescriptions"><br>Quantidade: {{ props.row.partial_quantity }}</span>
            <span class="spandescriptions"><br>Motivo: {{ props.row.partial_desc }}</span>
            <span class="spandescriptions" v-if="props.row.partial_photo"><br>Foto Cadastrada: {{ (props.row.partial_photo) ? 'Sim' : 'Não' }}</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column width="30">
        <template slot-scope="scope">
          <el-button @click.native.prevent="removeCancellation(scope)" type="text" size="small" class="vermelho"><i class="el-icon-delete"></i></el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="actions-buttons actions-buttons-fullWidth">
      <el-button type="success" @click="addItem">{{ (itemsCancellation.length > 0) ? 'Adicionar novo ou atualizar item' : 'Adicionar item' }}</el-button>
      <!-- <el-button type="info" @click="$router.go(-1)">Voltar</el-button> -->
      <el-button type="info" @click="$router.push(`/delivery`)">Voltar</el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import FotoUploadButton from '@/components/Shared/FotoUploadButton'

export default {
  name: 'invocie-partial-cancellation',
  components: {
    FotoUploadButton
  },
  data () {
    return {
      form: {
        itemCancellation: '',
        cancellationOption: '',
        foto: null,
        fotoNeeded: false,
        quantityCancellation: 0
      },
      rules: {
        itemCancellation: [
          { required: true, message: 'Selecionar o item é obrigatório' }
        ],
        cancellationOption: [
          { required: true, message: 'Motivo do cancelamento é obrigatório' }
        ],
        quantityCancellation: [
          { required: true, type: 'number', min: 1, message: 'Quantidade diferente de zero é obrigatório' }
        ],
        foto: [
          { required: true, message: 'Foto do produto é obrigatório' }
        ]
      },
      itemsCancellation: [],
      quantityMaxCancellation: 0,
      quantityStepCancellation: 1
    }
  },
  computed: {
    ...mapState('Cargos', ['deliverCustomer']),
    ...mapGetters('Cargos', ['deliverPartialCancellation']),
    ...mapGetters('User', ['cancellationReasonsPartial']),
    invoicePartialCancellation () {
      return this.$route.params.invoice.replace('xbarx', '/')
    },
    deliverPartialCancellationItems () {
      return Object.values((this.deliverPartialCancellation(this.invoicePartialCancellation)).items)
    }
  },
  methods: {
    ...mapActions('Cargos', ['setDeliverCustomer']),
    ...mapMutations('Cargos', ['mixDeliverCustomerInvoice']),
    removeCancellation (scope) {
      // Clono objeto
      let deliverCustomer = JSON.parse(JSON.stringify(this.deliverCustomer))

      // find invoice in deliverCustomer state
      Object.values(deliverCustomer.invoices).some(invoice => {
        if (invoice.invoice === this.invoicePartialCancellation) {
          // find item in deliverCustomer.invoices
          Object.values(invoice.items).some(item => {
            if (`${item.product}_${item.quantity}_${item.um}_${item.step}` === `${scope.row.product}_${scope.row.quantity}_${scope.row.um}_${scope.row.step}`) {
              // Delete partial info at item level in state
              delete item.partial
              delete item.partial_quantity
              delete item.partial_reason
              delete item.partial_desc
              delete item.partial_photo

              // Delete item from data table
              this.itemsCancellation.splice(scope.$index, 1)
            }
          })
        }
      })
      // Update state with new partial infos or remove all partial info from customer
      if (this.itemsCancellation.length === 0) this.removeAllCancellation()
      else this.setDeliverCustomer(deliverCustomer)
    },
    removeAllCancellation () {
      this.mixDeliverCustomerInvoice({
        invoiceNumber: this.invoicePartialCancellation,
        deletePartial: true,
        deleteCancel: false,
        data: {}
      })
    },
    addItem () {
      this.$router.push(`/delivery/invoice-partial-cancellation/${this.$route.params.invoice}/add`)
    }
  },
  mounted () {
    // Preenche datatable com dados pré cadastrados
    this.itemsCancellation = this.deliverPartialCancellationItems.filter(item => {
      return item.partial === true
    })
  }
}
</script>

<style scoped>
.el-input-number {
  margin-left: 1%;
  margin-right: 1%;
  width: 50%
}

.quantityLine >>> div {
  text-align: center;
}

.txt-azul {
  color:#00AFEF;
  font-size: 15px;
}

.txt-label {
  color: #606266;
  font-size: 14px;
  padding-bottom: 15px;
  padding-top: 15px;
}

.vermelho{
  color: #FF6347;
}

.spandescriptions {
  font-style: italic;
  font-size: 12px;
  line-height: 8px;
}

</style>
