export default {
  deliverPartialCancellation (state) {
    return (invoicePartialCancellation) => {
      if (state.deliverCustomer.invoices && invoicePartialCancellation) {
        return Object.values(state.deliverCustomer.invoices)
          .find(invoice => {
            return invoice.invoice === invoicePartialCancellation
          })
      } else {
        return null
      }
    }
  },
  deliverCustomerInvoicesCount (state) {
    if (state.deliverCustomer.invoices) return Object.keys(state.deliverCustomer.invoices).length
    else return 0
  }
}
