export default {
  isConnectionFast (state) {
    if (state.connectionType === 'wifi' || state.connectionType === 'ethernet' || state.connectionTypeBypass) return true
    else return false
  },
  swNeedUpdate (state) {
    if (state.swNeedUpdate) return true
    else return false
  }
}
