import { getDefaultTruckState } from './state'

export default {
  'resetState' (state) {
    Object.assign(state, getDefaultTruckState())
  },
  'changeTrucks' (state, payload) {
    state.allTrucks = payload
  },
  'changeSelectedTruck' (state, payload) {
    state.selectedTruck = payload
  },
  'changeSelectedTruckKm' (state, payload) {
    state.selectedTruck.km = payload
    const truckIndex = state.allTrucks.findIndex(truck => truck.truck_id === state.selectedTruck.truck_id)
    state.allTrucks[truckIndex]['km'] = payload
  },
  'clearTrucks' (state) {
    state.allTrucks = []
    state.selectedTruck = {}
  }
}
