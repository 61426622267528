var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.showThisDialog,"before-close":_vm.onClose,"center":"","custom-class":"edit-payment-dialog","width":"100%","top":"69px","show-close":false},on:{"update:visible":function($event){_vm.showThisDialog=$event}}},[(_vm.invoice.total !== undefined)?_c('span',{staticClass:"h2-descricao"},[_vm._v("Valor total da nota fiscal: "+_vm._s(`R$ ${_vm.invoice.total.toFixed(2)}`))]):_vm._e(),_c('br'),(_vm.totalNewPays !== undefined)?_c('span',{staticClass:"h2-descricao"},[_vm._v("Valor total em pagamentos: "+_vm._s(`R$ ${_vm.totalNewPays.toFixed(2)}`))]):_vm._e(),_c('br'),(!_vm.correctPayment && _vm.formSubmitted)?_c('span',{staticClass:"h2-descricao-alert"},[_c('center',[_vm._v("Valor total da nota não é igual com o total de pagamento informado")])],1):_vm._e(),_c('br'),_c('span',{staticClass:"h2-descricao"},[_vm._v("Forma de pagamento original")]),_c('br'),_vm._l((_vm.paymentData),function(payment,index){return _c('span',{key:`payit_dialog_${index}`,staticClass:"h2-descricao"},[_vm._v(" "+_vm._s(`R$ ${parseFloat(payment.ammount).toFixed(2)} com ${(payment.nameFromCheckin || payment.name)}`)+" "),_c('br')])}),_c('br'),_c('div',[_c('span',{staticClass:"h2-descricao"},[_vm._v("Nova forma de pagamento")]),_c('br'),_c('el-form',{ref:"editPaymentDialofRef",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"60px","label-position":"right","inline":""}},_vm._l((_vm.form.newPayments),function(payment,index){return _c('div',{key:`payment.${payment.key}`},[_c('div',{staticClass:"new-payment-item"},[_c('el-form-item',{attrs:{"prop":`newPayments.${index}.name`,"rules":[
              { required: true, message: 'Obrigatorio', trigger: 'blur' }
            ]}},[_c('el-select',{attrs:{"placeholder":"Selecione a nova forma de pagamento"},on:{"change":function($event){return _vm.paymentChange(_vm.form.newPayments[index].name, index)}},model:{value:(_vm.form.newPayments[index].name),callback:function ($$v) {_vm.$set(_vm.form.newPayments[index], "name", $$v)},expression:"form.newPayments[index].name"}},_vm._l((_vm.enabledPayments),function(paymentDB){return _c('el-option',{key:paymentDB.code,attrs:{"label":paymentDB.name,"value":paymentDB.name}})}),1)],1),_c('el-form-item',{attrs:{"prop":`newPayments.${index}.ammount`,"rules":[
            {  min: 0.01, required: true, message: 'Obrigatorio', trigger: 'blur' }
          ]}},[_c('input-mask-number-field',{attrs:{"value":_vm.form.newPayments[index].ammount,"prefix":"","width":"110px","decimals":2},on:{"updated":function($event){return _vm.$refs['editPaymentDialofRef'].validateField(`newPayments.${index}.ammount`)},"update:value":function($event){return _vm.$set(_vm.form.newPayments[index], "ammount", $event)}}})],1)],1),(_vm.newPaymentHasNsu(_vm.form.newPayments[index].code))?_c('el-form-item',{ref:`npfi.${index}.nsu`,refInFor:true,staticClass:"new-payment-form-item",attrs:{"label":"NSU","prop":`newPayments.${index}.nsu`,"rules":[
            { required: _vm.newPaymentHasNsuForced(_vm.form.newPayments[index].code), message: 'Número NSU obrigatório', trigger: 'blur' },
            { min: 5, message: 'NSU inválida, mínimo de 5 digitos', trigger: 'blur' }
          ]}},[_c('el-input',{staticClass:"new-payment-nsu",attrs:{"placeholder":"Número do NSU","type":"number"},model:{value:(_vm.form.newPayments[index].nsu),callback:function ($$v) {_vm.$set(_vm.form.newPayments[index], "nsu", $$v)},expression:"form.newPayments[index].nsu"}})],1):_vm._e()],1)}),0),_c('br'),_c('div',{staticClass:"new-payment-buttons"},[_c('el-button',{attrs:{"plain":"","disabled":(_vm.form.newPayments.length) === 1,"type":"info","icon":"el-icon-remove"},on:{"click":function($event){return _vm.removePayment()}}},[_vm._v(" Remover ")]),_c('el-button',{attrs:{"plain":"","type":"primary","icon":"el-icon-circle-plus"},on:{"click":function($event){return _vm.addPayment()}}},[_vm._v(" Adicionar ")])],1),_c('div',{staticClass:"new-payment-buttons"},[_c('el-button',{attrs:{"type":"info","icon":"el-icon-error"},on:{"click":function($event){return _vm.onClose()}}},[_vm._v(" Cancelar ")]),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-success"},on:{"click":function($event){return _vm.savePayment()}}},[_vm._v(" Confirmar ")])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }