import Vue from 'vue'
import App from './App'
import router from './router'

import { store, initializeStore, populateStatesFromIndexDB } from './store'

import { sync } from 'vuex-router-sync'
import { fbapp } from '@/config/firebase'

import './registerServiceWorker'

// https://fontawesome.com/icons/
// Ver em cargo/index exemplos
import 'vue-awesome/icons/microphone'
import 'vue-awesome/icons/stop'
import 'vue-awesome/icons/play'
import 'vue-awesome/icons/pause'
import 'vue-awesome/icons/check'
import 'vue-awesome/icons/camera'
import 'vue-awesome/icons/wifi'
import 'vue-awesome/icons/truck-moving'
import 'vue-awesome/icons/exclamation-triangle'
import Icon from 'vue-awesome/components/Icon'

import element from './plugins/element.js'
import trimCleanConcat from '@/helpers/trimCleanConcatStrings.js'
import { uaReport, uaParserInstall } from '@/helpers/uaParser.js'
import logging from '@/services/logging.js'
// import notifyErrors from '@/services/notifyErrors'
import ImageUploader from 'vue-image-upload-resize'
import { installAxios } from '@/services/axiosHttp'

import moment from 'moment'
import VueMoment from 'vue-moment'
import typy from 'typy'

moment.locale('pt-br')

Vue.component('icon', Icon)

// Vue.use(VueLocalForage)
Vue.use(installAxios)
Vue.use(element.Element, { locale: element.locale })
Vue.use(VueMoment, moment)
Vue.use(trimCleanConcat)
Vue.use(uaParserInstall)
Vue.use(logging)
Vue.use(ImageUploader)

Vue.use({
  install (Vue, options) {
    // Instalação global no this pelo JS e componentes
    Object.defineProperties(Vue.prototype, {
      $$to: {
        get () {
          return (prop, path) => typy(prop, path).safeObject
        }
      },
      $$td: {
        get () {
          return (prop, path) => typy(prop, path).isDefined
        }
      }
    })

    // Instalação apenas nos componentes
    // Vue.prototype.$$to = (prop, path) => typy(prop, path).safeObject
    // Vue.prototype.$$td = (prop, path) => typy(prop, path).isDefined
  }
})

// Vuecli auto set NODE_ENV, read https://cli.vuejs.org/guide/mode-and-env.html#modes
Vue.config.productionTip = !(process.env.NODE_ENV === 'production')
Vue.config.devtools = !(process.env.NODE_ENV === 'production')
Vue.config.debug = !(process.env.NODE_ENV === 'production')
Vue.config.silent = (process.env.NODE_ENV === 'production')

// ERROR HANDLER
// Vue possui um hook para erros próprios que cobrem praticamente todos os erros não esperados do Vue
// window.onerror é nativo do javascript, mas nesse caso só funciona fora do app vue
// window.onerror = function (message, source, line, column, error) {
//   console.error('ONE ERROR HANDLER TO RULE THEM ALL:', message)
// }
// Funcoes recebe (err, vm, info) objeto de erro, vm/instˆncia do app, info de texto qualquer

// Bloco abaixo desativado porque no Safari não funciona
// O código regex da função falha com error "invalid group specifier name"
// Também comentado dentro do arquivo notifyErrors.js
// if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
//   Vue.config.errorHandler = notifyErrors.error
//   Vue.config.warnHandler = notifyErrors.warn
// }

/* eslint-disable no-new */
let app = null

async function initializeApp () {
  await populateStatesFromIndexDB()
  await initializeStore()
  await sync(store, router)

  // Save to state the device Info
  store.commit('Shared/setDeviceInfo', uaReport, { root: true })

  // Wait firebase auth be fully initialized and start a observer fired every time auth changed
  // fbapp.auth().currentUser will be null when auth is not fully initialized and some router validation fails
  fbapp.auth().onAuthStateChanged(user => {
    if (!app) {
      app = new Vue({
        router,
        store,
        render: h => h(App)
      }).$mount('#app')
    }
  })
}

initializeApp()
