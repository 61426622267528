function firebaseDoc (appendString = '') {
  const now = new Date()
  if (appendString) appendString = `_${clearText(appendString)}`
  return `${now.toISOString().substr(0, 19).replace(/-|:|T/g, '')}${appendString}_${Math.random().toString(36).substring(2)}`
}

function clearText (text) {
  return (text) ? text.toString().replace(/[/\\@#!$%ˆ&*()]/g, '') : 'unk'
}

export default {
  firebaseDoc
}
