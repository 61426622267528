
<!--
  EXEMPLO DE USO

  import InputMaskNumberField from '@/components/Shared/InputMaskNumberField'

  export default {
    components: {
      InputMaskNumberField
    }
  }

  <template>
    <input-mask-number-field
      @updated="$refs['formRef'].validateField('fieldProp')"  // Obrigatório quando se deseja validar o campo com o validade do element
      :value.sync ="form.value"                               // propriedade no qual o valor deve ser retornado
      showButtons                                             // se deve exibir os botões de + e -
      showPlusButtom                                          // se deve exibir o botão de +
      showMinusButtom                                         // se deve exibir o botão de -
      prefix="R$"                                             // Texto prefixo do campo
      decimals=1                                              // Quantidade de casas decimais
      >
    </input-mask-number-field>
  </template>
-->

<template>
  <div class="input-mask-number-field-div1" :style="`width:${width};`">
    <button v-if="this.showButtons || this.showMinusButtom" class="input-mask-number-field-buttom input-mask-number-field-buttom-minus" @click.prevent="minus">-</button>
    <div class="input-mask-number-field-div2" :class="{ 'input-mask-number-field-div-focus': isFocus }">
      <span class="input-mask-number-field-prefix" v-if="prefix">{{ prefix }}</span>
      <span class="input-mask-number-field-prefix-empty" v-else />
      <input type="text"
        class="input-mask-number-field"
        inputmode="numeric"
        v-money="customMask"
        :value="normalizedValue"
        @focus="onFocus"
        @blur="onBlur"
        @change="onChange($event.target.value)"
        :min=0.00
        :step="1"
        novalidate />
    </div>
    <button v-if="this.showButtons || this.showPlusButtom" class="input-mask-number-field-buttom input-mask-number-field-buttom-plus" @click.prevent="add">+</button>
  </div>
</template>

<script>
import { VMoney } from 'v-money'

export default {
  name: 'input-mask-number-field',
  directives: {
    money: VMoney
  },
  data () {
    return {
      isFocus: false,
      customMask: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: this.decimals,
        masked: false /* doesn't work with directive */
      }
    }
  },
  props: {
    value: {},
    prefix: {
      type: String,
      default: ''
    },
    decimals: {
      type: Number,
      default: 2
    },
    showButtons: {
      type: Boolean,
      default: false
    },
    showPlusButtom: {
      type: Boolean,
      default: false
    },
    showMinusButtom: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      required: false,
      default: '100%'
    }
  },
  computed: {
    normalizedValue () {
      return parseFloat(this.value).toFixed(this.decimals)
    }
  },
  methods: {
    add () {
      let value = parseFloat(this.value)
      value = value + 1.00
      this.$emit('update:value', value)
    },
    minus () {
      let value = parseFloat(this.value)

      if (value > 1.00) value = value - 1.00
      else if (value <= 1.00 && value > 0.10) value = value - 0.10
      else if (value <= 0.10 && value > 0.1) value = value - 0.01
      else value = 0.00

      this.$emit('update:value', value)
    },
    prepareDecimals (value) {
      if (typeof value === 'number') {
        return value.toFixed(this.decimals)
      } else if (typeof value === 'string') {
        return parseFloat(value.replace(/\./g, '').replace(/,/g, '.')).toFixed(this.decimals)
      } else {
        return parseFloat(value.toString().replace(/./g, '').replace(/,/g, '.')).toFixed(this.decimals)
      }
    },
    onChange (value) {
      value = this.prepareDecimals(value)
      this.$emit('update:value', value)
    },
    onFocus () {
      this.isFocus = !this.isFocus
    },
    onBlur () {
      this.isFocus = !this.isFocus
      this.$emit('updated')
    }
  }
}
</script>

<style scoped>
  .input-mask-number-field-div1{
    display: flex;
  }
  .input-mask-number-field-div2 {
    display: flex;
    justify-content: flex-start;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border 0.20s;
    width: 100%;
  }
  .input-mask-number-field-div-focus {
    border: 1px solid #409EFF !important;
  }
  .is-error .input-mask-number-field-div2 {
    border: 1px solid #f56c6c;
  }
  .is-success .input-mask-number-field-div2 {
    border: 1px solid #67c23a;
  }
  .input-mask-number-field {
    font-size: 18px;
    padding: 0;
    border: 0;
    width: 100%;
    color: #666;
    font-weight: bold;
    line-height: 2.2;
  }
  .input-mask-number-field:focus {
    outline: 0;
  }
  .input-mask-number-field-prefix-empty {
    line-height: 2.2;
    padding: 0px 5px 0px 5px;
  }
  .input-mask-number-field-prefix {
    font-size: 18px;
    padding: 0px 10px 0px 15px;
    color: #999;
    background: white;
    line-height: 2.2;
  }
  .input-mask-number-field-buttom {
    background-color: #409EFF;
    color: white;
    padding: 0 18px;
    font-size: 20px;
    text-align: center;
    text-decoration: none;
    margin: 0;
    border: 0;
    border-radius: 4px;
    background-position: center;
    transition: background 0.6s;
  }
  .input-mask-number-field-buttom:focus {
    outline: 0;
  }
  .input-mask-number-field-buttom:hover {
    background: #1879d3 radial-gradient(circle, transparent 1%, #1879d3 1%) center/15000%;
  }
  .input-mask-number-field-buttom:active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
  }
  .input-mask-number-field-buttom-minus{
    margin-right: 3px;
  }
  .input-mask-number-field-buttom-plus {
    margin-left: 3px;
  }
</style>
