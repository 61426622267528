<template>
  <div>
    <h1>Entrega Parcial NFe: <span class="txt-azul"> {{invoicePartialCancellation}} </span></h1>

    <el-form ref="invoicePartialForm" :model="form" :rules="rules" label-position="top">

      <el-form-item label="Qual item deseja incluir?" prop="itemCancellation" >
        <el-select v-model="form.itemCancellation" placeholder="Selecione o produto" @change="setItemQuantitys">
          <el-option
            v-for="item in deliverPartialCancellationItems"
            :key="item.product"
            :label="item.product"
            :value="item.product">
            <span style="float: left; font-size: 11px">{{ item.product }}</span>
            <span style="float: right; color: #8492a6; font-size: 9px">{{ item.quantity }} {{ item.um }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Qual a quantidade?" prop="quantityCancellation" class=quantityLine>
        <el-button type="warning"  icon="el-icon-remove-outline" @click="setCancellationToZero" size="small" :disabled="itemNotSelected"></el-button>
        <el-input-number v-model.number="form.quantityCancellation" :min="0" :max="quantityMaxCancellation" :step="quantityStepCancellation" size="small" :disabled="itemNotSelected"></el-input-number>
        <el-button type="warning"  icon="el-icon-circle-plus-outline" @click="setCancellationToMax" size="small" :disabled="itemNotSelected"></el-button>
      </el-form-item>

      <el-form-item label="Qual o motivo?" prop="cancellationOption">
        <el-select v-model="form.cancellationOption" placeholder="Selecione o motivo" :disabled="itemNotSelected" @change="needPhoto">
          <el-option
            v-for="item in cancellationReasonsPartial"
            :key="item.id"
            :label="item.description"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="form.fotoNeeded" label="Adicione uma foto do produto" prop="foto">
        <foto-upload-button
          @onImage="form.foto = $event"
          outputFormat="base64" />
      </el-form-item>

      <div class="actions-buttons actions-buttons-fullWidth">
        <el-button type="success" @click="addCancellation">
          {{ itemSelectedNotInList ? 'Confirmar parcial do produto' : 'Atualizar produto na lista'}}
        </el-button>
        <el-button type="info" @click="$router.go(-1)">Cancelar</el-button>
        <!-- <el-button type="info" @click="$router.push(`/delivery/invoice-partial-cancellation/${this.$route.params.invoice}`)">Cancelar</el-button> -->
      </div>
    </el-form>

  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import FotoUploadButton from '@/components/Shared/FotoUploadButton'

export default {
  name: 'invocie-partial-cancellation',
  components: {
    FotoUploadButton
  },
  data () {
    return {
      form: {
        itemCancellation: '',
        cancellationOption: '',
        foto: null,
        fotoNeeded: false,
        quantityCancellation: 0
      },
      rules: {
        itemCancellation: [
          { required: true, message: 'Selecionar o item é obrigatório' }
        ],
        cancellationOption: [
          { required: true, message: 'Motivo do cancelamento é obrigatório' }
        ],
        quantityCancellation: [
          { required: true, type: 'number', min: 1, message: 'Quantidade não pode ser zero' }
        ],
        foto: [
          { required: true, message: 'Foto do produto obrigatório' }
        ]
      },
      itemsCancellation: [],
      quantityMaxCancellation: 0,
      quantityStepCancellation: 1
    }
  },
  computed: {
    ...mapState('Cargos', ['deliverCustomer']),
    ...mapGetters('Cargos', ['deliverPartialCancellation']),
    ...mapGetters('User', ['cancellationReasonsPartial']),
    invoicePartialCancellation () {
      return this.$route.params.invoice.replace('xbarx', '/')
    },
    deliverPartialCancellationItems () {
      return Object.values((this.deliverPartialCancellation(this.invoicePartialCancellation)).items)
    },
    itemNotSelected () {
      return Boolean(!this.form.itemCancellation)
    },
    itemSelectedNotInList () {
      return !this.itemsCancellation.some(item => (item.product === this.form.itemCancellation))
    }
  },
  methods: {
    needPhoto (selectedReasonId) {
      const selectedReason = this.cancellationReasonsPartial.find(reason => reason.id === selectedReasonId)
      this.form.fotoNeeded = selectedReason.photo || false
      this.form.foto = null
    },
    setItemQuantitys (value) {
      // quantidade retorna para o valor padrão
      this.form.quantityCancellation = 0

      // capturo o produto selecionado
      const product = this.deliverPartialCancellationItems
        .find(item => item.product === value)

      // configuro a quantidade máxima
      this.quantityMaxCancellation = product.quantity || 0

      // configuro o step de quantidades
      this.quantityStepCancellation = product.step || 1
    },
    setCancellationToMax () {
      this.form.quantityCancellation = this.quantityMaxCancellation
    },
    setCancellationToZero () {
      this.form.quantityCancellation = 0
    },

    addCancellation () {
      // Validação do formuário
      this.$refs['invoicePartialForm'].validate((valid) => {
        if (valid) {
          console.log(valid)
          // Se válido, adiciono itens na tabela de cancelamento
          // let deliverCustomer = Object.assign({}, this.deliverCustomer)
          let deliverCustomer = JSON.parse(JSON.stringify(this.deliverCustomer))
          deliverCustomer.has_invoice_partial = true

          // find invoice in deliverCustomer state
          Object.values(deliverCustomer.invoices).some(invoice => {
            if (invoice.invoice === this.invoicePartialCancellation) {
              invoice.status = 'with_partial'
              invoice.partial_at = { seconds: Math.round((new Date()).getTime() / 1000) }

              // find item in deliverCustomer.invoices
              Object.values(invoice.items).some(item => {
                if (item.product === this.form.itemCancellation) {
                  item.partial = true

                  let cancellationreason = this.cancellationReasonsPartial.find(e => e.id === this.form.cancellationOption)

                  item.partial_quantity = this.form.quantityCancellation
                  item.partial_reason = this.form.cancellationOption
                  item.partial_desc = cancellationreason.description
                  item.partial_photorequired = cancellationreason.photo
                  item.partial_photo = this.form.foto

                  // Array build for datatable, remove if already added
                  let indexOfItemsCancellations = this.itemsCancellation.indexOf(item)
                  if (indexOfItemsCancellations > -1) this.itemsCancellation.splice(indexOfItemsCancellations, 1)
                  this.itemsCancellation.push(item)
                }
              })
            }
          })
          // Update state with new partial infos
          this.setDeliverCustomer(deliverCustomer)
          // this.$router.go(-1)
          this.$router.push(`/delivery/invoice-partial-cancellation/${this.$route.params.invoice}`)
        } else {
          this.setError(`Erros no formulário`)
        }
      })
    },
    ...mapActions('Cargos', ['setDeliverCustomer']),
    ...mapMutations('Shared', ['setError'])
  },
  mounted () {
    // Preenche datatable com dados pré cadastrados
    this.itemsCancellation = this.deliverPartialCancellationItems.filter(item => {
      return item.partial === true
    })
  }
}
</script>

<style scoped>
.el-input-number {
  margin-left: 1%;
  margin-right: 1%;
  width: 50%
}

.quantityLine >>> div {
  text-align: center;
}

.txt-azul {
  color:#00AFEF;
  font-size: 15px;
}

.txt-label {
  color: #606266;
  font-size: 14px;
  padding-bottom: 15px;
  padding-top: 15px;
}

</style>
