import { db } from '@/config/firebase'

export default {
  setOnlineState ({ commit }) {
    if (db.appInitialized) {
      if (!navigator.onLine) {
        db.disableNetwork()
      } else {
        db.enableNetwork()
      }
    }
    commit('setOnlineState')
  },

  getAppVersions ({ commit }) {
    return db
      .collection('settings')
      .doc('versions')
      .get()
      .then(doc => {
        commit('setAppVersions', doc.data())
        return doc.data()
      })
  }
}
