<template>
  <div v-if="show" class="progress-message">
    Enviando {{ uploadingStatus.filesBeingUploaded }} {{ textFile }} / {{ uploadingStatus.overallProgress }}% concluído
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'file-upload-messages-simple',
  props: {
    uploadingStatus: {
      type: Object,
      required: true
    },
    timeout: {
      type: Number,
      required: false,
      default: 3000
    }
  },
  computed: {
    show () {
      return (this.uploadingStatus.filesBeingUploaded > 0)
    },
    is_success () {
      return (this.uploadingStatus.overallStatus === 'completed')
    },
    is_failed () {
      return (this.uploadingStatus.overallStatus === 'failed')
    },
    status () {
      if (this.is_success) return 'success'
      else if (this.is_failed) return 'exception'
      else return null
    },
    textFile () {
      if (this.uploadingStatus.filesBeingUploaded > 1) return 'fotos'
      else return 'foto'
    }
  },
  data () {
    return {
      textMessage: ''
    }
  },
  methods: {
    ...mapMutations('Shared', ['clearUploadingMessage']),
    setDefaultUploadingStatusTimeOut (multiplicador = 1) {
      setTimeout(() => {
        this.clearUploadingMessage()
      }, (this.timeout * multiplicador))
    }
  },
  watch: {
    status (value) {
      if (value === 'success') {
        this.textMessage = 'Arquivos enviados com sucesso!'
        this.setDefaultUploadingStatusTimeOut()
      } else if (value === 'failed') {
        this.textMessage = 'Falha ao enviar arquivos!'
        this.setDefaultUploadingStatusTimeOut(2)
      }
    }
  }
}
</script>
<style scoped>
  .progress-message {
    position: absolute;
    font-size: 10px;
    background: transparent;
  }
</style>
