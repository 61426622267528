<template>
  <invoice-details :invoice="invoice" showDataGrid showTitle/>
</template>

<script>
import { mapState } from 'vuex'
import InvoiceDetails from '@/components/Cargo/Complements/InvoiceDetails'

export default {
  name: 'items-invoice',
  components: {
    InvoiceDetails
  },
  computed: {
    ...mapState('Cargos', ['deliverCustomer']),
    invoiceId () {
      return this.$route.params.invoice.replace('xbarx', '/')
    }
  },
  data () {
    return {
      invoice: {}
    }
  },
  created () {
    const invoiceIndex = Object.values(this.deliverCustomer.invoices)
      .findIndex(invoice => invoice.invoice === this.invoiceId || invoice.order_number === this.invoiceId)
    this.invoice = this.deliverCustomer.invoices[invoiceIndex]
  }
}
</script>

<style scoped>
  .txt-nfe {
    font-size:12px;
    color: black;
    font-weight: 500;
  }
  .dados-nota {
    font-size: 12px;
    text-align: center;
    font-weight: bold;
  }
 </style>
