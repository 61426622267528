<template>
    <div>
      <h1>Página não encontrada</h1>
    </div>
</template>

<script>
export default {
  name: 'NotFound404',
  data () {
    return {}
  }
}
</script>
