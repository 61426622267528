<!--
  EXEMPLO DE USO

  import FotoUploadButton from '@/components/Shared/FotoUploadButton'

  export default {
    components: {
      FotoUploadButton
    }
  }

  <template>
    <foto-upload-button
      @hasImage="hasImage = $event"             // Evento gerado para atribuir o valor da variavel hasImage no pai

      @blobImage="form.fotoBlob = $event"       // Evento gerado para atribuir o conteúdo da foto no pai, mantido apenas por compatibilidade quando somente blob
      @onImage="form.fotoBlob = $event"      // Evento gerado para atribuir o conteúdo da foto no pai, blob ou base64

      :showImagePreview="true"                  // Opcional, Se deve exibir preview da imagem, deafult false
      :rotateEnabled="true"                     // Opcional, Se deve ativar a diretiva rotate, default false
      :rotateIncrement="90"                     // Opcional, Se diretiva rotate ativado, quanto deve ser rotacionado, default 90º
      beforeText="Foto do odômetro"             // Opcional, Texto no botão antes da foto enviada, default Adicionar foto
      afterText="Foto do odômetro enviada" >    // Opcional, Texto no botão após foto enviada, default Atualizar foto
      :setImageFn="funcaoPersonalizada">        // Opcional, Caso queira executar uma função personalizada ao definir imagem
      :inputId="id_para_input"                  // Opcional, Caso queira definir id próprio para o input
      :outputFormat="blob"                      // Opcional, Caso queira definir o output format para base64
      :nullText="true"                          // Opcional, quando ativo remove texto do bottão, padrão é false
      bt-style="warning"                        // Opciona, Altera a cor do estilo do botão: opções warning (amarelo bege) danger (vermelho), padrão botão cor azul
      @removeButton="removeFn(image)"           // Opcional, Se informado o listener adiciona botão remover, deve ser passada com uma função callback
      @cancelButton="cancelFn(image)"           // Opcional, Se informado o listener adiciona botão cancelar, deve ser passada com uma função callback
      @editButton="editFn(image)"               // Opcional, Se informado o listener adiciona botão editar, deve ser passada com uma função callback
      @trashButton="trashFn(image)"             // Opcional, Se informado o listener adiciona botão lixeira, deve ser passada com uma função callback
    </foto-upload-button>

      capture can be: camera, user or environment
      <input id="event-pay-fuel-comp-img" type="file" accept="image/*" capture="environment" class="input-file-hide">
  </template>
-->

<template>
  <div class="input-file-container" >
    <image-uploader
      :id="inputId"
      :maxWidth="1512"
      :quality="0.9"
      :autoRotate=true
      capture="environment"
      :className="['input-file-hide']"
      :outputFormat="outputFormat"
      :preview=showImagePreview
      @input="setImage"
      @onUpload="setUploadAsRunning"
      @onComplete="setUploadAsStopped"
      v-imageRotate="{ enabled: rotateEnabled, incrementL: rotateIncrement}">
    </image-uploader>

    <el-row :gutter="3">
      <el-col :span="firstButtonSize">
        <label :id="`${inputId}_label`" :for="inputId" class="input-file-trigger" :class="btStyle" @click="setUploadAsRunningClick">
          <div v-if="uploading">
            <i class="el-icon-loading"></i>
          </div>
          <div v-else>
            <icon name="camera"></icon>
            {{ computedHasImage ? toUseAfterText : toUseBeforeText }}
            <icon v-show="computedHasImage" name="check"></icon>
          </div>
        </label>
      </el-col>

      <el-col v-if="showRemoveButton" :span="extraButtonsSize">
        <el-button v-if="showRemoveButton"
          @click.prevent="removeButtonFn"
          icon="el-icon-remove"
          type="warning">
        </el-button>
      </el-col>

      <el-col v-if="showEditButton" :span="extraButtonsSize">
        <el-button v-if="showEditButton"
          @click.prevent="editButtonFn"
          icon="el-icon-edit-outline"
          type="warning">
        </el-button>
      </el-col>

      <el-col v-if="showCancelButton" :span="extraButtonsSize">
        <el-button v-if="showCancelButton"
          @click.prevent="cancelButtonFn"
          icon="el-icon-circle-close"
          type="danger">
        </el-button>
      </el-col>

      <el-col v-if="showTrashButton" :span="extraButtonsSize">
        <el-button v-if="showTrashButton"
          @click.prevent="trashButtonFn"
          icon="el-icon-delete"
          type="danger">
        </el-button>
      </el-col>

    </el-row>
  </div>
</template>

<script>
import imageRotate from '@/directives/ImageRotate'
// import ImageUploader from 'vue-image-upload-resize'

export default {
  name: 'foto-upload-button',
  components: {
    // ImageUploader
  },
  data () {
    return {
      intHasImage: false,
      dataImage: false,
      uploading: false,
      uploadingConfirmed: false,
      extraButtonsSize: 5
    }
  },
  props: {
    inputId: {
      type: String,
      default: `input-file-${Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36)}`
    },
    hasImage: {
      type: Boolean,
      default: false
    },
    showImagePreview: {
      type: Boolean,
      default: false
    },
    rotateEnabled: {
      type: Boolean,
      default: false
    },
    rotateIncrement: {
      type: Number,
      default: 90
    },
    nullText: {
      type: Boolean,
      default: false
    },
    btStyle: {
      type: String,
      default: `bt-defaultStyle`
    },
    beforeText: {
      type: String,
      default: `Adicionar foto`
    },
    afterText: {
      type: String,
      default: `Atualizar foto`
    },
    setImageFn: {
      type: Function,
      default: () => {}
    },
    outputFormat: {
      type: String,
      default: 'blob'
    }
  },
  directives: {
    'imageRotate': imageRotate
  },
  computed: {
    computedHasImage () {
      return (this.intHasImage || this.hasImage)
    },
    showRemoveButton () {
      return (this.$listeners && this.$listeners.removeButton)
    },
    showEditButton () {
      return (this.$listeners && this.$listeners.editButton)
    },
    showCancelButton () {
      return (this.$listeners && this.$listeners.cancelButton)
    },
    showTrashButton () {
      return (this.$listeners && this.$listeners.trashButton)
    },
    toUseBeforeText () {
      return (this.nullText) ? '' : this.beforeText
    },
    toUseAfterText () {
      return (this.nullText) ? '' : this.beforeText
    },
    firstButtonSize () {
      let fullSize = 24
      if (this.showRemoveButton) fullSize -= this.extraButtonsSize
      if (this.showEditButton) fullSize -= this.extraButtonsSize
      if (this.showCancelButton) fullSize -= this.extraButtonsSize
      if (this.showTrashButton) fullSize -= this.extraButtonsSize
      return fullSize
    }
  },
  methods: {
    setImage: function (file) {
      this.intHasImage = true
      this.dataImage = file
      this.$emit('hasImage', true)
      this.$emit('onImage', this.dataImage)
      if (this.outputFormat === 'blob') this.$emit('blobImage', this.dataImage)
      this.setImageFn(file)
    },
    removeButtonFn: function () {
      this.$emit('removeButton')
    },
    editButtonFn: function () {
      this.$emit('editButton')
    },
    cancelButtonFn: function () {
      this.$emit('cancelButton')
    },
    trashButtonFn: function () {
      this.$emit('trashButton')
    },
    setUploadAsRunning: function () {
      this.$set(this, 'uploading', true) // same as this.uploading = true, but force reative
      this.uploadingConfirmed = true
    },
    setUploadAsStopped: function () {
      this.$set(this, 'uploading', false) // force reative
      this.uploadingConfirmed = false
    },
    setUploadAsRunningClick: function () {
      // When upload/processing a camera foto to memory, DOM not rendering and loading not show
      // This and this.uploadingConfirmed  is a hack to show loading during uploading foto to memory
      this.$set(this, 'uploading', true)
      setTimeout(() => {
        if (!this.uploadingConfirmed) this.$set(this, 'uploading', false)
      }, 2500)
    }
  }
}
</script>

<style scoped>
  .input-file-container {
    position: relative;
    width: 100%;
    text-align:center;
  }

  .input-file-trigger {
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    display: block;
    padding: 0px 0px;
    line-height: 40px;
    color: #fff;
    font-size: 1em;
    transition: all .4s;
    cursor: pointer;
    text-align:center;
    border-radius: 5px;
  }

  .input-file {
    position: absolute;
    top: 0; left: 0;
    width: 225px;
    opacity: 0;
    padding: 14px 0;
    cursor: pointer;
  }

  .input-file-container >>> .input-file-hide {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .input-file-container >>> img {
    border-radius: 5%;
    width: 50%;
  }

  .bt-defaultStyle {
    background-color: #409EFF;
  }

  .warning {
    background-color: #e6a23c;
  }

  .danger {
    background-color: #f56c6c;
  }
</style>
