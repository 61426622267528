export default {
  firebase: {
    apiKey: process.env.VUE_APP_APIKEY || 'unknow',
    authDomain: process.env.VUE_APP_AUTHDOMAIN || 'unknow',
    databaseUrl: process.env.VUE_APP_DATABASEURL || 'unknow',
    projectId: process.env.VUE_APP_PROJECTID || 'unknow',
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID || 'unknow',
    storageBucket: process.env.VUE_APP_STORAGEBUCKET || 'unknow',
    workBucket: process.env.VUE_APP_WORKBUCKET || 'unknow',
    api_url: process.env.VUE_APP_API_URL || undefined // URL base para uso pelo axios e functions do firebase
  },
  build: {
    version: process.env.VUE_APP_VERSION || '0.0.0',
    buildDate: process.env.VUE_APP_BUILDDATE || '00.00.00x',
    nodeEnv: process.env.VUE_APP_NODE_ENV || process.env.NODE_ENV || 'unknow'
  },
  config: {
    title: process.env.VUE_APP_TITLE || 'no title',
    wifiInterval: (process.env.VUE_APP_WIFI_CHECK_INTERVAL || 10) * 1000,
    wifiBypassInterval: (process.env.VUE_APP_WIFI_CHECK_BYPASS || 60) * 1000,
    index_db: process.env.VUE_APP_INDEXDB_NAME || 'APP_DB', // Nome do Index DB local
    errorNotify: process.env.VUE_APP_ERROR_NOTIFY || 'false' // Força envio da notificação quando ambiente dev
  }
}
