import Vue from 'vue'
import Router from 'vue-router'
import t from 'typy'
import { fbapp } from '@/config/firebase'
import { store } from '@/store'

import validates from '@/router/validateCargoState'

import Home from '@/components/Home'
import Login from '@/components/Login'
import Logout from '@/components/Logout'
import Install from '@/components/General/Install'
import Reload from '@/components/General/Reload'
import About from '@/components/General/About'
import ImagesQueue from '@/components/General/ImagesQueue'
import StatusConexao from '@/components/General/StatusConexao'
import NotFound404 from '@/components/General/NotFound404'

import Cargo from '@/components/Cargo/index.vue'
import CargoClose from '@/components/Cargo/CloseCargo.vue'
import Deliver from '@/components/Cargo/Deliver.vue'
import Delivery from '@/components/Cargo/Delivery.vue'
import CustomerCancellation from '@/components/Cargo/CustomerCancellation.vue'
import InvoicePartialCancellation from '@/components/Cargo/InvoicePartialCancellation.vue'
import InvoicePartialCancellationAdd from '@/components/Cargo/InvoicePartialCancellationAdd.vue'
import InvoiceTotalCancellation from '@/components/Cargo/InvoiceTotalCancellation.vue'
import DeliverInvoice from '@/components/Cargo/InvoiceItems.vue'
import DeliverWaze from '@/components/Cargo/DeliverWaze.vue'

import EventPayFuel from '@/components/Events/EventPayFuel.vue'
import EventPayTax from '@/components/Events/EventPayTax.vue'
import EventGeneral from '@/components/Events/EventGeneral.vue'

import openStore from '@/services/localForageService'
const storeParams = openStore('params')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: '/about',
      name: 'About',
      component: About,
      meta: { requiresAuth: false }
    },
    {
      path: '/queue',
      name: 'ImagesQueue',
      component: ImagesQueue,
      meta: { requiresAuth: false }
    },
    {
      path: '/login/:comp?',
      name: 'Login',
      component: Login,
      meta: { requiresAuth: false }
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout,
      meta: { requiresAuth: false }
    },
    {
      path: '/status/conexao',
      name: 'StatusConexao',
      component: StatusConexao,
      meta: { requiresAuth: false }
    },
    {
      path: '/install',
      name: 'Install',
      component: Install,
      meta: { requiresAuth: false }
    },
    {
      path: '/reload',
      name: 'Reload',
      component: Reload,
      meta: { requiresAuth: false }
    },
    {
      path: '/cargo',
      name: 'Cargo',
      component: Cargo,
      meta: { requiresAuth: true, onErrorValidateRouteTo: '/' },
      beforeEnter: validates.multiple([validates.cargoIsSet, validates.truckIsSelected])
    },
    {
      path: '/cargo/close',
      name: 'CargoClose',
      component: CargoClose,
      meta: { requiresAuth: true, onErrorValidateRouteTo: '/' },
      beforeEnter: validates.truckIsSelected
    },
    {
      path: '/deliver',
      name: 'Deliver',
      component: Deliver,
      meta: { requiresAuth: true },
      beforeEnter: validates.deliverCustomerIsSet
    },
    {
      path: '/deliver/invoice/:invoice',
      name: 'DeliverInvoice',
      component: DeliverInvoice,
      meta: { requiresAuth: true },
      beforeEnter: validates.deliverCustomerIsSet
    },
    {
      path: '/deliver/waze',
      name: 'DeliverWaze',
      component: DeliverWaze,
      meta: { requiresAuth: true },
      beforeEnter: validates.deliverCustomerIsSet
    },
    {
      path: '/delivery',
      name: 'Delivery',
      component: Delivery,
      meta: { requiresAuth: true, onErrorValidateRouteTo: '/cargo' },
      beforeEnter: validates.multiple([validates.deliverCustomerIsSet, validates.statusIsNotFinished])
    },
    {
      path: '/customer-cancellation',
      name: 'CustomerCancellation',
      component: CustomerCancellation,
      meta: { requiresAuth: true, onErrorValidateRouteTo: '/cargo' },
      beforeEnter: validates.multiple([validates.deliverCustomerIsSet, validates.statusIsNotFinished])
    },
    {
      path: '/delivery/invoice-total-cancellation/:invoice',
      name: 'InvoiceTotalCancellation',
      component: InvoiceTotalCancellation,
      meta: { requiresAuth: true, onErrorValidateRouteTo: '/delivery' },
      beforeEnter: validates.multiple([validates.deliverCustomerIsSet, validates.statusIsNotFinished])
    },
    {
      path: '/delivery/invoice-partial-cancellation/:invoice',
      name: 'InvoicePartialCancellation',
      component: InvoicePartialCancellation,
      meta: { requiresAuth: true, onErrorValidateRouteTo: '/delivery' },
      beforeEnter: validates.multiple([validates.deliverCustomerIsSet, validates.statusIsNotFinished])
    },
    {
      path: '/delivery/invoice-partial-cancellation/:invoice/add',
      name: 'InvoicePartialCancellationAdd',
      component: InvoicePartialCancellationAdd,
      meta: { requiresAuth: true, onErrorValidateRouteTo: '/delivery' },
      beforeEnter: validates.multiple([validates.deliverCustomerIsSet, validates.statusIsNotFinished])
    },
    {
      path: '/events/payfuel',
      name: 'EventPayFuel',
      component: EventPayFuel,
      meta: { requiresAuth: true },
      beforeEnter: validates.truckIsSelected
    },
    {
      path: '/events/paytax',
      name: 'EventPayTax',
      component: EventPayTax,
      meta: { requiresAuth: true },
      beforeEnter: validates.truckIsSelected
    },
    {
      path: '/events/general',
      name: 'EventGeneral',
      component: EventGeneral,
      meta: { requiresAuth: true },
      beforeEnter: validates.truckIsSelected
    },
    {
      path: '*',
      component: NotFound404
    }
  ]
})

// Função para recarregar os dados do usuários e empresa quando navegador é encerrado e os dados do state perdidos
function reloadProfiles (user) {
  return new Promise((resolve, reject) => {
    store.commit('Shared/setLoadingMessage', 'Carregando...', { root: true })
    storeParams.getItem('companieDnsName')
      .then(companiename => store.dispatch('User/getCompanieByDnsName', { dnsname: companiename, bypassRemoveMsg: true, bypassAddMsg: true }))
      .then(companie => store.dispatch('User/getUserProfile', user))
      .then(profile => {
        store.commit('Shared/setLoadingMessage', '', { root: true })
        resolve(profile)
      })
  })
}

// Auth
router.beforeEach((to, from, next) => {
  // Verifica se rota necessita de autenticação
  if (to.matched.some(rec => rec.meta.requiresAuth)) {
    // Verifica se profile do usuário esta carregado no state e na lib do firebase, se sim... continua
    // Veja fbapp.auth().onAuthStateChanged carregado no main.js para garantir a inicialização do auth antes do app
    if (t(store, 'state.User.profile.login').isDefined && fbapp.auth().currentUser) {
      next()
    // Se não, verifica se apenas o auth().currentUser esta carregado, se sim, recarrega o profile do usuário do firestore
    } else if (fbapp.auth().currentUser) {
      reloadProfiles(fbapp.auth().currentUser).then(profile => next({ name: 'Home' }))
    } else {
      // Force logout and route to login page
      store.dispatch('User/userLogout', { bypassRouter: false, bypassLog: true })
    }
  } else {
    next()
  }
})

// Update location
router.beforeEach((to, from, next) => {
  store.dispatch('Trucks/saveTruckLocation')
  next()
})

// Back buttom
router.beforeEach((to, from, next) => {
  const user = t(store, 'state.User.profile.login').isDefined
  if (user && from.name === 'Home' && to.name === 'Login') {
    router.forward()
  } else if (user && to.name === 'Login') {
    next({ name: 'Home' })
  } else if (user && from.name === 'Cargo' && to.name === 'Home' && !to.query.bypass) {
    router.forward()
  } else if (user && from.name === 'Delivery' && to.name === 'Deliver' && !to.query.bypass) {
    store.commit('Shared/setInfoMessage', 'Finalize a entrega antes de voltar', { root: true })
    router.forward()
  } else if (user && from.name === 'Deliver' && to.name === 'Cargo' && store.state.Cargos.deliverCustomer.status === 'delivering' && !to.query.bypass) {
    store.commit('Shared/setInfoMessage', 'Finalize a entrega antes de voltar', { root: true })
    router.forward()
  } else {
    next()
  }
})

export default router
