<template>
  <div>
    <h2>Sessão</h2>
    <el-table :data="tableData3" size="mini" :show-header=false :class="'about-tables'">
      <el-table-column prop="field" label="Conf" width="100" align="right"></el-table-column>
      <el-table-column prop="value" label="Valor"> </el-table-column>
    </el-table>

    <h2>Dispositivo</h2>
    <el-table :data="tableData5" size="mini" :show-header=false :class="'about-tables'">
      <el-table-column prop="field" label="Conf" width="100" align="right"></el-table-column>
      <el-table-column prop="value" label="Valor"> </el-table-column>
    </el-table>

    <h2 @click="goToStatus">Status</h2>
    <el-table :data="tableData2" size="mini" :show-header=false :class="'about-tables'">
      <el-table-column prop="field" label="Conf" width="100" align="right"></el-table-column>
      <el-table-column prop="value" label="Valor"> </el-table-column>
    </el-table>

    <h2>Build</h2>
    <el-table :data="tableData1" size="mini" :show-header=false :class="'about-tables'">
      <el-table-column prop="field" label="Conf" width="100" align="right"></el-table-column>
      <el-table-column prop="value" label="Valor"> </el-table-column>
    </el-table>

    <h2>Conexão</h2>
    <el-table :data="tableData4" size="mini" :show-header=false :class="'about-tables'">
      <el-table-column prop="field" label="Conf" width="100" align="right"></el-table-column>
      <el-table-column prop="value" label="Valor"> </el-table-column>
    </el-table>

    <div class="describe">
      <div class="describe-title">Minha Rota App</div>
      <div class="describe-body">
        www.minharota.com.br
        <br>
        contato@minharota.com.br
      </div>
    </div>

    <el-button @click="buttonclick1('Teste 1')">1</el-button>
    <el-button @click="buttonclick2('Teste 2')">2</el-button>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import env from '@/config/environment'

export default {
  name: 'About',
  data () {
    return {
      tableData1: [],
      tableData2: [],
      tableData3: [],
      tableData4: [],
      tableData5: []
    }
  },
  computed: {
    ...mapState('Shared', [
      'onlineState',
      'isSyncing',
      'isSyncingPending',
      'isGPSError'
    ]),
    ...mapState('Cargos', ['cargo']),
    ...mapState('Trucks', ['selectedTruck']),
    ...mapState('User', ['profile']),
    ...mapState('Shared', ['deviceInfo']),
    ...mapGetters('Shared', ['isConnectionFast'])
  },
  mounted () {
    this.tableData1 = [
      { field: 'Versão', value: env.build.version },
      { field: 'Build Date', value: env.build.buildDate },
      { field: 'Ambiente', value: env.build.nodeEnv }
    ]

    this.tableData2 = [
      { field: 'Online', value: (this.onlineState ? 'Sim' : 'Não') },
      { field: 'Wifi', value: (this.isConnectionFast ? 'Conectado' : 'Desconectado') },
      { field: 'GPS', value: (this.isGPSError ? 'Com falha' : 'OK') },
      { field: 'Fotos na fila', value: this.isSyncingPending },
      { field: 'Enviando fotos', value: (this.isSyncing ? 'Sim' : 'Não') }

    ]

    let login = (this.profile && this.profile.login) ? this.profile.login : 'no_login@id.app.m.r'
    login = login.split('@')[0]

    this.tableData3 = [
      { field: 'Usuário', value: (this.profile ? this.profile.name || login : '---') },
      { field: 'Carga', value: (this.cargo.branch_id ? `${this.cargo.branch_id}/${this.cargo.cargo_id}` : '---') },
      { field: 'Veículo', value: this.selectedTruck.truck_id || '---' }
    ]

    this.tableData4 = [
      { field: 'param1', value: `...${env.firebase.apiKey.substr(env.firebase.apiKey.length - 4)}` },
      { field: 'param2', value: env.firebase.authDomain.split('.')[0] },
      { field: 'param3', value: env.firebase.databaseUrl.split('//')[1].split('.')[0] },
      { field: 'param4', value: env.firebase.projectId },
      { field: 'param5', value: `...${env.firebase.messagingSenderId.substr(env.firebase.messagingSenderId.length - 4)}` },
      { field: 'param6', value: env.firebase.storageBucket.split('.')[0] },
      { field: 'param7', value: env.firebase.workBucket.split('//')[1].split('.')[0] }
    ]

    this.tableData5 = [
      { field: 'Navegador', value: `${this.$$to(this, 'deviceInfo.browser.name')} ${this.$$to(this, 'deviceInfo.browser.version')}` },
      { field: 'Sistema', value: `${this.$$to(this, 'deviceInfo.os.name')} ${this.$$to(this, 'deviceInfo.os.version')}` },
      { field: 'Resolução', value: `${this.$$to(this, 'deviceInfo.viewport.height')}x${this.$$to(this, 'deviceInfo.viewport.width')}*${this.$$to(this, 'deviceInfo.viewport.zoom')}` }
    ]
  },
  methods: {
    ...mapMutations('Shared', ['setError']),
    buttonclick1 (msg) {
      this.setError({ err: new Error(msg), that: this })
    },
    buttonclick2 (msg) {},
    goToStatus () {
      this.$router.push('/status/conexao')
    }
  }
}
</script>

<style>
  .about-tables td {
    padding: 0 !important;
  }

  .about-tables td:first-child {
    font-size: 10px !important;
  }

  .about-tables {
    margin-bottom:30px;
    width: 95%;
  }

  .describe {
    text-align: center;
  }

  .describe-title {
    font-weight: bold;
    font-size: 14px;
  }

  .describe-body {
    font-size: 10px;
  }

</style>
