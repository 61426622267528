<template>
  <div>

    <el-menu
      default-active="2"
      class="el-menu-vertical"
      @open="handleOpen"
      @close="handleClose"
      @select="handleSelect"
      :collapse="menuCollapse"
      :router="true">

      <el-menu-item index="cargos" @click="handleClick">
        <icon class="icon" name="route"></icon>
        <span>Cargas</span>
      </el-menu-item>

      <el-menu-item index="drivers">
        <icon class="icon" name="id-card"></icon>
        <span>Motoristas</span>
      </el-menu-item>

      <el-menu-item index="trucks">
        <icon class="icon" name="truck-moving"></icon>
        <span>Veículos</span>
      </el-menu-item>

      <el-menu-item index="events">
        <i class="el-icon-news"></i>
        <span>Eventos</span>
      </el-menu-item>

      <el-submenu index="10" disabled>
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>Admin</span>
        </template>

        <el-menu-item-group title="Group One">
          <el-menu-item index="1-1">item one</el-menu-item>
          <el-menu-item index="1-2">item one</el-menu-item>
        </el-menu-item-group>

        <el-menu-item-group title="Group Two">
          <el-menu-item index="1-3">item three</el-menu-item>
        </el-menu-item-group>

        <el-submenu index="1-4">
          <template slot="title">item four</template>
          <el-menu-item index="1-4-1">item one</el-menu-item>
        </el-submenu>
      </el-submenu>

    </el-menu>

  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'navMenu',
  data () {
    return {}
  },
  props: {
    menuCollapse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('User', ['profile']),
    ...mapState('route', ['name'])
  },
  methods: {
    ...mapMutations('Shared', ['setError']),
    goTo (destination) {
      if (!this.selectedTruck.truck_id) this.setError('Veículo deve ser selecionado')
      else this.$router.push(destination)
    },
    handleOpen (key, keyPath) {},
    handleClose (key, keyPath) {},
    handleSelect (key, keyPath) {},
    handleClick (menuItem) {}
  }
}
</script>

<style scoped>
.el-menu-vertical:not(.el-menu--collapse) {
  min-width: 200px;
}
svg {
  margin-right: 5px;
  width: 24px;
  text-align: center;
}

@media only screen and (max-width: 600px) {
    .el-menu--collapse {
      display: none;
    }
}

</style>
