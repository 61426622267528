export default {

  bind (el, binding, vnode) {
    let current = 0
    if (binding.value.enabled) {
      el.addEventListener('dblclick', function () {
        let incremento = binding.value.increment || 90
        let efeito

        if (!binding.arg || binding.arg === 'rotate') {
          if (binding.modifiers.reverse) {
            current -= incremento
          } else {
            current += incremento
          }

          efeito = `rotate(${current}deg)`
        } else if (binding.arg === 'scale') {
          efeito = `scale(${incremento})`
        }

        el.style.transform = efeito

        if (binding.modifiers.animate) el.style.transition = 'transform 0.5s'
      })
    }
  }
}
