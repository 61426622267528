<template>
  <div class="delivery-delivered-component">
    <div class="area-entregue">
      Data da Entrega:
      {{ $moment.unix(customer.delivered_at.seconds).format('DD/MM/YYYY HH:mm') }} <br>
      <span v-if="customer.partial" style="color: #FF8C00"> ( Entrega Parcial ) </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'delivery-delivered',
  data () {
    return {}
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
  .delivery-delivered-component {
    color:black;
    font-weight: bold;
    text-align: center;
  }
  .area-entregue {
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>
