<template>
    <div>
      <h1>Status Conexão</h1>
      <br>
      <table>
        <tr><th style="width:200px">Campo</th><th>Valor</th></tr>
        <tr><td>loop i</td>          <td> {{ i }} </td></tr>
        <tr><td>status</td>     <td> {{ status }} </td></tr>
        <tr><td>typeeff</td>    <td> {{ connectionEffectiveType }} </td></tr>
        <tr><td>type</td>       <td> {{ connectionType }} </td></tr>
        <tr><td>downlink</td>   <td> {{ downlink }} </td></tr>
        <tr><td>downlinkMax</td><td> {{ downlinkMax }} </td></tr>
        <tr><td>rtt</td>        <td> {{ rtt }} </td></tr>
        <tr><td>saveData</td>   <td> {{ saveData }} </td></tr>
      </table>

      <h1>windows.navigator.geolocation</h1>
      <pre>
        {{ position }}
        {{ positionFinished }}
      </pre>
      <h1>windows.navigator</h1>
      <pre>
        {{ navigatorUX }}
      </pre>
      <h1>windows.navigator.connection</h1>
      <pre>
        {{ navigatorUXCon }}
      </pre>
      <h1>uaParser</h1>
      <pre>
        {{ uaParser }}
      </pre>
    </div>
</template>

<script>
export default {
  name: 'StatusConexao',
  data () {
    return {
      status: null,
      connection: null,
      connectionEffectiveType: null,
      connectionType: null,
      downlink: null,
      rtt: null,
      saveData: null,
      downlinkMax: null,
      i: 0,
      interval: null,
      navigatorUX: {},
      navigatorUXCon: {},
      uaParser: this.$$uaReport,
      position: null,
      positionFinished: false
    }
  },
  created () {},
  mounted () {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    }

    const that = this

    navigator.geolocation.getCurrentPosition(position => {
      console.log('getLocation', position)
      that.positionFinished = true
      that.position = {
        longitude: position.coords.longitude,
        latitude: position.coords.latitude,
        accuracy: position.coords.accuracy,
        timestamp: position.timestamp,
        altitude: position.coords.altitude,
        altitudeAccuracy: position.coords.altitudeAccuracy,
        heading: position.coords.heading,
        speed: position.coords.speed
      }
    }, function (error) {
      console.log(error)
      that.positionFinished = {
        code: error.code,
        message: error.message
      }
    }, options)

    for (var key1 in navigator) {
      if (typeof navigator[key1] !== "function") {
        this.navigatorUX[key1] = navigator[key1]
      }
    }

    for (var key2 in navigator.connection) {
      if (typeof navigator.connection[key2] !== "function") {
        this.navigatorUXCon[key2] = navigator.connection[key2]
      }
    }

    this.status = (navigator.onLine) ? 'online' : 'offline'
    console.log('navigator', navigator)

    if (!this.$$browser.isApple) {
      this.connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection
      this.connectionEffectiveType = this.connection.effectiveType
      this.connectionType = this.connection.type
      this.downlink = this.connection.downlink
      this.rtt = this.connection.rtt
      this.saveData = this.connection.saveData
      this.downlinkMax = this.connection.downlinkMax

      this.interval = setInterval(() => {
        this.connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection
        this.status = (navigator.onLine) ? 'online' : 'offline'
        this.connectionEffectiveType = this.connection.effectiveType
        this.connectionType = this.connection.type
        this.downlink = this.connection.downlink
        this.rtt = this.connection.rtt
        this.saveData = this.connection.saveData
        this.downlinkMax = this.connection.downlinkMax
        this.i = this.i + 1
      }, 3000)
    }
  },
  beforeDestroy () {
    if (this.interval) clearInterval(this.interval)
  },
  methods: {
    askPermission() {
      if (navigator.permissions && navigator.permissions.query) {
      // try permissions APIs first
        navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
          // Will return ['granted', 'prompt', 'denied']
          const permission = result.state
          if (permission === 'granted' || permission === 'prompt') {
            this._onGetCurrentLocation()
          }
        })
      } else if (navigator.geolocation) {
      // then Navigation APIs
        this._onGetCurrentLocation()
      }
    },

    _onGetCurrentLocation () {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
      navigator.geolocation.getCurrentPosition(function (position) {
        // use coordinates
        const marker = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      }, function (error) {
        console.log(error)
      }, options)
    }

  }
}
</script>
