<template>
    <div class="login-form">
      <h1>Login</h1>
      <el-form ref="loginForm" :model="form" :rules="rules" hide-required-asterisk @keyup.enter.native="login">

        <el-form-item label="Empresa" prop="companie">
          <el-input v-model="form.companie" auto-complete="off" placeholder="Empresa" :disabled="(!allowCompanieEdit)" required ref="companie"></el-input>
          <div class="form-input-extra-option" ><a @click="allowCompanieEdit = true" class="form-input-extra-option-link">Outra Empresa?</a> </div>
        </el-form-item>

        <el-form-item label="Usuário" prop="username">
          <el-input v-model="form.username" auto-complete="off" required ref="username"></el-input>
        </el-form-item>

        <el-form-item label="Senha" prop="password">
          <el-input type="password" v-model="form.password" auto-complete="off" required ref="password"></el-input>
          <div class="form-input-extra-option" ><a @click="resetPassword" class="form-input-extra-option-link">Esqueceu a senha?</a> </div>
        </el-form-item>

        <div class="actions-buttons actions-buttons-fullWidth">
          <el-button type="primary" @click="login">Entrar</el-button>
        </div>
      </el-form>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import openStore from '@/services/localForageService'

const storeParams = openStore('params')

export default {
  name: 'Login',
  data () {
    return {
      form: {
        username: null,
        password: null,
        companie: null
      },
      validCompanie: false,
      allowCompanieEdit: true,
      rules: {
        companie: [
          { required: true, type: 'string', pattern: /^[a-z0-9]+$/i, message: 'Utilize apenas letras e números no campo empresa', trigger: 'blur' },
          { required: true, type: 'string', message: 'Campo empresa é obrigatório', trigger: 'blur' },
          { validator: this.validateCompanie, trigger: 'none' }
        ],
        username: [
          { required: true, type: 'string', pattern: /^[a-z0-9._]+$/i, message: 'Utilize apenas letras, números, ponto (.) e underline (_) no campo usuário', trigger: 'blur' },
          { required: true, type: 'string', message: 'Campo usuário é obrigatório' }
        ],
        password: [
          { required: true, message: 'Campo senha é obrigatório', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    ...mapActions('User', ['userLogin', 'getCompanieByDnsName']),
    ...mapMutations('Shared', ['clearError', 'setInfoMessage', 'setLoadingMessage']),
    login () {
      this.$refs['loginForm'].validate(valid => {
        if (valid) {
          storeParams.setItem('companieDnsName', this.form.companie)
          storeParams.setItem('username', this.form.username)
          // storeParams.setItem('password', this.form.password)
          this.userLogin({ form: this.form })
            .then(userData => {
              if (userData.profile) {
                this.$router.push({ name: 'Home' })
              }
            })
        }
      })
    },
    resetPassword () {
      this.setInfoMessage('Contate o adm para trocar a senha')
    },
    validateCompanie (rule, value, callback) {
      this.getCompanieByDnsName({ dnsname: this.form.companie, bypassRemoveMsg: true })
        .then(companie => {
          if (companie) {
            storeParams.setItem('companieValid', true)
            callback()
          } else {
            this.setLoadingMessage('')
            storeParams.setItem('companieValid', false)
            callback(new Error('Empresa digitada não é válida'))
          }
        })
    },
    setFocus () {
      if (this.form.companie === null) {
        this.$refs.companie.$refs.input.focus()
      } else if (!this.form.username) {
        // this.$refs.username.focus() // default do Vue
        // this.$refs.username.$el.getElementsByTagName('input')[0].focus() // hack 1 com element ui
        this.$refs.username.$refs.input.focus() // hack 2 com element ui
      } else {
        this.$refs.password.$refs.input.focus()
      }
    },
    getCompanieDnsName () {
      return new Promise(resolve => {
        // if Form is filled
        if (this.form.companie) resolve(this.form.companie)
        // else check if route has dnsname
        else if (this.$route.params.comp) resolve(this.$route.params.comp)
        // Else verify if store has dnsname saved
        else storeParams.getItem('companieDnsName').then(companie => resolve(companie))
      })
    }
  },
  created () {
    storeParams.getItem('companieValid').then(isvalid => {
      if (isvalid && this.form.companie) this.allowCompanieEdit = false
      else this.allowCompanieEdit = true
    })
    // storeParams.getItem('password').then(password => { this.form.password = password })
  },
  mounted () {
    this.getCompanieDnsName()
      .then(companie => { this.form.companie = companie })
      .then(() => storeParams.getItem('username'))
      .then(username => { this.form.username = username })
      .then(() => this.setFocus())
  }
}
</script>

<style scoped>
.login-form {
  margin-top: 10%;
  max-width: 350px;
}
</style>
