<template>
  <div id="app">
    <el-container>

      <header-bar :alertmessage="alertMessage"/>

      <el-container>
        <nav-menu :menuCollapse="showNavMenuCollapse" v-if="showNavMenuBar"/>

        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>

      <el-footer height="30px">
        <file-upload-message-simple :uploadingStatus="uploadingStatus" :timeout="3000" class="uploadMessage"/>
        <div class="versao" @click="hackWifi">{{ versao }} | {{ build_date }}{{ nodeEndPrint }}</div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import headerBar from '@/components/Header/headerbar.vue'
import navMenu from '@/components/Menu/navmenu.vue'
import FileUploadMessageSimple from '@/components/Shared/FileUploadMessageSimple'
import fileUpload from '@/services/fileUploadService'
import env from '@/config/environment'

export default {
  name: 'app',
  components: {
    headerBar,
    navMenu,
    FileUploadMessageSimple
  },
  data () {
    return {
      versao: env.build.version,
      build_date: env.build.buildDate,
      node_env: env.build.nodeEnv,
      loading: null,
      installPromptEvent: undefined,
      queueImageUploadJob: [],
      uaParser: this.$$uaParser(),
      alertMessage: {
        msg: '',
        show: false
      }
    }
  },
  methods: {
    ...mapActions('Shared', [
      'setOnlineState',
      'getAppVersions'
    ]),
    ...mapMutations('Shared', [
      'clearError',
      'clearInfoMessage',
      'setSwNeedUpdate',
      'setShowItems',
      'setConnectionType',
      'setConnectionTypeBypass',
      'setUploadingFirstLoad'
    ]),
    hackWifi () {
      this.setConnectionTypeBypass(!this.connectionTypeBypass)
    }
  },
  computed: {
    ...mapState('Shared', [
      'loadingMessage',
      'infoMessage',
      'errorMessage',
      'uploadingStatus',
      'showNavMenuCollapse',
      'showNavMenuBar',
      'connectionTypeBypass',
      'appVersions'
    ]),
    ...mapState('route', ['name']),
    ...mapGetters('Shared', ['swNeedUpdate']),
    nodeEndPrint () {
      return this.node_env !== 'production' ? ` | ${this.node_env}` : ''
    }
  },
  watch: {
    loadingMessage (value) {
      if (value && !this.loading) {
        this.loading = this.$loading({
          lock: true,
          text: value,
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
      } else if (this.loading && this.loading.visible && value) {
        this.loading.text = value
      } else {
        if (this.loading) {
          this.loading.close()
          this.loading = null
        }
      }
    },
    errorMessage (value) {
      if (value) {
        this.$message({ type: 'error', message: value, showClose: true, duration: 8000, onClose: this.clearError })
      }
    },
    infoMessage (value) {
      if (value) {
        this.$message({ type: 'info', message: value, onClose: this.clearInfoMessage })
      }
    },
    swNeedUpdate (value) {
      // Notifica usuário que existem atualizações do app
      if (value) {
        // Se existir atualização, force reload
        if (this.$route.name === 'Home' && this.swNeedUpdate) {
          // this.setSwNeedUpdate(false)
          // setTimeout(() => { window.location.reload(true) }, 800)
          setTimeout(() => { this.$router.push('/reload') }, 800)
        }
      }
    },
    name (value) {
      // Menu Bar
      // if (value !== 'Login') {
      //   this.setShowItems({stateName: 'showNavMenuBar', value: true})
      // } else {
      //   this.setShowItems({stateName: 'showNavMenuBar', value: false})
      // }

      // Left Back Arrow
      if (value !== 'Login' && value !== 'Home' && value !== 'Cargo') {
        this.setShowItems({ stateName: 'showHeaderLeftArrow', value: true })
      } else {
        this.setShowItems({ stateName: 'showHeaderLeftArrow', value: false })
      }

      // Menu dots
      if (value !== 'Login') {
        this.setShowItems({ stateName: 'showHeaderDots', value: true })
      } else {
        this.setShowItems({ stateName: 'showHeaderDots', value: false })
      }
    }
  },
  created () {
    // Se navegador não compatível
    // Desativado quando permitido o uso de celulares iphone com o app
    // if (!this.$$browser.supported) {
    //   this.alertMessage.show = true
    //   this.alertMessage.msg = 'Navegador incompatível! Para melhor funcionamento do app utilize o Chrome'
    // }

    // Se versão do navegador não compatível
    // if (this.$$browser.supported && !this.$$browser.version) {
    //   this.alertMessage.show = true
    //   this.alertMessage.msg = 'Versão do Chrome desatualizada!'
    // }

    // Reset queue upload
    this.setUploadingFirstLoad(true)

    // Clear any messages from cache
    this.clearError()
    this.clearInfoMessage()

    // Upload files/images/audios queue
    this.queueImageUploadJob.forEach(intID => clearInterval(intID))
    this.queueImageUploadJob.push(setInterval(() => { fileUpload.queueImageUpload() }, env.config.wifiInterval))
    this.queueImageUploadJob.push(setInterval(() => { this.setConnectionTypeBypass(false) }, env.config.wifiBypassInterval))

    // Init title from env
    document.title = env.config.title
  },
  mounted () {
    this.setOnlineState()
    window.addEventListener('online', this.setOnlineState)
    window.addEventListener('offline', this.setOnlineState)

    if (!this.$$browser.isApple) {
      navigator.connection.addEventListener('change', this.setConnectionType)
    }

    // Verifica versão local com versão declarada no banco
    if (navigator.onLine) {
      this.getAppVersions()
        .then(versions => {
          const bdAr = versions.app_build.split('.')
          const dbNumberBuild = new Date(`20${bdAr[2]}-${bdAr[1]}-${bdAr[0]}T${bdAr[3]}:00:00.000-03:00`)
          const locAr = this.build_date.split('.')
          const envNumberBuild = new Date(`20${locAr[2]}-${locAr[1]}-${locAr[0]}T${locAr[3]}:00:00.000-03:00`)

          const bdVer = versions.app_version.split('.')
          const locVer = this.versao.split('.')
          const dbNumberVer = parseInt(`1${bdVer[0].replace(/v/g, '')}` + bdVer[1] + bdVer[2])
          const envNumberVer = parseInt(`1${locVer[0].replace(/v/g, '')}` + locVer[1] + locVer[2])

          setTimeout(() => {
            if ((dbNumberBuild > envNumberBuild) || (dbNumberVer > envNumberVer)) {
              this.$confirm('Atualizações encontradas, deseja atualizar agora?', 'Novas atualizações', {
                confirmButtonText: 'Sim, faça agora!',
                cancelButtonText: 'Não, vou fazer depois',
                type: 'warning'
              })
                .then(() => {
                  this.setSwNeedUpdate(true)
                  setTimeout(() => { this.$router.push('/reload') }, 500)
                })
            }
          }, 1000)
        })
    }
  },
  beforeDestroy () {
    window.removeEventListener('online', this.setOnlineState)
    window.removeEventListener('offline', this.setOnlineState)
    if (!this.$$browser.isApple) {
      navigator.connection.removeEventListener('change', this.setConnectionType)
    }
  }
}
</script>

<style>
  html {
    height: 100%;
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    position: relative;
    margin: 0;
    min-height: 100%;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }

  /* body::before  {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("../src/assets/logo_50px.png") no-repeat center;
    opacity: .4;
    filter: grayscale(1) invert(1);
    -webkit-filter: grayscale(1) invert(100%);
  } */

  h1 {
    border-bottom: 1px #ccc solid;
    text-transform: uppercase;
    font-size: 15px;
    padding-bottom: 5px;
    margin-top: 5px;
  }

  h2 {
    border-bottom: 1px #ccc solid;
    text-transform: uppercase;
    font-size: 13px;
    padding-bottom: 5px;
    margin-bottom: 4px;
  }

  .versao {
    font-size: 10px;
    position: absolute;
    bottom: 6px;
    right: 2px;
    line-height: 10px;
  }

  .uploadMessage {
    font-size: 10px;
    position: absolute;
    line-height: 10px;
    color: white;
    bottom: 6px;
    left: 2px;
  }

  .app-main-div {
    z-index: 1;
    height: 100%;
  }

  .el-main {
    position: relative;
    margin: auto;
    width: 98%;
  }

  .el-main>div {
    overflow: auto;
    margin: auto;
    position: relative;
    max-width: 400px;
    margin-bottom: 30px;
  }

  .el-container main {
    padding: 5px 18px;
    min-height: 100%;
  }

  .el-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-image: linear-gradient(to bottom, white, black 40%);
    color: #FFFFFF;
    font-size: 10px;
    text-align: center;
  }

  .h2-descricao{
    font-size: 14px;
    margin-bottom: 10px;
    color: #808080;
    line-height: 1.5;
    margin-top: 5px;
  }
  .h2-descricao-destaque {
    font-weight: bold;
    color: black;
  }

  /* altera o width padrão do componente message do element.io */
  .el-message {
    min-width: 95%;
    font-size: 11px;
    font-weight: normal;
    min-width: 280px;
  }

  .el-message-box {
    width: 95% !important;
  }

  /* Padronização de formuários */
  .el-form-item + h2 {
    margin-top: 25px;
  }

  .el-select {
    width: 100%;
  }

  .el-input-number {
    width: 70%;
    margin-left: 14%;
    margin-right: 14%;
  }

  .el-input-number + .el-form-item__error, .input-file-container + .el-form-item__error, .el-form-item__content > .el-form-item__error {
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    position: relative;
  }

  .el-form-item > label.el-form-item__label {
    padding: 0;
  }

  .el-form-error-message-to-success {
    font-weight: normal !important;
    color: #67C23A !important;
  }

  /* classes para os buttons de ação no final dos formulários */
  .actions-buttons {
    width: 100%;
    text-align: center;
    align-content: center;
    margin-top: 10px;
    border-top: 1px #ccc solid;
    padding-top: 10px;
  }

  .actions-buttons button {
    margin-bottom: 10px;
    margin-left: 0px;
    width: 160px;
  }

  .actions-buttons-fullWidth button {
    width: 90%;
  }

  .el-button + .el-button, .actions-buttons > .el-button:not(:first-child) {
    margin-left: 0;
  }

  .actions-buttons a:link {
    text-decoration: none;
  }

  .el-button--primary {
    background-color: #00AFEF;
  }

  /* classes para adicionar opções extras relacionadas com campos de formuários */
  .form-input-extra-option-link {
    color: #ccc;
    font-size: 12px;
  }

  .form-input-extra-option-link-red{
    color: #f56c6c;
    font-size: 12px;
  }

  .form-input-extra-option {
    text-align: right;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;
  }

/* não esta funcionando ainda, pesquisar */
/* @media only screen and (max-width: 360px) {
    .mobile { min-width: 360px; }
}

@media only screen and (min-width: 361px) {
    .mobile { min-width: 400px; }
}

@media only screen and (min-width: 400px) {
    .mobile { min-width: 500px; }
}

@media only screen and (min-width: 500px) {
    .mobile { min-width: 600px; }

@media only screen and (min-width: 600px) {
    .mobile { min-width: 700px; }
} */

/* para usar na route transition */
/* .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
} */
</style>
