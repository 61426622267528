<template>
  <div class="customers-list">
    <h1>Lista de entregas</h1>

    <h2>
      Carga: {{ cargo.branch_id }}/{{ cargo.cargo_id }} <br>
      Veículo: {{ selectedTruck.truck_id }}
    </h2>

    <div v-for="customer in cargo.customers" :key="$$trimCleanConcat(customer.customer, customer.address1, customer.sequenceofdelivery)" >
        <cargo-title-slot :customer="customer" @click="goToCustomer" />
    </div>

    <div class="actions-buttons actions-buttons-fullWidth">
      <el-button v-if="isApple && isSyncingPending <= 0" type="info">
        {{isSyncingPending}} foto(s) na fila
      </el-button>
      <el-button v-if="isApple && connectionTypeBypass && isSyncingPending > 0" type="info" @click="hackWifi">
        <span v-if="isSyncing">Enviando...</span><span v-if="!isSyncing">Preparando para enviar...</span>
        <br>Toque aqui para desativar o envio de fotos
        <br>{{isSyncingPending}} foto(s) na fila
      </el-button>
      <el-button v-if="isApple && !connectionTypeBypass && isSyncingPending > 0" type="warning" @click="hackWifi">
        Existem fotos pendentes para enviar
        <br>Toque aqui para ativar o envio manual de fotos
        <br>{{isSyncingPending}} foto(s) na fila
      </el-button>
      <el-button type="danger" @click="closeCargo">Encerrar Carga</el-button>
    </div>

  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import CargoTitleSlot from '@/components/Cargo/Complements/CargoTitleSlot'

export default {
  name: 'cargo',
  components: {
    CargoTitleSlot
  },
  data () {
    return {
      isApple: this.$$browser.isApple
    }
  },
  computed: {
    ...mapState('Cargos', ['cargo']),
    ...mapState('Trucks', ['selectedTruck']),
    ...mapState('Shared', ['onlineState', 'connectionTypeBypass', 'isSyncingPending', 'isSyncing']),
    ...mapGetters('User', ['companieGeneralSettings']),
    payFuelOnClose () {
      let setting = {
        enabled: this.$$to(this, 'companieGeneralSettings.forceEvtPayFuelBeforeCloseCargo'),
        time: this.$$to(this, 'companieGeneralSettings.forceEvtPayFuelBeforeCloseCargoTime')
      }

      if (setting.enabled === undefined) setting.enabled = true
      if (setting.time === undefined) setting.time = 60

      return setting
    }
  },
  methods: {
    ...mapActions('Events', ['validateLastPayFuel']),
    ...mapActions('Cargos', ['setDeliverCustomer', 'closeCargoValidade', 'closeCargoConfirm']),
    ...mapActions('User', ['userLogout']),
    ...mapMutations('Shared', ['setError', 'setInfoMessage', 'setConnectionTypeBypass']),
    ...mapMutations('Cargos', ['changeCargos']),
    closeCargo () {
      this.closeCargoValidade()
        .then(valid => {
          if (!valid) throw new Error('Existem cargas abertas! Finalize todas as cargas antes de continuar')
        })
        .then(() => {
          // Check if exist at least one fuel event for selected truck in last pass minutes
          if (this.payFuelOnClose.enabled) {
            return this.validateLastPayFuel(this.payFuelOnClose.time)
          } else {
            return 1
          }
        })
        .then(lastFuel => {
          // Função validateLastPayFuel retorna um array com a quantidade de eventos encontrados dentro do tempo informado
          if (lastFuel.length < 1) throw new Error('Não encontramos eventos de abastecimento na última hora, o abastecimetno é obrigatório para finalizar a carga.')
        })
        .then(() => {
          this.$router.push('/cargo/close')
        })
        .catch(err => {
          if (err.message) {
            this.$alert(err.message, 'Atenção', {
              confirmButtonText: 'OK',
              type: 'error'
            })
          }
        })
    },
    goToCustomer (customer, to = '/deliver') {
      this.setDeliverCustomer(customer)
      this.$router.push(to)
    },
    hackWifi () {
      this.setConnectionTypeBypass(!this.connectionTypeBypass)
    }
  },
  beforeRouteEnter (to, from, next) {
    // From events screen to Home screen bypass database querys
    if (from.name === 'Delivery' && to.name === 'Cargo') to.meta.byPassReload = true
    else delete to.meta.byPassReload
    next()
  },
  beforeMount () {
    if (!this.$route.meta.byPassReload) {
      let deliveringCustomer = Object.values(this.cargo.customers).find(customer => ['delivering', 'delivering_checkin'].includes(customer.status))

      // If has one customer being delivered... go there
      if (deliveringCustomer && deliveringCustomer.status === 'delivering') this.goToCustomer(deliveringCustomer)
      else if (deliveringCustomer && deliveringCustomer.status === 'delivering_checkin') this.goToCustomer(deliveringCustomer, '/delivery')
      else this.setDeliverCustomer()
    } else {
      delete this.$route.meta.byPassReload
      this.setDeliverCustomer()
    }
  }
}
</script>

<style>
  .customers-list {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex-flow: column nowrap;
  }

  .actions-buttons {
    margin-top: auto;
  }

</style>
