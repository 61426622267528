const getDefaultCargosState = () => {
  return {
    cargo: [],
    deliverCustomer: {},
    tempDeliveryFormData: {}
  }
}

export default getDefaultCargosState()
export { getDefaultCargosState }
