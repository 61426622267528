import dates from '@/helpers/getDateRange'

const defaultUploadingStatus = () => {
  return {
    filesBeingUploaded: 0,
    overallProgress: 0,
    overallStatus: 'not_uploading'
  }
}

const defaultUploadingConfig = () => {
  return {
    firstLoad: true,
    retryMax: 6,
    retryMaxDuration: 30,
    uploadSimultaneous: 1
  }
}

const defaultSearchState = () => {
  return {
    searchStartDateFilter: dates.d.startDateYesterdayFull, // Campo data inicial das barras de filtro, para ser compartilhada em várias telas
    searchEndDateFilter: dates.d.endDateFull, // Campo data final das barras de filtro, para ser compartilhada em várias telas
    searchBranchsFilter: ['*'] // Campo array de branch das barras de filtro, para ser compartilhada em várias telas
  }
}

const getDefaultSharedState = () => {
  let sharedState = {
    loadingMessage: '', // Mensagens de loading
    infoMessage: null, // Mensagem de avisos geral
    errorMessage: null, // Mensagem de Erro
    uploadingStatus: Object.assign({}, defaultUploadingStatus()), // Status da fila de upload
    uploadingConfig: Object.assign({}, defaultUploadingConfig()), // Config da fila de upload
    uploadingFiles: [], // Quantidade de arquivos em processo de envio para o firebase
    onlineState: false, // Se online ou offline, conexão com internet
    connectionTypeBypass: false, // Se a detecção wifi deve ser ignorado, para propósitos de desenvolvimento
    connectionType: null, // bluetooth, cellular, ethernet, none, wifi, wimax, other, unknown
    connectionEffectiveType: null, // 'slow-2g', '2g', '3g', or '4g'.
    lastLocation: null, // Ultima localização coletada
    lastLocationUpdate: null, // Data com ultima atualização ad localização GPS
    lastLocationUpdateDB: null, // Data com ultima atualização ad localização GPS no banco
    swNeedUpdate: false, // Se o service worker possui atualização
    showNavMenuCollapse: false, // Se menu esta recolhido
    showNavMenuBar: false, // Se deve exibir menu de navegação a esquerda
    showHeaderLeftArrow: false, // Se deve exibir botão de voltar no header
    showHeaderDots: false, // Se deve excibir o menu de 3 pontos
    isSyncing: false, // Se processo de upload das imagens esta em andamento
    isSyncingPending: 0, // Quantidade de imagens na fila para envio
    isGPSError: false, // Se o processo de coletar o GPS apresentou falhas, seja erro ou negado
    deviceInfo: null, // dados do device, versao do navegador, resolução, so, etc...
    appVersions: null // ultima versão lançada do app carregada do banco firestore
  }

  Object.assign(sharedState, defaultSearchState())

  return sharedState
}

export default getDefaultSharedState()
export { getDefaultSharedState }
export { defaultUploadingStatus }
export { defaultSearchState }
