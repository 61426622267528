export default {
  isLoggedIn (state) {
    if (state.profile && state.profile.auth_uid && state.profile.login) return true
    else return false
  },
  cancellationReasonsPartial (state) {
    if (state.companie.settings && state.companie.settings.cancellationReasonsPartial) return state.companie.settings.cancellationReasonsPartial
    else return null
  },
  cancellationReasons (state) {
    if (state.companie.settings && state.companie.settings.cancellationReasons) return state.companie.settings.cancellationReasons
    else return null
  },
  companieEventsSettings (state) {
    if (state.companie.settings && state.companie.settings.events) return state.companie.settings.events
    else return {}
  },
  companieImagesSettings (state) {
    if (state.companie.settings && state.companie.settings.images) return state.companie.settings.images
    else return {}
  },
  companiePaymentsSettings (state) {
    if (state.companie.settings && state.companie.settings.payments) return state.companie.settings.payments
    else return { x01: { name: 'Dinheiro', enabled: true } }
  },
  companieGeneralSettings (state) {
    if (state.companie.settings && state.companie.settings.general) return state.companie.settings.general
    else return {}
  },
  getCompanieBranches (state) {
    if (state.companie.branchs) return state.companie.branchs
    return null
  }
}
