<template>
  <div class="delivery-reavailable-component">
    <div class="area-reavailable">
      Entrega reiniciada as {{ $moment.unix(customer.reavailable_at.seconds).format('DD/MM/YYYY HH:mm') }}
      após cancelamento por: <br>
      {{ canceledReasonText }} <br>
      {{ customer.canceled_notice }}<br>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'delivery-canceled-cause',
  data () {
    return {}
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('User', ['cancellationReasons']),
    canceledReasonText () {
      if (this.customer.canceled_reason === 'system') {
        return 'Auto cancelamento do sistema'
      } else if (typeof this.customer.canceled_reason === 'number') {
        return this.cancellationReasons[this.customer.canceled_reason].description
      } else {
        return 'Description ERROR'
      }
    }
  }
}
</script>

<style scoped>
  .delivery-reavailable-component {
    color: gray;
    font-weight: normal;
    text-align: center;
    font-size: 12px;
  }
  .area-reavailable {
    border: 1px solid gray;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>
