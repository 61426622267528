<!--
  EXEMPLO DE USO
  <template>
    <client-details :customer="deliverCustomer" nameHighlight showInvoices
      :customer="customer"                // obrigatório, dados do customer
      :nameHighlight="false"              // opcional, boolean, se nome do cliente deve ser exibido em destaque, default false
      showInvoices                        // opcional, boolean, se lista de notas deve ser exibida, default false
    </foto-upload-button>
  </template>
-->

<template>
    <div class="client-details-component">
      <div class="destaque" v-if="nameHighlight">{{ customer.customer }} </div>
      <div class="texto" v-if="!nameHighlight">Nome: {{ customer.customer }} </div>
      <div class="texto">
        Endereço: {{ customer.address1 }} {{ customer.address2 }}<br>
        Bairro: {{ customer.neighborhood }}<br>
        Cidade: {{ customer.city }} / {{ customer.state }}<br>
        CEP: {{ customer.postal_code || '' }} <br>
        Telefone: {{ customer.phone }}<br>

        <div v-if="customer.delivered_who">Responsável: {{ customer.delivered_who || '' }} </div>
        <div v-if="customer.order_observations">Observações: {{ customer.order_observations || '' }} </div>

        <br>
        <items-invoice-button v-if="showInvoices" :invoices="customer.invoices" :centerTitle="true" centerBody goto="/deliver/invoice" />
      </div>
    </div>
</template>

<script>
import ItemsInvoiceButton from '@/components/Cargo/Complements/ItemsInvoiceButton'

export default {
  name: 'client-details',
  components: {
    ItemsInvoiceButton
  },
  data () {
    return {}
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    nameHighlight: Boolean,
    showInvoices: Boolean
  }
}
</script>

<style scoped>
  .client-details-component {
    line-height: 1.3;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .destaque {
    color: black;
    font-weight: bold;
    padding-bottom: 5px;
    padding-top: 10px;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
  }
  .texto {
    font-size: 13px;
    margin-bottom: 10px;
  }
</style>
