import { store } from '@/store'

export default (rule, value, callback) => {
  const settings = store.getters['User/companieGeneralSettings'] || {}
  const maxUpdate = settings.maxUniqueKmUpdateDiff || 5000
  const minUpdate = settings.minUniqueKmUpdateDiff || 0

  if (value === '' || value === 0) {
    return callback(new Error('KM não pode ser vazio ou zero'))
  } else if ((value - store.state.Trucks.selectedTruck.km) > maxUpdate) {
    return callback(new Error(`KM não pode ser maior que ${store.state.Trucks.selectedTruck.km + maxUpdate}`))
  } else if (value < store.state.Trucks.selectedTruck.km) {
    return callback(new Error(`KM não pode ser menor que ${store.state.Trucks.selectedTruck.km}`))
  } else if ((value - store.state.Trucks.selectedTruck.km) < minUpdate) {
    return callback(new Error(`Atualização mínima do KM deve ser ${minUpdate}`))
  } else {
    return callback()
  }
}
