import { db, timestamp } from '@/config/firebase'
import fileUpload from '@/services/fileUploadService'
import createIds from '@/helpers/createIds'
import getLocation from '@/services/getLocation'

export default {
  saveNewEvent ({ commit, dispatch, rootState }, eventForm) {
    commit('Shared/setLoadingMessage', 'Registrando evento...', { root: true })

    const companyId = rootState.User.profile.company_id || false
    const login = rootState.User.profile.login || false
    const authUid = rootState.User.profile.auth_uid || false
    const truckId = rootState.Trucks.selectedTruck.truck_id || null
    const branchId = rootState.Cargos.cargo.branch_id || null
    const cargoId = rootState.Cargos.cargo.cargo_id || null

    // Basic data validation
    if (!eventForm.type) throw new Error('Invalid data')
    if (!eventForm.data) throw new Error('Invalid data')
    if (!companyId) throw new Error('Company cant be empty')
    if (!truckId) throw new Error('Truck cant be empty')
    if (!login) throw new Error('Invalid login')
    if (!authUid) throw new Error('Invalid uid')

    // Firebase new doc reference
    const docId = createIds.firebaseDoc()
    const collName = 'events'
    const eventRef = db.collection('companies').doc(companyId).collection(collName).doc(docId)

    // Array for tasks promises
    let tasks = []

    // Data to Save
    let newEvent = {
      auth_login: login,
      auth_uid: authUid,
      truck_id: truckId,
      branch_id: branchId,
      cargo_id: cargoId,
      created_at: timestamp,
      event: eventForm.data,
      event_type: eventForm.type,
      images: []
    }

    // Get location and save data to database task
    tasks[0] = getLocation()
      .then(location => { newEvent.location = location })
      .then(() => eventRef.set(newEvent))
      .then(() => newEvent.location)

    // Update km in Firebase and State
    tasks[1] = dispatch('Trucks/saveAndSetTruckNewKm', eventForm.data.kmatual, { root: true })

    // Upload do audio, se existir
    tasks[2] = new Promise((resolve, reject) => {
      if (eventForm.data.audio) {
        const metadata = {
          collection_name: collName,
          doc_id: docId,
          tag: eventForm.data.audio_tag,
          duration: eventForm.data.audio_duration,
          doc_ref: eventRef
        }

        return fileUpload.upload(eventForm.data.audio, metadata, 'audio')
          .then(result => resolve(result))
          .catch(err => reject(err))
      } else {
        resolve()
      }
    })

    // Upload foto do comprovante
    tasks[3] = new Promise((resolve, reject) => {
      // Validade if is array, and if images array has some image
      // As photo needed was a custom admin option, events maybe has or not images atacheds
      if (eventForm.images && Array.isArray(eventForm.images) && (eventForm.images.some(image => image.data !== null)) && eventForm.images.length > 0) {
        // If view send empties images because settings remove photos needed
        return Promise.all(
          eventForm.images
            .filter(image => image.data !== null)
            .map((image, index) => {
              return new Promise((resolve, reject) => {
                const metadata = {
                  collection_name: collName,
                  doc_id: docId,
                  image_tag: image.describe,
                  doc_ref: eventRef
                }

                fileUpload.upload(image.data, metadata)
                  .then(result => resolve(result))
                  .catch(err => reject(err))
              })
            }))
          .then(uplodsResults => resolve(uplodsResults))
      } else {
        resolve(['no_image'])
      }
    })

    commit('Shared/setLoadingMessage', '', { root: true })

    return Promise.all(tasks)
      // When all first group of task ends... wait for the second group, queue images, returned on index 2
      .then(responses => Promise.all(responses[3]))
      // Results of promises in index 2
      .then(responses2 => {
        // responses2 return true if queue success
        return Promise.resolve(true)
      })
      .catch(err => {
        commit('Shared/setError', `Falha ao salvar: ${err.message}`, { root: true })
        return err
      })
  },

  validateLastPayFuel ({ commit, rootState }, minutes) {
    const companyId = rootState.User.profile.company_id || false
    const authUid = rootState.User.profile.auth_uid || false
    const truckId = rootState.Trucks.selectedTruck.truck_id || null

    const today = new Date()
    const minutesago = new Date(today.getTime() - (1000 * 60 * minutes))
    // const yesterday = new Date(today.getTime() - (1000 * 60 * 60 * 24))

    let eventRef = db.collection('companies').doc(companyId).collection('events')

    return eventRef
      .where('truck_id', '==', truckId)
      .where('auth_uid', '==', authUid)
      .where('event_type', '==', 'payfuel')
      .where('created_at', '>', minutesago)
      .limit(1)
      .get()
      .then(querySnapshot => {
        let returnData = []
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            returnData.push(doc.data())
          })
        }
        return returnData
      })
      .catch(err => {
        commit('Shared/setError', err.message, { root: true })
        return []
      })
  }
}
