var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cargo-title-slot-component",class:{
    'background-success': _vm.customer.status === 'delivered',
    'background-canceled': _vm.customer.status === 'canceled',
    'background-partial': _vm.customer.has_invoice_partial,
    'background-canceled2': _vm.customer.has_invoice_canceled
    },on:{"click":function($event){return _vm.titleClick(_vm.customer)}}},[(_vm.customer.status === 'delivered')?_c('div',{staticClass:"icon-status icon-success",class:{
      'icon-success-warning': _vm.customer.has_invoice_partial,
      'icon-success-error': _vm.customer.has_invoice_canceled
    }},[_c('i',{staticClass:"el-icon-success"})]):_vm._e(),(_vm.customer.status === 'canceled')?_c('div',{staticClass:"icon-status icon-canceled"},[_c('i',{staticClass:"el-icon-error"})]):_vm._e(),(_vm.customer.status === 'reavailable')?_c('div',{staticClass:"icon-status icon-info"},[_c('i',{staticClass:"el-icon-info"})]):_vm._e(),(_vm.customer.status === 'delivering')?_c('div',{staticClass:"icon-status icon-canceled"},[_c('icon',{staticClass:"icon",attrs:{"name":"truck-moving"}})],1):_vm._e(),(!_vm.customer.status)?_c('div',{staticClass:"icon-status icon-blank"}):_vm._e(),_c('div',{staticClass:"titulo-texts"},[(!!_vm.customer.fantasy_name)?_c('div',{staticClass:"fantasia-item"},[_vm._v(" "+_vm._s(_vm.customer.fantasy_name)+" ")]):_vm._e(),_c('div',{staticClass:"titulo-item"},[_vm._v(" "+_vm._s(_vm.customer.customer)+" ")]),_c('div',{staticClass:"titulo-endereco"},[_vm._v(" "+_vm._s((_vm.customer.address1).toLowerCase())+" / "+_vm._s((_vm.customer.neighborhood).toLowerCase()))])]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"el-icon-arrow-right el-icon-right"})])
}]

export { render, staticRenderFns }