import axios from 'axios'
import env from '@/config/environment'
import { fbapp } from '@/config/firebase'
import { store } from '@/store'

// https://github.com/axios/axios

// Exemplo de uso dentro do componente
//
// <script>
// import { sendCargos } from '@/services/axiosHttp'

// const data = {}

// export default {
//   methods: {
//     async sendCargosClick1 () {
//       let httpresponse = await sendCargos({ cargos: [{ invalid: data }] })
//     },
//     async sendCargosClick2 () {
//       let httpresponse = await this.$$http.post('/cargos', { cargos: [{ invalid: data }] })
//     }
//   }
// }
// </script>

const callAxios = async (endpoint = '', data = {}, method = 'post') => {
  let token = ''

  if (!env.firebase.api_url) {
    console.error('HTTP request error, invalid API URL', env.firebase.api_url)
    return false
  }

  // Se usuário autenticado
  if (fbapp.auth().currentUser) {
    token = await fbapp.auth().currentUser.getIdToken()
    store.commit('User/changeToken', token, { root: true })

  // Se não autenticado e endpoint === '/log/error'
  } else if (endpoint === '/log/error') {
    token = 'not_auth_for_only_notify_error'

  // Se nenhum dos casos acima
  } else {
    throw new Error('Chamada ao serviço $$http com parâmetos inválidos')
  }

  let axiosObject = {
    url: `${env.firebase.api_url}${endpoint}`,
    method: method,
    headers: {
      'Authorization': `Bearer ${token || null}`,
      'Content-type': 'application/json'
    }
  }

  if (method === 'post') axiosObject.data = data
  else if (method === 'get') axiosObject.params = data

  return axios(axiosObject)
    .then(response => response)
    .catch(error => {
      // console.warn(`HTTP error on request to ${env.firebase.api_url}${endpoint} : `, error.message)
      if (error.response) {
        console.error(error.response.data)
      } else if (error.request) {
        console.error('HTTP request error', error.request)
      } else {
        console.error('HTTP general error', error.message)
      }
      return error
    })
}

const post = async (endpoint, data) => callAxios(endpoint, data, 'post')
const get = async (endpoint, params) => callAxios(endpoint, params, 'get')
const postCargos = (data) => callAxios('/cargos', data, 'post')
const postInvoices = async (data) => callAxios('/invoices', data, 'post')
const postError = (data) => callAxios('/log/error', data, 'post')

const vueAxiosInstall = {
  install (Vue, options) {
    Vue.prototype.$$http = {
      post: post,
      get: get,
      logError: postError
    }
  }
}

export default {
  post: post,
  get: get
}

export { vueAxiosInstall as installAxios }
export { postInvoices as sendInvoices }
export { postCargos as sendCargos }
export { postError as logError }
