<template>
  <div class="delivery-canceled-component">
      <div class="area-cancelado">
        Entrega cancelada <br>
      {{ canceledReasonText }} <br>
      {{ customer.canceled_notice }}<br>
      {{ $moment.unix(customer.canceled_at.seconds).format('DD/MM/YYYY HH:mm') }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'delivery-canceled-cause',
  data () {
    return {}
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('User', ['cancellationReasons']),
    canceledReasonText () {
      if (this.customer.canceled_reason === 'system') {
        return 'Auto cancelamento do sistema'
      } else if (typeof this.customer.canceled_reason === 'number') {
        return this.cancellationReasons[this.customer.canceled_reason].description
      } else {
        return 'Description ERROR'
      }
    }
  }
}
</script>

<style scoped>
  .delivery-canceled-component {
    color: red;
    font-weight: bold;
    text-align: center;
  }
  .area-cancelado {
    border: 1px solid #f56c6c;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>
