// import Vue from 'vue'
// import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate'

// import User from './modules/user'
// import Shared from './modules/shared'
// import Cargos from './modules/cargos'
// import Trucks from './modules/trucks'
// import Events from './modules/events'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   plugins: [createPersistedState({ storage: window.sessionStorage })],
//   strict: process.env.NODE_ENV !== 'production',
//   modules: {
//     User,
//     Shared,
//     Cargos,
//     Trucks,
//     Events
//   }
// })

import Vue from 'vue'
import Vuex from 'vuex'

// Plugins que foram utilizados para persistir o estado do state entre reloads da pagina
// O primeiro não tem suporte ao localforage, sendo inutilizado
// O segundo funcionou, mas encontrei metodo parecido que não utiliza plugins de terceiros
// import createPersistedState from 'vuex-persistedstate' // plugins: [createPersistedState({ storage: window.sessionStorage })],
// import VuexPersistence from 'vuex-persist'

import env from '@/config/environment'
import localforage from 'localforage'
import 'localforage-getitems'
import 'localforage-setitems'

import User from './modules/user'
import Shared from './modules/shared'
import Cargos from './modules/cargos'
import Trucks from './modules/trucks'
import Events from './modules/events'

Vue.use(Vuex)

let modules = {
  User,
  Shared,
  Cargos,
  Trucks,
  Events
}

let store = null
let savedState = null

// Versão do vuex persistent com auxilio do link sem plugin de terceiros
// Guia de https://rauvetech.com/blog/offline-persistence-vuex-state-with-localforage

let VuexStorage = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: env.config.index_db,
  version: 1.0,
  storeName: 'VuexSyncDb',
  description: 'Vuex'
})

// Store plugin
const persistPlugin = store => {
  store.subscribe((mutations, state) => {
    // Evento Subscribe recebido a cada atualização do state
    // Subscribe sempre recebe o state completo, para reduzir a carga do indexDb
    // atualizo apenas a chave relacionada ao modulo enviado no mutation
    let moduleName = mutations.type.split('/')[0] // mutation.type = "Shared/setDeviceInfo"
    let stateToSave = {}

    // Json parse to remove object clone error reference on sabe to indexDb
    stateToSave[moduleName] = JSON.parse(JSON.stringify(state[moduleName]))

    // Save to indexDb
    VuexStorage.setItems(stateToSave).catch(err => {
      // console.error(`Localstorage Vuex Indexbd Plugin Subscribe ERROR: ${JSON.stringify(mutations.type)}`, stateToSave, mutations)
      // console.error(err)
    })
  })
}

// Merge states and initialize store
async function populateStatesFromIndexDB () {
  savedState = await VuexStorage.getItems()

  Object.keys(savedState).forEach(stateNames => {
    if (modules[stateNames] && modules[stateNames].state) {
      modules[stateNames].state = Object.assign({}, modules[stateNames].state, savedState[stateNames])
    }
  })

  return modules
}

// Init Store after states populated
async function initializeStore () {
  store = new Vuex.Store({
    plugins: [persistPlugin],
    strict: process.env.NODE_ENV !== 'production',
    modules: modules
  })
  return store
}

// Versão do vuex persistent com plugin
// https://github.com/championswimmer/vuex-persist
// https://github.com/championswimmer/vuex-persist/issues/15

// first define a plugin that emits when the state has been persisted
// const vuexPersistEmitter = () => {
//   return store => {
//     store.subscribe(mutation => {
//       if (mutation.type === 'RESTORE_MUTATION') {
//         store._vm.$root.$emit('storageReady')
//       }
//     })
//   }
// }

// // I only needed to define strict mode here and not in the store.
// const vuexLocal = new VuexPersistence({
//   strictMode: true,
//   asyncStorage: true,
//   storage: localforage,
//   key: 'VuexSyncDbP'
// })

// store = new Vuex.Store({
//   mutations: { RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION },
//   plugins: [ vuexLocal.plugin, vuexPersistEmitter() ],
//   strict: process.env.NODE_ENV !== 'production',
//   modules: modules
// })

export default store
export { store }
export { initializeStore }
export { populateStatesFromIndexDB }
