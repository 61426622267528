<template>
  <div>
    <h1>Check-out</h1>

    <el-form ref="deliveryForm" :model="form" :rules="rules" label-position="top">

      <div class="h2-descricao">
      Fotografe a <span class="h2-descricao-destaque"> Nota Fiscal </span> da entrega. Caso tenha alguma divergência, realize a <span class="h2-descricao-destaque"> Entrega Parcial </span>ou <span class="h2-descricao-destaque">  Cancele </span> a Nota Fiscal.
      </div>

      <div v-for="(invoice, index) in deliverCustomer.invoices" :key="`invoiceBox_${invoice.invoice}_${index}`" class="invoiceBox" :class="{ 'invoice_canceled': (invoice.status === 'canceled') }">

        <el-form-item
          :prop="`receipts_photo.${index}`"
          :rules="{ validator: validateInvoiceReceiptPhoto }"
          :label="`NFe: ${invoice.invoice}  (Vol: ${invoice.volumes})`"
          :key="`receipts_photo.${index}`"
          v-if="(invoice.status !== 'canceled')" >
          <foto-upload-button
            @editButton="invoicePartialCancellation(invoice)"
            @cancelButton="invoiceTotalCancellation(invoice.invoice)"
            @onImage="setInvoiceImage($event, invoice.invoice, `receipts_photo.${index}`)"
            :hasImage="(invoiceHasReceiptPhoto(invoice.invoice))"
            outputFormat="base64"
            beforeText="Foto da Nota"
            :inputId="`delivery-invoice-${invoice.invoice}`" >
          </foto-upload-button>
        </el-form-item>

        <el-form-item
          :label="`NFe: ${invoice.invoice}  (Vol: ${invoice.volumes})`"
          v-if="(invoice.status === 'canceled')" >
          <el-button style="width: 100%;" type="danger" @click="invoiceTotalCancellation(invoice.invoice)">Nota cancelada</el-button>
        </el-form-item>

        <el-form-item
          :prop="`safeguard_photo.${index}`"
          :rules="{ validator: validateInvoiceSafeguardPhoto }"
          :key="`safeguard_photo.${index}`"
          v-if="(invoice.status === 'with_partial')" >
          <foto-upload-button
            @onImage="setInvoiceRessalvaImage($event, invoice.invoice, `safeguard_photo.${index}`)"
            :hasImage="(invoiceHasSafeGuardPhoto(invoice.invoice))"
            outputFormat="base64"
            beforeText="Adicionar foto da ressalva"
            afterText="Foto da ressalva cadastrada"
            btStyle="warning"
            :inputId="`delivery-ressalva-${invoice.invoice}`" >
          </foto-upload-button>
        </el-form-item>

        <br>
        <div v-if="(invoice.status !== 'canceled')" class="payment-block">
          <div class="payment-description">
            <div v-if="invoice.paymentEdited" class="payment-description-edited">
              <span class="h2-descricao">Novo Pagamento</span><br>
              <span  class="h2-descricao" v-for="(payment, index) in invoice.paymentEdited" :key="`payit_${invoice.invoice}_${index}`">
                {{ `  R$ ${parseFloat(payment.ammount).toFixed(2)} com ${payment.name}` }} <span v-if="payment.nsu"> {{ payment.nsu }}</span><br>
              </span>
            </div>
            <div v-else>
              <span class="h2-descricao">Pagamento esperado</span><br>
              <span class="h2-descricao" v-for="(payment, index) in invoice.payment" :key="`payit_${invoice.invoice}_${index}`">
                {{ `  R$ ${parseFloat(payment.ammount).toFixed(2)} com ${getPaymentName(payment)}` }} <br>
              </span>
            </div>
          </div>
          <div class="payment-edit-button">
          <el-button
            @click.prevent="editPaymentDialog(invoice)"
            icon="el-icon-edit"
            type="info" />
          </div>
        </div>
        <el-form-item label="Número da NSU"
          :prop="`nsuNumber`"
          v-if="invoiceHasNsuPay(invoice.invoice)"
          :rules="[
            { required: invoiceHasNsuPayForced(invoice.invoice), message: 'Número NSU obrigatório', trigger: 'blur' },
            { min: 5, message: 'NSU inválida, mínimo de 5 digitos', trigger: 'blur' }
          ]">
          <el-input v-model="form.nsuNumber"
            auto-complete="off"
            @change="nsuupdate(invoice.invoice, form.nsuNumber)"
            type="number" />
        </el-form-item>
      </div>
      <!-- <div class="form-input-extra-option" v-if="(deliverCustomerInvoicesCount > 1)" ><a @click="allInvoicesTotalCancellation" class="form-input-extra-option-link-red">Cancelar todas as notas?</a></div> -->

      <br>
      <h2>Dados complementares</h2>

      <el-form-item
        label="Nome do receptor"
        prop="whoReceived"
        :rules="[{ required: (!this.allInvoicesCanceled), message: 'Campo obrigatório' }]">
        <el-input v-model.lazy="form.whoReceived" auto-complete="off"></el-input>
      </el-form-item>

      <el-form-item label="Documento do receptor" prop="documentReceived">
        <el-input v-model.lazy="form.documentReceived" auto-complete="off"></el-input>
      </el-form-item>

      <el-form-item prop="deliveryNotice" label="Observações adicionais">
        <el-input v-model.lazy="form.deliveryNotice"></el-input>
      </el-form-item>

      <el-form-item label="Informe o KM atual do veículo" prop="km_truck">
        <input-mask-number-field
          @updated="$refs['deliveryForm'].validateField('km_truck')"
          :value.sync="form.km_truck"
          prefix="Km"
          :decimals=1 />
      </el-form-item>

      <el-form-item
        v-if="odomPhoto.enabled"
        label="Foto do odômetro"
        prop="km_photo"
        :rules="{ validator: validateCustomerKmPhoto }">
        <foto-upload-button
          @onImage="setKmTruckImage($event)"
          :hasImage="(customerHasKmPhoto())"
          outputFormat="base64"
          beforeText="Foto do odômetro"
          afterText="Foto do odômetro pronta" >
        </foto-upload-button>
      </el-form-item>

      <br>
      <div class="actions-buttons actions-buttons-fullWidth">
        <div class="h2-descricao">
          Finalize a entrega com o clicando no botão <span class="h2-descricao-destaque">Check-Out</span>, lembrando que essa opção não poderá ser desfeita.
        </div>
        <el-button type="success" @click="deliveryConfirmComponent">Check-Out</el-button>
      </div>

    </el-form>

    <edit-payment-dialog ref="editPaymentDialog" @savePayment="updatePayment"/>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import FotoUploadButton from '@/components/Shared/FotoUploadButton'
import InputMaskNumberField from '@/components/Shared/InputMaskNumberField'
import validateKm from '@/helpers/validateKm'
import EditPaymentDialog from './Complements/EditPaymentDialog'

export default {
  name: 'delivery',
  components: {
    FotoUploadButton,
    InputMaskNumberField,
    EditPaymentDialog
  },
  data () {
    return {
      form: {
        whoReceived: null,
        documentReceived: null,
        deliveryNotice: null,
        km_truck: 0,
        km_photo: false,
        invoices: {},
        receipts_photo: [],
        safeguard_photo: [],
        nsuNumer: ''
      },
      rules: {
        whoReceived: [
          { required: (!this.allInvoicesCanceled), message: 'Campo obrigatório' }
        ],
        km_truck: [
          { required: true, message: 'Campo obrigatório' },
          { validator: validateKm }
        ]
      }
    }
  },
  watch: {
    'form.whoReceived': function (value) {
      this.updateTempDeliveryForm({ field: 'whoReceived', data: value })
    },
    'form.documentReceived': function (value) {
      this.updateTempDeliveryForm({ field: 'documentReceived', data: value })
    },
    'form.deliveryNotice': function (value) {
      this.updateTempDeliveryForm({ field: 'deliveryNotice', data: value })
    },
    'form.km_truck': function (value) {
      this.updateTempDeliveryForm({ field: 'km_truck', data: value })
    }
  },
  computed: {
    ...mapState('Cargos', ['deliverCustomer', 'tempDeliveryFormData']),
    ...mapGetters('Cargos', ['deliverCustomerInvoicesCount']),
    ...mapGetters('Trucks', ['selectedTruckSavedKm']),
    ...mapGetters('User', ['companieGeneralSettings', 'companiePaymentsSettings']),
    allInvoicesCanceled () {
      let canceleds = Object.values(this.deliverCustomer.invoices).filter(invoice => invoice.status === 'canceled')
      return (canceleds.length === Object.values(this.deliverCustomer.invoices).length)
    },
    odomPhoto () {
      let setting = {
        enabled: this.$$to(this, 'companieGeneralSettings.odomPhotoOnEachDelivery'),
        forced: this.$$to(this, 'companieGeneralSettings.odomPhotoOnEachDeliveryForced')
      }

      if (setting.enabled === undefined) setting.enabled = true
      if (setting.forced === undefined) setting.forced = true

      return setting
    },
    paysWithNSUenabled() {
      return Object.values(this.companiePaymentsSettings).filter(payset => payset.nsu && payset.nsu === true)
    },
    paysWithNSUForcedenabled() {
      return Object.values(this.companiePaymentsSettings).filter(payset => payset.forcensu && payset.forcensu === true)
    }
  },
  methods: {
    ...mapActions('Cargos', ['deliveryConfirm']),
    ...mapMutations('Shared', ['setError']),
    ...mapMutations('Cargos', ['mixDeliverCustomer', 'mixDeliverCustomerInvoice', 'updateTempDeliveryForm', 'clearUpdateTempDeliveryForm']),

    editPaymentDialog (invoice) {
      this.$refs.editPaymentDialog.showDialog(invoice)
    },

    updatePayment (data) {
      this.mixDeliverCustomerInvoice({ invoiceNumber: data.invoice.invoice, data: { paymentEdited: data.newPayments } })
    },

    nsuupdate (invoice, number) {
      // this.form.invoices[invoice].payment[0].nsuNumber = number
      this.mixDeliverCustomerInvoice({ invoiceNumber: invoice, data: { nsuNumber: number } })
    },

    getPaymentName (payment) {
      if (Object.keys(this.companiePaymentsSettings).find(xcode => xcode === `x${payment.code}`)) {
        payment.nameFromCheckin = this.companiePaymentsSettings[`x${payment.code}`].name
        return payment.nameFromCheckin
      } else {
        payment.nameFromCheckin = `Não cadastrado ${payment.code}|${payment.type}`
        return payment.type
      }

      // Objeto Payment esperado
      // { ammount: 0,
      //   code: "99",
      //   type: "DE" }
    },

    deliveryConfirmComponent () {
      this.$refs['deliveryForm'].validate((valid) => {
        if (valid) {
          let payload = {
            deliveryCustomer: this.deliverCustomer,
            form: this.form,
            whoReceived: this.form.whoReceived,
            documentReceived: this.form.documentReceived,
            deliveryNotice: this.form.deliveryNotice,
            kmTruck: parseFloat(this.form.km_truck)
          }
          this.clearUpdateTempDeliveryForm()
          this.deliveryConfirm(payload)
          this.$router.push(`/cargo`)
        } else {
          this.setError(`Erros no formulário`)
        }
      })
    },

    invoicePartialCancellation (invoice) {
      if (invoice.status === 'with_partial') {
        this.$router.push(`/delivery/invoice-partial-cancellation/${invoice.invoice.replace('/', 'xbarx')}`)
      } else {
        this.$router.push(`/delivery/invoice-partial-cancellation/${invoice.invoice.replace('/', 'xbarx')}/add`)
      }
    },

    invoiceTotalCancellation (invoiceNumber) {
      let index = Object.values(this.deliverCustomer.invoices).findIndex(invoice => invoice.invoice === invoiceNumber)

      if (index > -1) {
        if (this.deliverCustomer.invoices[index].status === 'canceled') {
          let msg = `Remover status cancelado da nota ${this.deliverCustomer.invoices[index].invoice}?`
          return this.$confirm(msg,
            'Cancelamento total da nota',
            { confirmButtonText: 'Sim', cancelButtonText: 'Não', type: 'warning' })
            .then(() => {
              this.mixDeliverCustomerInvoice({ invoiceNumber, deletePartial: true, deleteCancel: true, data: {} })
            })
            .catch(() => {})
        } else {
          this.$router.push(`/delivery/invoice-total-cancellation/${invoiceNumber.replace('/', 'xbarx')}`)
        }
      }
    },

    // async allInvoicesTotalCancellation () {
    //   // Object.values(this.deliverCustomer.invoices).map(invoice => this.invoiceTotalCancellation(invoice.invoice))
    //   // Não funciona pois o map ou o forEach não irá esperar pelo confirm da funçào anterior
    //   for (let invoice of Object.values(this.deliverCustomer.invoices)) {
    //     await this.invoiceTotalCancellation(invoice.invoice)
    //   }
    // },

    setInvoiceImage: function (file, invoiceNumber, validateField) {
      this.mixDeliverCustomerInvoice({ invoiceNumber, data: { receipt_photo: file } })
      this.$refs['deliveryForm'].validateField(validateField)
    },

    setInvoiceRessalvaImage: function (file, invoiceNumber, validateField) {
      this.mixDeliverCustomerInvoice({ invoiceNumber, data: { safeguard_photo: file } })
      this.$refs['deliveryForm'].validateField(validateField)
    },

    setKmTruckImage: function (file) {
      this.mixDeliverCustomer({ delivered_km_photo: file })
      this.$refs['deliveryForm'].validateField('km_photo')
    },

    validateCustomerKmPhoto (rule, value, callback) {
      if (this.customerHasKmPhoto() || (this.odomPhoto.enabled && !this.odomPhoto.forced)) callback()
      else callback(new Error(`Foto do odômetro é obrigatório`))
    },

    validateInvoiceSafeguardPhoto (rule, value, callback) {
      let invoiceIndex = rule.field.split('.')[1] // field = receipts_photo.0
      let invoice = this.deliverCustomer.invoices[invoiceIndex]

      if (invoice.safeguard_photo && invoice.safeguard_photo.indexOf('base64') >= 0) {
        callback()
      } else {
        callback(new Error(`Foto da ressalva é obrigatória`))
      }
    },

    validateInvoiceReceiptPhoto (rule, value, callback) {
      let invoiceIndex = rule.field.split('.')[1] // field = receipts_photo.0
      let invoice = this.deliverCustomer.invoices[invoiceIndex]

      if (invoice.receipt_photo && invoice.receipt_photo.indexOf('base64') >= 0) {
        callback()
      } else {
        callback(new Error(`Foto da nota é obrigatória`))
      }
    },

    invoiceHasReceiptPhoto (invoice) {
      return Object.values(this.deliverCustomer.invoices)
        .some(invoiceFromState => invoiceFromState.invoice === invoice && invoiceFromState.receipt_photo)
    },

    invoiceHasSafeGuardPhoto (invoice) {
      return Object.values(this.deliverCustomer.invoices)
        .some(invoiceFromState => invoiceFromState.invoice === invoice && invoiceFromState.safeguard_photo)
    },

    customerHasKmPhoto () {
      if (this.deliverCustomer && this.deliverCustomer.delivered_km_photo) return true
      else return false
    },

    invoiceHasNsuPay (document) {
      let invoice = Object.values(this.deliverCustomer.invoices).filter(doc => doc.invoice === document)
      let payments = invoice.map(doc => doc.payment)
      let nsuEnabledCodes = []
      Object.values(this.paysWithNSUenabled).map(pay => {
        nsuEnabledCodes.push(pay.code)
        nsuEnabledCodes.push(pay.type)
      })
      let enabled = Object.values(payments[0]).some(pay => (nsuEnabledCodes.indexOf(pay.code) >= 0) || (nsuEnabledCodes.indexOf(pay.type) >= 0))
      // console.log('invoiceHasNsuPay on Delivery', document, enabled)
      return enabled
    },

    invoiceHasNsuPayForced (document) {
      let invoice = Object.values(this.deliverCustomer.invoices).filter(doc => doc.invoice === document)
      let payments = invoice.map(doc => doc.payment)
      let nsuEnabledCodes = []
      Object.values(this.paysWithNSUForcedenabled).map(pay => {
        nsuEnabledCodes.push(pay.code)
        nsuEnabledCodes.push(pay.type)
      })
      let enabled = Object.values(payments[0]).some(pay => (nsuEnabledCodes.indexOf(pay.code) >= 0) || (nsuEnabledCodes.indexOf(pay.type) >= 0))
      // console.log('invoiceHasNsuPayForced on Delivery', document, enabled)
      return enabled
    }
  },
  created () {
    // replicate invoices payment structure information from deliverCustomer state to form object
    Object.values(this.deliverCustomer.invoices).forEach(invoice => {
      this.$set(this.form.invoices, invoice.invoice, { payment: {} })

      Object.values(invoice.payment).forEach((payment, index) => {
        this.$set(this.form.invoices[invoice.invoice].payment, index, { nsuNumer: null })
      })
    })

    // set at form object the km truck
    this.form.km_truck = this.selectedTruckSavedKm

    // set form felds
    if (this.tempDeliveryFormData.whoReceived) this.form.whoReceived = this.tempDeliveryFormData.whoReceived
    if (this.tempDeliveryFormData.documentReceived) this.form.documentReceived = this.tempDeliveryFormData.documentReceived
    if (this.tempDeliveryFormData.deliveryNotice) this.form.deliveryNotice = this.tempDeliveryFormData.deliveryNotice
    if (this.tempDeliveryFormData.km_truck) this.form.km_truck = this.tempDeliveryFormData.km_truck
  },
  mounted () {
    // console.log(this.companieGeneralSettings)
    // console.log(this.companiePaymentsSettings)
    // console.log(this.deliverCustomer.invoices)
  }
}
</script>

<style scoped>
  .confirmPaymentLine >>> div {
    text-align: center;
  }

  .el-form-item {
    margin-bottom: 5px;
  }

  .invoiceBox {
    border-radius: 5px;
    border: 1px solid #808080;
    padding: 5px;
    margin-bottom: 8px;
  }

  .invoice_canceled {
    border: 1px solid #ffb2b2;
  }

  .payment-block {
    display:flex;
    justify-content: space-between;
    align-items: center;
  }

  .payment-description-edited {
    font-style: italic;
  }

</style>
