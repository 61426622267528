let dataExport = {}

function getDates () {
  let objToday = new Date()
  let dd = String(objToday.getDate()).padStart(2, '0')
  let mm = String(objToday.getMonth() + 1).padStart(2, '0') // January is 0
  let yyyy = objToday.getFullYear()
  let hour = objToday.getHours() < 10 ? '0' + objToday.getHours() : objToday.getHours()
  let minute = objToday.getMinutes() < 10 ? '0' + objToday.getMinutes() : objToday.getMinutes()
  let seconds = objToday.getSeconds() < 10 ? '0' + objToday.getSeconds() : objToday.getSeconds()
  // let meridiem = objToday.getHours() > 12 ? 'PM' : 'AM'

  // let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  // let curMonth = months[objToday.getMonth()]

  // let weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  // let dayOfWeek = weekday[objToday.getDay()]

  // new Date().toISOString().slice(0, 10) = "2019-06-03"
  // new Date().toLocaleDateString(locale ou empty) = "8/6/2019"
  // new Date().toJSON().slice(0, 10).replace(/-/g, '/') = "2019/06/03"

  // Calcular período d -1
  let dayDm1 = new Date(objToday.getTime() - 3600 * 1000 * 24 * 1)
  let lastDay = String(dayDm1.getDate()).padStart(2, '0')
  let lastDayMonth = String(dayDm1.getMonth() + 1).padStart(2, '0')
  let lastDayYear = dayDm1.getFullYear()

  // Calcular período de data do mes anterior
  // let lastmonth = String((mm === '01') ? '12' : (parseInt(mm) - 1)).padStart(2, '0')
  // let lastmonthYear = String((mm === '01') ? (parseInt(yyyy) - 1) : yyyy)
  // let lastmonthLastday = '31'
  // if (['04', '06', '09', '11'].indexOf(lastmonth) >= 0) lastmonthLastday = '30'
  // else if ((lastmonthYear % 4 === 0) && ((lastmonthYear % 100 !== 0) || (lastmonthYear % 400 === 0))) lastmonthLastday = '29'

  // let lastMonthStart = `${lastmonthYear}-${lastmonth}-01 00:00:00`
  // let lastMonthEnds = `${lastmonthYear}-${lastmonth}-${lastmonthLastday} 23:59:59`

  // Return Data
  return {
    now: `${mm}/${dd}/${yyyy} ${hour}:${minute}:${seconds}`,
    today: `${mm}/${dd}/${yyyy}`,
    yesterday: `${lastDay}/${lastDayMonth}/${lastDayYear}`,
    time: `${hour}:${minute}:${seconds}`,
    startDateToday: `${yyyy}-${mm}-${dd}`,
    startDateYesterday: `${lastDayYear}-${lastDayMonth}-${lastDay} `,
    startDateMonth: `${yyyy}-${mm}-01`,
    endDate: `${yyyy}-${mm}-${dd}`,
    startDateTodayFull: `${yyyy}-${mm}-${dd} 00:00:00`,
    startDateYesterdayFull: `${lastDayYear}-${lastDayMonth}-${lastDay} 00:00:00`,
    startDateMonthFull: `${yyyy}-${mm}-01 00:00:00`,
    endDateFull: `${yyyy}-${mm}-${dd} 23:59:59`
    // startLastMonth: lastMonthStart,
    // endDateLastMonth: lastMonthEnds
  }
}

function updateDate () {
  dataExport.d = getDates()
}

dataExport = {
  d: getDates(),
  update: updateDate
}

export default dataExport
