<!--
  EXEMPLO DE USO
  <template>
    <items-invoice
      :invoices="customer.invoices"             // obrigatório, lista de invoices
      :title="texto"                            // opcional, texto alternativo para o descritivo da lista
      :centerTitle="true "                      // opcional, boolean, se titulo deve ser centralizado, default false
      centerBody                                // opcional, boolean, se lista de notas deve ser centralizada, default false
      goto='/'                                  // obrigatorio, path para adicionar no goto
    </foto-upload-button>
  </template>
-->

<template>
  <div class="items-invoice-component">
    <div :class="{ center : centerTitle }"> {{ title }} </div>

    <div :class="{ center : centerBody }">
      <li v-for="invoice in invoices" :key="invoice.invoice || invoice.order_number">
        <el-button plain @click="selectInvoice(invoice)">
          <div v-if="invoice.invoice">NFe {{ invoice.invoice }}</div>
          <div v-else>Pedido {{ invoice.order_number }}</div>
          <div style="font-size: 11px; font-style: italic;">{{ invoice.volumes }} volume(s)</div>
        </el-button>
      </li>
    </div>
  </div>
</template>

<script>
export default {
  name: 'items-invoice',
  props: {
    invoices: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: 'Notas e/ou Pedidos'
    },
    goto: {
      type: String,
      default: '/'
    },
    centerTitle: Boolean,
    centerBody: Boolean
  },
  data () {
    return {}
  },
  methods: {
    selectInvoice (invoice) {
      if (invoice.items) {
        this.$router.push(`${this.goto}/${(invoice.order_number || invoice.invoice).replace('/', 'xbarx')}`)
      }
    }
  }
}
</script>

<style scoped>
  li {
    list-style: none;
  }
  button {
    padding: 5px 20px;
    width: 90%;
  }
  .center {
    text-align: center;
  }
 </style>
